<div class="technical-works w-100 h-100 flex justify-center items-center">
  <div class="technical-works-body flex flex-col items-center">
    <img src="assets/img/app-icon_266x266.png" alt="">
    <div>
      <h1 class="technical-works-body_title">Сайт временно недоступен!</h1>
      <p class="technical-works-body_desc">
        <span *ngIf="systemAlert; else desc">{{ systemAlert.message }}</span>
        <ng-template #desc>Идут технические работы.</ng-template>
      </p>
    </div>
  </div>
</div>

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StorageService } from '../storage/storage.service';
import { BehaviorSubject, Observable, catchError, map, of, tap } from 'rxjs';
import { Router } from '@angular/router';
import {
  CommonRoutes,
  StudentUser,
  MethodistUser,
  RecoverPassword,
  Keys,
} from 'src/app/core/model';
import {
  AuthRequest,
  AuthResponse,
  RegistrationResponse,
  GetUserInfoResponse,
} from 'src/app/core/model';
import { Roles } from 'src/app/core/model';
import { ToastrService } from 'ngx-toastr';
import { UsernameType } from '../../model/enum/auth';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public _isAuthenticated$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(!!localStorage.getItem('token') || false);

  private role!: Roles;

  constructor(
    private httpClient: HttpClient,
    private storageService: StorageService,
    private router: Router,
    private toastrService: ToastrService
  ) {}



  public signIn(data: AuthRequest): Observable<AuthResponse | boolean> {
    return this.httpClient.post<AuthResponse>(`/v1/auth/signin`, data).pipe(
      tap((res) => {
        this.storageService.setToken(res.token);
        this.storageService.setRefreshToken(res.refreshToken);
        this._isAuthenticated$.next(true);
        this.setRole(res.roles[0]);
      }),
      map((res) => {
        return res;
      }),
      catchError((error) => {
        console.log(error);
        return of(false);
      })
    );
  }
  public signInNoToken(data: AuthRequest): Observable<AuthResponse | boolean> {
    return this.httpClient.post<AuthResponse>(`/v1/auth/signintoken`, data).pipe(
      tap((res) => {
        this.storageService.setToken(res.token);
        this.storageService.setRefreshToken(res.refreshToken);
        this._isAuthenticated$.next(true);
        this.setRole(res.roles[0]);
      }),
      map((res) => {
        return res;
      }),
      catchError((error) => {
        console.log(error);
        return of(false);
      })
    );
  }

  public signUp(
    data: StudentUser | MethodistUser
  ): Observable<RegistrationResponse | boolean> {
    return this.httpClient
      .post<RegistrationResponse>(`/v1/auth/signup`, data)
      .pipe(
        tap(() => {
          this.router.navigate([`/${CommonRoutes.Authorization}`]);
        }),
        catchError((error) => {
          this.toastrService.error(error.error.messages[0], 'Қате')
          return of(false);
        })
      );
  }
  public logout(): void {
    this._isAuthenticated$.next(false);
    this.storageService.removeToken();
    this.storageService.removeFcmToken();
    this.storageService.removeRefreshToken();
    this.router.navigate(['/']);
  }

  public navigateToErrorPage(): void {
    this.router.navigate(['/system-error']).then();
  }

  public getInfoByRegistrationToken(
    registrationToken: string
  ): Observable<GetUserInfoResponse> {
    return this.httpClient
      .get<GetUserInfoResponse>(
        `/v1/auth/registrationtoken/${registrationToken}`
      )
      .pipe(
        tap((res) => {
          this.setRole(res.roles[0]);
        }),
        map((res) => {
          return res;
        })
      );
  }

  public sendEmailToRecoverPassword(
    username: string,
    type: UsernameType,
    token?: string,
  ): Observable<RecoverPassword> {
    const data: RecoverPassword = {
      token: token ?? '',
    };

    if(type === UsernameType.EMAIL) {
      data.username = username;
    }
    if(type === UsernameType.SMS) {
      data.phoneNumber = '+7'+ username;
    }

    return this.httpClient
      .post<RecoverPassword>(`/v1/auth/recoverpassword/token`, data)
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  private setRole(role: Roles): void {
    localStorage.setItem(Keys.Role, role);
  }
  public getRole(): Roles {
    return localStorage.getItem(Keys.Role) as Roles;
  }

  public refreshToken(token: string): Observable<AuthResponse> {
    return this.httpClient
      .post<AuthResponse>(`/v1/auth/refreshtoken`, {
        refreshToken: token,
      })
      .pipe(
        map((response) => {
          this.storageService.setToken(response.token);
          this.storageService.setRefreshToken(response.refreshToken);
          return response;
        })
      );
  }

  public recoverPassword(data: RecoverPassword): Observable<string> {
    return this.httpClient.post<string>(`/v1/auth/recoverpassword`, data);
  }

  public shorturl(id: string): Observable<{link: string}> {
    return this.httpClient.get<{link: string}>(`/v1/shorturls/${id}`);
  }
}

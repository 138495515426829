<ng-template #myTemplate>
  <div class="app-dialog">
    <div class="header">
      <h1 class="title">{{title | translate}}</h1>
      <button mat-button mat-dialog-close>
        <img src="assets/svg/circles/close-circle.svg" alt=""/>
      </button>
    </div>
    <div class="content">
      <ng-content></ng-content>
    </div>
    <div *ngIf="showButtons" class="buttons">
      <button class="iconic-button secondary-button button" mat-dialog-close
              mat-button>{{(closeBntText || 'cancel') | translate}}</button>
      <button
        class="iconic-button primary-button button"
        (click)="handleClick()"
        [disabled]="disabledConfirm"
        mat-button>{{(confirmBntText || 'save') | translate}}
      </button>
    </div>
  </div>
</ng-template>

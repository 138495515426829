import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map } from 'rxjs';
import {
  BannerCreateDTO,
  BannerDTO,
  CoursesDTO,
  DividingDTO,
  IGroupSwitch,
  IShortLesson,
  LessonDTO,
  LessonSummary,
  Roles, SortableResponseData,
  StudentGroupDTO,
  StudentProgress,
  StudentUser
} from '../../model';
import { IFaq } from '../../model/interface/faq';
import { IGroup } from '../../model/interface/group';
import {
  SUBJECTS_LABEL,
  SUBJECTS_OPTIONS,
} from 'src/app/shared/models/subjects';
import { IAnnouncementCreate } from '../../model/interface/announcement';
import { IEvent, IEventCreate } from '../../model/interface/event';
import { SubjectDTO } from '../../model/interface/subject';
import { PromoCodeDto, PromoCodeStatisticsDto } from "../../model/interface/promo-code";
import { MyTasks } from "../../model/interface/my-tasks";

@Injectable({
  providedIn: 'root',
})
export class HeadTeacherService {
  private selectedLesson$: BehaviorSubject<any> = new BehaviorSubject(null);
  public groupedStreamsBySubjectId$: BehaviorSubject<any> = new BehaviorSubject(
    null
  );

  constructor(private _http: HttpClient) {}

  public getAllStreams(): Observable<any> {
    return this._http.get(`/v1/streams`);
  }

  public getStreamsBySubjectId({
    subjectId,
    search,
  }: {
    subjectId: string;
    search?: string;
  }): Observable<any> {
    return this._http.get(`/v1/streams/subject/${subjectId}`, {
      params: { search: search ?? '' },
    });
  }

  public getGroupsByStreamId(streamId: string): Observable<any> {
    return this._http.get(`/v1/headteacher/streams/${streamId}/groups`);
  }

  public getSubjectById(subjectId: string): Observable<any> {
    return this._http.get(`/v1/streams/subject/${subjectId}`);
  }

  public getThemesByGroupId(groupId: string): Observable<any> {
    return this._http.get(`/v1/headteacher/groups/${groupId}`);
  }

  public changeStreamActiveStatus(
    streamId: string,
    status: boolean
  ): Observable<any> {
    return this._http.post(`/v1/streams/${streamId}/${status}`, null);
  }

  public changeCourseActiveStatus(
    courseId: string,
    status: boolean
  ): Observable<any> {
    return this._http.post(`/v1/streams/${courseId}/coursefinished/${status}`, null);
  }

  public changeAuthorizedStatus(
    courseId: string,
    status: boolean
  ): Observable<any> {
    return this._http.patch(`/v1/streams/${courseId}/onlylogged/${status}`, null);
  }

  public getLessonsByGroupIdThemeId(
    groupId: string,
    themeId: string,
    curatorId: string
  ): Observable<any> {
    return this._http.get(
      `/v1/headteacher/groups/${groupId}/themes/${themeId}/lessons`,
      {
        params: {
          curatorId: curatorId,
        },
      }
    );
  }

  public setSelectedLesson(lesson: LessonDTO): void {
    this.selectedLesson$.next(lesson);
  }

  public getSelectedLesson(): BehaviorSubject<any> {
    return this.selectedLesson$;
  }

  public getFaqs(): Observable<IFaq[]> {
    return this._http.get<IFaq[]>(`/v1/headteacher/faqs`);
  }

  public saveFaq(
    name: string,
    description: string,
    videoUrl?: string
  ): Observable<any> {
    return this._http.post(`/v1/headteacher/faqs`, {
      name,
      description,
      videoUrl,
    });
  }

  public deleteFaq(faqId: string): Observable<any> {
    return this._http.delete(`/v1/headteacher/faqs/${faqId}`);
  }

  public updateFaq(
    faqId: string,
    name: string,
    description: string
  ): Observable<any> {
    return this._http.put(`/v1/headteacher/faqs/${faqId}`, {
      name,
      description,
    });
  }

  public getMySubjects(): Observable<SubjectDTO[]> {
    return this._http.get<SubjectDTO[]>(`/v1/headteacher/subjects/my`)
  }

  public getCoursesBySubjectId(subjectId: string): Observable<any> {
    return this._http.get(`/v1/headteacher/subjects/${subjectId}/courses`);
  }

  public getPurchaseCount(id: string): Observable<number> {
    return this._http.get<number>(`/v1/streams/${id}/purchasecount`);
  }

  public saveStream(data: any): Observable<any> {
    return this._http.post(`/v1/streams`, data);
  }

  public editStream(data: any, id: string): Observable<any> {
    return this._http.put(`/v1/streams/${id}`, data);
  }

  public updateGroup(groupId: string, group: any): Observable<any> {
    return this._http.put(`/v1/headteacher/groups/${groupId}`, group);
  }

  public getStudentsByGroupId(groupId: string): Observable<any> {
    return this._http.get(`/v1/headteacher/groups/${groupId}/students`);
  }

  public getStudentByGroupIdAndLessonId(groupId: string, lessonId: string): Observable<any> {
    return this._http.get(`/v1/headteacher/groups/${groupId}/lessons/${lessonId}/students`);
  }

  public getGroupsByCourseId(courseId: string): Observable<any> {
    return this._http.get(`/v1/headteacher/courses/${courseId}/groups`);
  }

  public getSubjects(): { name: string; value: string }[] {
    return SUBJECTS_OPTIONS;
  }

  public _getSubjects(): { name: string; value: string }[] {
    const subjects = <{ name: string; value: string }[]>[].concat(SUBJECTS_OPTIONS as any);
    subjects.push({
      name: 'Творческий экзамен',
      value: 'f6ba7ace-19bd-4a4c-938e-682c37a00b13',
    });
    return subjects.filter(subject => subject.value !== 'aefcbf13-8928-40a5-bddb-1b5c7eac2e07' && subject.value !== '2f9a8bf5-4a39-4c5f-aa32-4c7ae09521b2');
  }

  public divideIntoGroups(data: DividingDTO): Observable<any> {
    return this._http.post(`/v1/streams/divideIntoGroups`, data);
  }

  public getStreamById(id: string): Observable<any> {
    return this._http.get(`/v1/streams/${id}`);
  }

  public updatePaymentsDates(date: any, id: string): Observable<any> {
    return this._http.patch(`/v1/headteacher/streams/${id}/paymentdates`, date);
  }

  public updatePaymentsToDate(body: any, streamId: string, paymentDateId: string): Observable<any> {
    return this._http.put(`/v1/headteacher/streams/${streamId}/paymentdates/${paymentDateId}`, body);
  }

  public removePaymentDateOnStream(
    streamId: string,
    paymentDateId: string
  ): Observable<any> {
    return this._http.delete(
      `/v1/headteacher/streams/${streamId}/paymentdates/${paymentDateId}`
    );
  }

  public duplicateStreamById(id: string): Observable<any> {
    return this._http.post<any>(`/v1/headteacher/streams/${id}/duplicate`, {});
  }

  public removeStreamById(id: string): Observable<any> {
    return this._http.delete<any>(`/v1/streams/${id}`, {});
  }

  public updateStudentGroup(
    groupId: string,
    studentId: string,
    data: string[]
  ): Observable<any> {
    return this._http.patch(
      `/v1/headteacher/groups/${groupId}/students/${studentId}`,
      data
    );
  }

  public switchStudentGroup(
    streamId: string,
    studentId: string,
    data: IGroupSwitch
  ): Observable<any> {
    return this._http.patch<any>(
      `/v1/headteacher/streams/${streamId}/students/${studentId}/swapgroup`,
      data
    );
  }

  public getHeadteacherSubjects(): Observable<any> {
    return this._http.get(`/v1/headteacher/profile/subjects`);
  }

  public getGroups(page?: number, size?: number): Observable<IGroup[]> {
    return this._http
      .get<{ content: IGroup[] }>(`/v1/headteacher/rating/groups`, {
        params: {
          page: page ?? 0,
          size: size ?? 300,
        },
      })
      .pipe(map((res) => res.content));
  }

  public getRating({
    groupIds,
    themeIds,
    lessonIds,
  }: {
    groupIds: string[];
    themeIds: string[];
    lessonIds: string[];
  }): Observable<any> {
    return this._http.get<any>(`/v1/headteacher/rating`, {
      params: { groupIds, themeIds, lessonIds },
    });
  }

  public getLesonsByThemeIds(ids: string[]): Observable<any> {
    return this._http.get(`/v1/headteacher/rating/lessonsbythemeids`, {
      params: {
        themeIds: ids,
      },
    });
  }

  public getThemesByGroupsIds(ids: string[]): Observable<any> {
    return this._http.get(`/v1/headteacher/rating/themesbygroupids`, {
      params: {
        groupIds: ids,
      },
    });
  }

  public getExcelFile(
    groupIds: string[],
    themeIds: string[],
    lessonIds: string[]
  ): Observable<Blob> {
    return this._http.get(`/v1/headteacher/rating/excel/download`, {
      params: {
        groupIds: groupIds,
        themeIds: themeIds,
        lessonIds: lessonIds,
      },
      responseType: 'blob',
    });
  }

  public sendToParents(
    groupIds: string[],
    themeIds: string[],
    lessonIds: string[]
  ): Observable<any> {
    return this._http.post(`/v1/headteacher/rating/excel/sendtoparents`, null, {
      params: {
        groupIds: groupIds,
        themeIds: themeIds,
        lessonIds: lessonIds,
      },
    });
  }

  public sendToStudents(
    groupIds: string[],
    themeIds: string[],
    lessonIds: string[]
  ): Observable<any> {
    return this._http.post(
      `/v1/headteacher/rating/excel/sendtostudents`,
      null,
      {
        params: {
          groupIds: groupIds,
          themeIds: themeIds,
          lessonIds: lessonIds,
        },
      }
    );
  }

  public commentLesson({
    text,
    groupId,
    lessonId,
    studentId,
  }: {
    text: string;
    groupId: string;
    lessonId: string;
    studentId: string;
  }): Observable<any> {
    return this._http.patch(`/v1/headteacher/progresses/groups/${groupId}/lessons/${lessonId}/students/${studentId}/comment`, {
      commentText: text,
    });
  }

  public getCourseById(id: string): Observable<any> {
    return this._http.get<any>(`/v1/courses/${id}`);
  }

  public getSubjectNameById(id: string): string {
    return (SUBJECTS_LABEL as any)[id] as string;
  }

  public getStudentGroups(studentId: string): Observable<any[]> {
    return this._http.patch<any[]>(
      `/v1/headteacher/students/${studentId}/groups`,
      {
        params: {
          studentId,
        },
      }
    );
  }

  public removeStudentFromGroup(
    studentId: string,
    groupId: string
  ): Observable<any> {
    return this._http.patch(
      `/v1/headteacher/groups/${groupId}/students/${studentId}/remove`,
      {
        params: {
          studentId,
          groupId,
        },
      }
    );
  }

  public addStudentToCourse(
    studentId: string,
    groupId: string,
    periodIds: string[]
  ): Observable<any> {
    return this._http.patch(
      `/v1/headteacher/groups/${groupId}/students/${studentId}`,
      {
        paymentDateIds: periodIds,
      },
      {
        params: {
          studentId,
          groupId,
        },
      }
    );
  }

  public createBanner(data: BannerCreateDTO): Observable<BannerDTO> {
    return this._http.post<BannerDTO>(`/v1/headteacher/banners`, data);
  }

  public getBanner(): Observable<BannerDTO> {
    return this._http.get<BannerDTO>(`/v1/banners/last`);
  }

  public updateBanner(data: BannerDTO): Observable<BannerDTO> {
    return this._http.put<BannerDTO>(`/v1/headteacher/banners/${data.id}`, data);
  };


  public getCoursesBySubjectIds(subjectIds: string[]): Observable<CoursesDTO[]> {
    return this._http.post<CoursesDTO[]>(`/v1/headteacher/coursesbysubjectids`, subjectIds);
  };

  public getGroupsByCourseIds(courseIds: string[]): Observable<StudentGroupDTO[]> {
    return this._http.post<StudentGroupDTO[]>(`/v1/headteacher/groupsbycourseids`, courseIds);
  };

  public getStudentsByGroupIds(groupIds: string[]): Observable<StudentUser[]> {
    return this._http.post<StudentUser[]>(`/v1/headteacher/studentsbygroupids`, groupIds);
  };

  public getCuratorsByCourseIds(courseIds: string[]): Observable<StudentUser[]> {
    return this._http.post<StudentUser[]>(`/v1/headteacher/curatorsbycourseids`, courseIds);
  };

  public getEvents(): Observable<IEvent[]> {
    return this._http.get<IEvent[]>(`/v1/headteacher/events`);
  }

  public getEventById(id: string): Observable<IEvent> {
    return this._http.get<IEvent>(`/v1/headteacher/events/${id}`);
  }

  public createEvent(data: IEventCreate): Observable<IEvent> {
    return this._http.post<IEvent>(`/v1/headteacher/events`, data);
  }

  public updateEvent(id: string,  data: IEventCreate): Observable<IEvent> {
    return this._http.put<IEvent>(`/v1/headteacher/events/${id}`, data);
  }
  public removeEvent(id: string): Observable<IEvent> {
    return this._http.delete<IEvent>(`/v1/headteacher/events/${id}`);
  }
  public createAnnounce(data: IAnnouncementCreate): Observable<any> {
    return this._http.post<any>(`/v1/headteacher/announcements`, data);
  }

  public updateAnnounce(announcementId: string, data: IAnnouncementCreate): Observable<any> {
    return this._http.put<any>(`/v1/curator/announcements/${announcementId}`, data);
  }

  public getCuratorList({
    page,
    size,
    search,
    sort
  }: {
    page: number;
    size: number;
    search?: string;
    sort?: string;
  }): Observable<any> {
    let params: any = {
      page: page,
      size: size,
      sort: [sort ?? '']
    };

    if (search) params.search = search;

    return this._http.get<any[]>(`/v1/admin/curator/list`, {
      params,
    });
  }

  public getLessonsWithSummaries(groupId: string, themeId: string): Observable<LessonSummary[]> {
    return this._http.get<LessonSummary[]>(`/v2/headteacher/groups/${groupId}/themes/${themeId}/lessons/summary`)
  }

  public getStudentProgresses(groupId: string, lessonId: string): Observable<StudentProgress[]> {
    return this._http.get<StudentProgress[]>(`/v2/headteacher/groups/${groupId}/lessons/${lessonId}/progresses`);
  }

  public getLessonsList(groupId: string, themeId: string): Observable<IShortLesson[]> {
    return this._http.get<IShortLesson[]>(`/v2/headteacher/groups/${groupId}/themes/${themeId}/lessons`)
  }

  public getLesson(groupId: string, lessonId: string): Observable<LessonDTO> {
    return this._http.get<LessonDTO>(`/v2/headteacher/groups/${groupId}/lessons/${lessonId}`)
  }


  public gradeLesson({
    grade,
    groupId,
    lessonId,
    studentId,
  }: {
    grade: number | null;
    groupId: string;
    lessonId: string;
    studentId: string;
  }): Observable<any> {
    return this._http.put(`/v1/headteacher/progresses/groups/${groupId}/lessons/${lessonId}/students/${studentId}`, {
      score: grade
    });
  }

  public deleteComment(commentId: string): Observable<void> {
    return this._http.delete<void>(`/v1/headteacher/progresses/comments/${commentId}`);
  }

  public getAnnouncements(pageable: ServiceDto): Observable<any> {
    if (!pageable?.roles?.length) {
      pageable.roles = [Roles.Curator, Roles.Student];
    }
    const params: any = pageable;
    return this._http.get<any>('/v1/headteacher/announcements', {
      params,
    });
  }

  public deleteAnnouncement(announcementId: string): Observable<void> {
    return this._http.delete<void>(`/v1/headteacher/announcements/${announcementId}`);
  }

  public getPromoCodes(pageable: ServiceDto | any): Observable<SortableResponseData<PromoCodeDto>> {
    const params: any = pageable;
    return this._http.get<SortableResponseData<PromoCodeDto>>('/v1/headteacher/promocodes', {
      params,
    });
  }

  public getPromoCodeById(promoCodeId: string): Observable<PromoCodeDto> {
    return this._http.get<PromoCodeDto>(`/v1/headteacher/promocodes/${promoCodeId}`);
  }

  public addPromoCode(data: any): Observable<any> {
    return this._http.post<any>(`/v1/headteacher/promocodes`, data);
  }

  public editPromoCode(promoCodeId: string, data: any): Observable<any> {
    return this._http.put<any>(`/v1/headteacher/promocodes/${promoCodeId}`, data);
  }

  public deletePromoCode(promoCodeId: string): Observable<any> {
    return this._http.delete<any>(`/v1/headteacher/promocodes/${promoCodeId}`);
  }

  public activatePromoCode(promoCodeId: string): Observable<any> {
    return this._http.patch(`/v1/headteacher/promocodes/${promoCodeId}/activate`, {});
  }

  public deactivatePromoCode(promoCodeId: string): Observable<any> {
    return this._http.patch(`/v1/headteacher/promocodes/${promoCodeId}/deactivate`, {});
  }

  public getStatistics(pageable: ServiceDto | any, promoCodeId: string): Observable<PromoCodeStatisticsDto[]> {
    const params: any = pageable;
    return this._http.get<PromoCodeStatisticsDto[]>(`/v1/headteacher/promocodes/${promoCodeId}/statistics`, {
      params,
    });
  }

  public getCuratorTasksById(curatorId: string, search: string = ''): Observable<MyTasks> {
    let params:any = {}
    params['search'] = search
    return this._http.get<MyTasks>(`/v2/headteacher/curator/${curatorId}/mytasks`, {params});
  }
}

interface ServiceDto {
  page?: number;
  size?: number;
  search?: string;
  sort?: string[],
  groupIds?: string[],
  courseIds?: string[],
  roles?: Roles[]
}

<form *ngIf="materials && materials.length > 0" [formGroup]="form" class="app-study-material-holder">
  <h1 class="name required" [ngClass]="{'required' : required}">{{name}}</h1>
  <div *ngIf="!allowed" class="disallowed">
    <img src="assets/svg/circles/lock-circle.svg" alt=""/>
    <span class="disallowed-text">{{'materialsAvailableAfterTest' | translate}}</span>
  </div>
  <div class="allowed" *ngIf="allowed">
    <ul class="materials">
      <li class="material" *ngFor="let material of materials" [ngClass]="{'material--active' : material.active}">
        <div class="left">
          <img [src]="'assets/svg/files/' + (material.id | fileFormat).icon" alt=""/>
          <button (click)="openFile(material.link)">
            <span class="material-name">{{(material.id | fileFormat).fileName }}</span>
          </button>
        </div>
        <app-slide-toggle
          *ngIf="!isViewModeTask"
          formControlName="toggler"
          (toggleValueChange)="setReviewedMaterials(material.id)"
          [toggleValue]="material.active"
          [text]="'iViewedMaterials' | translate"></app-slide-toggle>
      </li>
    </ul>
  </div>
  <div *ngIf="links && links.length > 0" class="links-wrapper">
    <h1 class="name">{{'helpfulLinks' | translate}}</h1>
    <ul class="links">
      <li class="link" *ngFor="let link of links; let i = index">
        <span>{{i + 1 + "."}}</span>
        <a class="link-text" [href]="link.link" target="_blank">{{link.name}}</a>
      </li>
    </ul>
  </div>
</form>

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component, OnDestroy,
  OnInit,
} from '@angular/core';
import { NotificationService } from "../../../../core/services/notification/notification.service";
import { NotificationCard } from "../../../../core/model/interface/notification-card";
import { AnnouncementService } from "../../../../core/services/announcement/announcement.service";
import { NotificationMapper } from "../../../../core/mappers/notification/notification.mapper";
import { BehaviorSubject, debounceTime, Subject, takeUntil } from 'rxjs';
import { AngularFireMessaging } from "@angular/fire/compat/messaging";
import { MessagingService } from "../../../../shared/services/firebase-messaging/messaging.service";
import { SystemService } from "../../../../core/services/system/system.service";
import { SystemAlerts } from "../../../../shared/models/interfaces/system-alerts";

@Component({
  selector: 'app-notification-modal',
  templateUrl: './notification-modal.component.html',
  styleUrls: ['./notification-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationModalComponent implements OnInit, OnDestroy {
  public isShow: boolean = false;
  public notifications: NotificationCard[] = [];
  private page = 0;
  public isLoading = false;
  public isDisabled: boolean = false;
  public nonReadExists: boolean = false;
  private isFullyLoad = false;

  currentSystemAlert$: BehaviorSubject<SystemAlerts | null> = new BehaviorSubject<SystemAlerts | null>(null);
  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private notificationService: NotificationService,
    private announcementService: AnnouncementService,
    private notificationMapper: NotificationMapper,
    private cdr: ChangeDetectorRef,
    private afMessaging: AngularFireMessaging,
    private messagingService: MessagingService,
    private systemService: SystemService
  ) {}

  ngOnInit(): void {
    this.currentSystemAlert$ = this.systemService.currentSystemAlert$;
    this.notificationService._isShowNotificationModal$
      .pipe(
        debounceTime(500),
        takeUntil(this.destroy$)
      )
      .subscribe((response) => {
        this.isShow = response;

        if (!this.isShow) {
          this.page = 0;
          this.isFullyLoad = false;
          this.isLoading = false;
          this.notifications = [];
        }
        if (this.isShow) {
          this.loadElements();
        }
        this.cdr.detectChanges();
      });

    this.afMessaging.messages
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (payload) => {
          console.log("new message received: ", payload);
          this.messagingService.currentMessage$.next(payload);
          this.messagingService.showCustomNotification(payload);
        }
      });
  }

  loadElements() {
    if (!this.isFullyLoad && !this.isLoading) {
      this.isLoading = true;
      this.announcementService.getAnnouncements(this.page++).subscribe(resp => {
        this.isLoading = false;
        this.notifications.push(...resp.content.map(el => this.notificationMapper.toNotificationCard(el)));
        this.nonReadExists = this.notifications.filter(notification => !notification.isRead)?.length > 0;
        this.announcementService.nonReadExists$.next(this.nonReadExists);
        this.isFullyLoad = resp.empty;
        this.cdr.detectChanges();
      });
    }
  }

  markAllAsRead() {
    this.isDisabled = true;
    const allNotifications = this.notifications.filter(t => t);
    this.notifications.forEach(t => t.isRead = true);
    this.nonReadExists = false;
    this.announcementService.nonReadExists$.next(this.nonReadExists);
    this.cdr.detectChanges();
    this.announcementService.readAnnouncement()
      .subscribe({
        next: _ => {
          this.isLoading = false;
          this.isFullyLoad = false;
          this.page = 0;
          this.isDisabled = false;
        },
        error: _ => {
          this.isDisabled = false;
          this.notifications = allNotifications;
          this.nonReadExists = this.notifications.filter(notification => !notification.isRead)?.length > 0;
          this.announcementService.nonReadExists$.next(this.nonReadExists);
          this.cdr.detectChanges();
        }
      });
  }

  onScroll(event: any) {
    if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
      this.loadElements();
    }
  }

  changedItem(item: NotificationCard) {
    const notificationIndex = this.notifications.findIndex(el => el.id === item.id);
    if (notificationIndex > -1) {
      this.notifications[notificationIndex] = item;
      this.nonReadExists = this.notifications.filter(notification => !notification.isRead)?.length > 0;
      this.announcementService.nonReadExists$.next(this.nonReadExists);
    }
  }

  closeNotification(): void {
    this.notificationService.toggleIsShowNotificationModal();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}

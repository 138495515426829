import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-view-photo',
  templateUrl: './view-photo.component.html',
  styleUrls: ['./view-photo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ViewPhotoComponent {
  @Input() image: string | undefined;

  public isViewMode: boolean = false;

  public close(): void {
    this.isViewMode = false;
  }
}

<div class="admin-modal-student-card flex border radius-8" *ngIf="group">
  <!-- <img class="preview" src="{{ group['subjectImage'] }}" alt="" /> -->

  <div class="image">
    <img *ngIf="group.subjectId" [src]="subjectsId[group.subjectId]" alt="" />
  </div>
  <div class="info">
    <div class="course-name text-md">
      {{ group.courseName }}
    </div>
    <div class="group-name">
      {{ group.name }}
    </div>

    <!-- <div class="progress">
      <div class="bg">
        <div class="value"></div>
      </div>

      <div class="label">Прогресс студента</div>
    </div> -->

    <button (click)="removeFromCourse()" class="remove-from-course__button">
      {{'REMOVE_FROM_COURSE' | translate}}
    </button>
  </div>
</div>

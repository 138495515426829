<h2 class="title">{{'COURSES' | translate}}</h2>
<div class="courses">
  <ng-container *ngFor="let group of selectedStudentGroupsList">
    <app-admin-student-card
      [group]="group"
      (removeFromCourseListener)="removeFromCourseHandler($event)"/>
  </ng-container>
</div>
<app-empty-message
  *ngIf="selectedStudentGroupsList.length === 0"
  message="STUDENT_HAS_NO_COURSES"/>

<button *ngIf="role !== roles.Curator" class="add-student-course" (click)="addCourse()">
  {{'ADD_TO_COURSE' | translate}}
</button>

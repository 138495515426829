<form [formGroup]="groupsForm">
    <div formArrayName="list">
        <div class="form" *ngFor="let group of groups.controls; let i = index; let lastRow = last" [formGroupName]="i"
            >
            <div
                class="row flex justify-between items-center"
                [ngStyle]="{ 'border-bottom': lastRow ? 'none' : '' }"
            >
                <div class="number">#{{ i + 1 }}</div>
                <div class="name">
                <app-input
                    formControlName="group"
                ></app-input>
                </div>

                <div class="count" (click)="onRowClick($event, i)">
                {{ group.get('studentCount')?.value ?? "-" }}
                </div>
                <div class="course">
                <app-cdk-select
                    [options]="coursesOption[i]"
                    (selectChangeHandler)="courseSelected($event, i)"
                    (searchInputHandler)="courseSearching($event, i)"
                    [withSearch]="true"
                    [value]="group.get('course')?.value"
                ></app-cdk-select>
                </div>
                <div class="curator">
                <app-cdk-select
                    [options]="curatorOption[i]"
                    (selectChangeHandler)="curatorSelected($event, i)"
                    (searchInputHandler)="curatorSearching($event, i)"
                    [withSearch]="true"
                    [value]="group.get('curator')?.value"
                ></app-cdk-select>
                </div>
                <div class="add-curator">
                <app-multi-select
                    [options]="curatorOption2[i]"
                    (selectedValue)="curator2Selected($event, i)"
                    (searchInputHandler)="curator2Searching($event, i)"
                    [withSearch]="true"
                    [preSelectedOptions]="group.get('addCurator')?.value"
                ></app-multi-select>
                </div>
            </div>
        </div>
    </div>
</form>
export enum LessonStatus {
    Additional="ADDITIONAL",
    Created = "CREATED",
    InReview = "IN_REVIEW",
    Success = "SUCCESS",
    Failed = "FAILED"
}

export enum FileExtension {
    WORD,
    PDF,
    EXCEL
}
export enum BaseRoutes {
  Student = 'student',
  Methodist = 'methodist',
  Curator = 'curator',
  HeadTeacher = 'headTeacher',
  Teacher = 'teacher',
  Admin = 'admin',
  Calendar = 'calendar',
  Catalogues = '',
  CataloguesWithOpenBasket = 'openBasket',
  MobileApp = 'mobile-app',
  Manager = 'manager',
  Docx = 'docx',
  Announcements = 'announcements',
}

export enum CommonRoutes {
  Registration = 'registration',
  Authorization = 'authorization',
  Profile = 'profile',
  ResetPassword = 'password-recover',
  ShortURL = 'shorturl'
}

export enum StudentRoutes {
  Main = 'main',
  AllTasks = 'all-tasks',
  Education = 'education',
  Theory = 'theory',
  Homework = 'homework',
  WorkOnMistakes = 'work-on-mistakes',
  Test = 'test',
  TestStart = 'start',
  TestPass = 'pass',
  TestFinish = 'finish',
  TestIds = ':groupId/:courseId/:lessonId',
  CoursesListMobile = 'courses-list-mobile',
  ThemesMobile = 'themes-mobile',
  Rating = 'rating',
  ScoresDetails= 'scores-details',
  MiniRating = 'mini-rating',
  Text = 'text',
  FoldersView = 'folders-view'
}

export enum EducationRoutes {
  Main = 'education',
  Theory = 'theory',
  Homework = 'homework',
  WorkOnMistakes = 'work-on-mistakes',
  Test = 'test',
  TestStart = 'start',
  TestPass = 'pass',
  TestFinish = 'finish',
  Text = 'text'
}

export enum TestRoutes {
  Test = 'test',
  TestStart = 'start',
  TestPass = 'pass',
  TestFinish = 'finish',
  TestIds = ':groupId/:courseId/:lessonId'
}

export enum MethodistRoutes {
  Main = 'main',
  Course = 'course',
  Theory = 'theory',
  Homework = 'homework',
  Education = 'education',
  Test = 'test',
  FoldersControl = 'folders-control'

}

export enum CuratorRoutes {
  Main = 'main',
  Profile = 'profile',
  MyTasks = 'my-tasks',
  Announcement = 'announcement',
  Education = 'education',
  Test = 'test',
  GroupId = ':groupId',
  StudentTestResult = 'student-test/:groupId/:lessonId/:studentId',
  GroupIdThemeId = ':groupId/:themeId',
  GroupIdThemeIdEdit = ':groupId/:themeId/edit',
  GroupIdThemeIdLectureId = ':groupId/:themeId/:lectureId',
  GroupIdRating = ':groupId/rating',
  FoldersControl = 'folders-control',
  StudentProgress = 'student-progress'
}

export enum CalendarRoutes {
  MAIN = '',
}

export enum CataloguesRoutes {
  MAIN = '',
  FAQ = 'faq',
  ORDER = 'order',
  ORDER_MOBILE = 'mobile-app/order',
  ORDER_RESULT = 'order/:result',
  COURSE_DETAIL = 'course/:courseId',
}

export enum AdminRoutes {
  Users = 'users',
  UserCreate = 'users/create',
  Curators = 'curators',
  Impersonation = 'impersonation',
  Subjects = 'subjects',
  Catalogue = 'catalogue',
  ManagersMonthly = 'managers/monthly',
  ManagersList = 'managers/list',
  Payments = 'payments',
  Statistic = 'statistic',
  SubjectDetail = 'subjects/:subjectId',
  SubjectGroups = 'subjects/:subjectId/:courseId',
  SubjectCourseEdit = 'subjects/:subjectId/:id/edit/:padding',
  SubjectGroupsDetail = 'subjects/:subjectId/:courseId/:groupId/:curatorId/:padding',
  SubjectGroupsThemeDetail = 'subjects/:subjectId/:streamId/:groupId/:curatorId/:themeId',
  SubjectGroupsThemeLessonDetail = 'subjects/:subjectId/:courseId/:groupId/:curatorId/:themeId/:lectureId/:no-padding',
  Faq = 'faq',
  Banner = 'banner',
  Profile = 'profile',
  Announcement = 'announcement',
  Discounts = 'discounts',
  Prolongation = 'prolongation',
  PromoCodes = 'promo-codes'
}

export enum HeadTeacherRoutes {
  Catalogue = 'catalogue',
  Subjects = 'subjects',
  GroupRating = 'group/:groupId',
  SubjectDetail = 'subjects/:subjectId',
  SubjectGroups = 'subjects/:subjectId/:courseId',
  SubjectCourseEdit = 'subjects/:groupId/:subjectId/:id/edit/:padding',
  SubjectGroupsDetail = 'subjects/:subjectId/:courseId/:groupId/:curatorId/:padding',
  SubjectGroupsThemeDetail = 'subjects/:subjectId/:streamId/:groupId/:curatorId/:themeId',
  SubjectGroupsThemeLessonDetail = 'subjects/:subjectId/:courseId/:groupId/:curatorId/:themeId/:lectureId/:no-padding',
  Curators = 'curators',
  Faq = 'faq',
  Profile = 'profile',
  Banner = 'banner',
  Announcement = 'announcement',
  Education = 'education',
  Test = 'test',
  FoldersControl = 'folders-control',
  SubjectGroupsThemeLessonCuratorDetail = 'curator-task/:subjectId/:courseId/:groupId/:curatorId',
  Analytics = 'analytics',
  StreamVideos = 'stream-videos',
  GroupIdThemeIdLectureId = ':groupId/:themeId/:lectureId',
  QuestionnaireCreate = "questionnaire-create",
  QuestionnaireRoled = "questionnaire-roled/:id",
  QuestionnaireTable = "questionnaire-table",
  QuestionnaireDetailed = "questionnaire-detailed",
  QuestionnaireHistory = "questionnaire-history"
}

export enum ManagerRoutes {
  Payments = 'payments',
  Discounts = 'discounts',
  Profile = 'profile'
}

export enum DocumentRoutes {
  PublicOffer = 'public-offer',
  PrivacyPolicy = 'privacy-policy'
}

import {Injectable} from '@angular/core';
import {AuthService} from "../auth/auth.service";
import {BaseRoutes, Roles} from "../../model";

@Injectable({
  providedIn: 'root'
})
export class RoleService {

  constructor(private authService: AuthService) {
  }

  public getBaseRouteByCurrentRole(): BaseRoutes {
    switch (this.authService.getRole()) {
      case Roles.Admin:
        return BaseRoutes.Admin;
      case Roles.Curator:
        return BaseRoutes.Curator;
      case Roles.Methodist:
        return BaseRoutes.Methodist;
      case Roles.HeadTeacher:
        return BaseRoutes.HeadTeacher;
      case Roles.Student:
        return BaseRoutes.Student;
      case Roles.Teacher:
        return BaseRoutes.Catalogues;
      case Roles.Manager:
        return BaseRoutes.Manager;
      default:
        return BaseRoutes.Catalogues
    }
  }
}

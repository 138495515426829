import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-result',
  templateUrl: './result.component.html',
  styleUrls: ['./result.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResultComponent {
  @Input() title: string = "";
  @Input() infoText: string = ""; 
  @Input() score: number = 0;
  @Input() explanation: string = "";
  @Input() referenceUrl: string | undefined;

  public isOpen: boolean = false;

  public openVideoPlayer(): void {
    this.isOpen = true;
  }

  public closeVideoPlayer(): void {
    this.isOpen = false;
  }
}

import { LessonStatus } from 'src/app/shared/models';
import { TestType } from '../enum';

export interface ProgressDTO {
  id: string;
  studentId: string;
  lessonId: string;
  lessonName: string,
  lessonType: string,
  themeId: string;
  courseId: string;
  groupId: string;
  score: number;
  comment: string;
  status: LessonStatus;
  quizQuestionIds: string[];
  quizStartTime: string;
  questionPassingDtos: QuizPassingDTO[];
  comments: CommentDTO[];
  maxScore: number;
  videoTimings: number[][] | null
  submissions: string[];
  lessonMaxScore?: number;
  viewedMaterials: string[] | null,
  videoUrl: string | undefined,
  videoViewed: boolean,
  submissionTime: string | null,
  submissionText: string | null,
  studentFirstname: string,
  studentLastname: string
}

export interface QuizPassingDTO {
  questionId: string;
  groupId: string;
  lessonId: string;
  quizQuestionType: TestType;
  userAnswers: UserAnswer[] | null;
  score: number;
  correctAnswerTexts: string[];
}

export interface UserAnswer {
  answerTextValue?: string;
  answerId?: string;
}

export interface TestAnswerDTO {
  questionId: string;
  groupId: string;
  lessonId: string;
  userAnswers: UserAnswer[] | null;
  quizQuestionType: TestType;
}

export interface QuizFinishDTO {
  quizPassingDto: TestAnswerDTO[],
  submissions: string[],
  submissionText: string
}

export interface CommentDTO {
  commentId: string;
  commentText: string;
  commenterId: string;
  commenterName: string;
  commentTime: string;
  commenterPhotoUrl: string;
  isEdit?: boolean;
}

export enum QuizState {
  Passing = 'Passing',
  Attention = 'Attention',
  View = 'View'
}

export interface HomeworkSubmission {
  submissions: string[] | null | undefined,
  submissionText: string | null | undefined
}

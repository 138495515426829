import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DialogComponent implements OnChanges {
  @ViewChild('myTemplate') customTemplate!: TemplateRef<any>;

  @Input() isOpen: boolean = false;
  @Input() disabledConfirm: boolean = true;
  @Input() title: string = "";
  @Input() showButtons: boolean = true;
  @Input() closeBntText: string = '';
  @Input() confirmBntText: string = '';

  @Output() confirmEvent = new EventEmitter<void>();
  @Output() closeEvent = new EventEmitter<void>();

  private dialogRef!: MatDialogRef<DialogComponent>;


  constructor(public dialog: MatDialog) {}

  ngOnChanges(changes: SimpleChanges) {
    if(changes['isOpen'] && this.isOpen) {
      this.openDialog();
    }
    if(changes['isOpen'] && !this.isOpen) {
      this.closeDialog();
    }
  }

  public handleClick(): void {
    this.confirmEvent.emit();
  }

  private openDialog(): void {
    if(this.customTemplate) {
      this.dialogRef = this.dialog.open(this.customTemplate);

      this.dialogRef.afterClosed().subscribe(() => {
        this.closeEvent.emit();
      });
    }
  }
  private closeDialog(): void {
    if(this.customTemplate) {
      this.dialogRef.close();
    }
  }
}

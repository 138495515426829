import { HttpClient } from '@angular/common/http';
import { Injectable, isDevMode } from '@angular/core';
import { Observable, map, of } from 'rxjs';
import { FileDTO } from '../../model/interface/files';
import { environment } from 'src/environment';

@Injectable({
  providedIn: 'root'
})
export class FilesService {

  constructor(private httpClient: HttpClient) { }


  public getFiles(fileId: string): Observable<Blob> {
    if(!fileId) {
      const mockFile = new Blob(['Mock file content'], { type: 'text/plain' });
      return of(mockFile);
    }
    else {
      return this.httpClient.get(`/v1/files/${fileId}`, { responseType: 'blob' });
    }
  }
  public sendFiles(material: FormData): Observable<FileDTO> {
    return this.httpClient.post<FileDTO>(`${environment.uploadFilesUrl}${isDevMode()}`, material).pipe(
      map(res => {
        return res;
      })
    );
  }
  public removeFiles(fileId: string): Observable<string> {
    return this.httpClient.delete<string>(`/v1/files/${fileId}`).pipe(
      map(res => {
        return res;
      })
    )
  }

  public removeListOfFiles(fileIds: string[]): Observable<string> {
    return this.httpClient.delete<string>(`/v1/files`, {
      body: fileIds
    })
  }

  public getImageUrl(url : string) : string {
    return `${environment.filesUrl}${url}`
  }
}

import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { HelperService } from 'src/app/shared/services/helper/helper.service';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { BaseRoutes, Keys, StudentGroupDTO, StudentGroupThemeDTO, StudentRoutes } from 'src/app/core/model';
import { LessonStatus } from 'src/app/shared/models';
import { Store } from '@ngrx/store';
import { selectCurrentGroupWithDetails, selectGroups } from 'src/app/core/state/student/groups/groups.selector';
import { LoadingService } from "../../../../shared/services/loading/loading.service";
import { Router } from '@angular/router';
import { StorageService } from "../../../../core/services/storage/storage.service";

@Component({
  selector: 'app-student-side-bar',
  templateUrl: './student-side-bar.component.html',
  styleUrls: ['./student-side-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StudentSideBarComponent implements OnInit, OnDestroy {
  public courseStatus = LessonStatus;

  public percent: number = 0;
  sortType: 'SORT_BY_NEW' | 'SORT_BY_OLD' = 'SORT_BY_OLD';

  public groupsCopy: StudentGroupDTO[] = [];
  public groups: StudentGroupDTO[] | null | undefined;
  private groups$: Observable<StudentGroupDTO[] | null | undefined> = this.store.select(selectGroups);
  private groupsSubscription!: Subscription;

  public currentGroup: StudentGroupThemeDTO | null | undefined;
  private currentGroup$: Observable<StudentGroupThemeDTO | null | undefined> = this.store.select(selectCurrentGroupWithDetails);
  private currGroupSubscription!: Subscription;
  loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private cdr: ChangeDetectorRef,
    private store: Store,
    private helperService: HelperService,
    private loadingService: LoadingService,
    private router: Router,
    private storageService: StorageService
  ) {}

  ngOnInit(): void {
    this.storageService.setItem(Keys.SortType, this.sortType);
    this.loading$ = this.loadingService.loadingSideBar$;
    this.groupsSubscription = this.groups$.subscribe(res => {
      this.groups = res;
      this.cdr.detectChanges();
    });

    this.currGroupSubscription = this.currentGroup$.subscribe(group => {
      if(group) {
        this.currentGroup = group;
        this.currentGroup.isCourseFinished = this.groups?.find(t => t.groupId === this.currentGroup?.groupId)?.isCourseFinished;

        this.groupsCopy = [...this.groups!].filter(item => item.groupId);
        this.groupsCopy =  this.groupsCopy?.filter(item => item.groupId !== this.currentGroup?.groupId);

        this.currentGroup.themes = this.helperService.countProgressForEachTheme(this.currentGroup!.themes);
        this.percent = this.helperService.countProgressForGroup(this.currentGroup.themes);
        this.cdr.detectChanges();
        this.loadingService.loadingSideBar$.next(false);
      } else {
        this.loadingService.loadingSideBar$.next(false);
        this.cdr.detectChanges();
      }
    });
  };

  setSort(sort: 'SORT_BY_NEW' | 'SORT_BY_OLD'): void {
    // this.loadingService.loadingSideBar$.next(true);
    this.sortType = sort;
    this.storageService.setItem(Keys.SortType, sort);
    this.cdr.detectChanges();
    // this.store.dispatch(getGroupAction({groupId: this.currentGroup!.groupId, direction: sort === 'SORT_BY_NEW' ? 'DESC' : 'ASC'}));
  }

  load(): void {
    this.loadingService.loadingSideBar$.next(true);
  }

  public openFolders(): void {
    this.router.navigate([`/${BaseRoutes.Student}/${StudentRoutes.FoldersView}`])
  }

  ngOnDestroy(): void {
    this.groupsSubscription.unsubscribe();
    this.currGroupSubscription.unsubscribe();
  }
}

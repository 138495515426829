import { DistrubutedGroupsOfStreamComponent } from './components/distrubuted-groups-of-stream/distrubuted-groups-of-stream.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StreamCardComponent } from './components/stream-card/stream-card.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { RouterModule } from '@angular/router';
import { ProlongateDateComponent } from './components/prolongate-date/prolongate-date.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CuratorInfoComponent } from './components/curator-info/curator-info.component';
import { ComponentsModule } from 'src/app/shared/components/components.module';
import { TranslateModule } from "@ngx-translate/core";

@NgModule({
  declarations: [
    StreamCardComponent,
    ProlongateDateComponent,
    CuratorInfoComponent,
    DistrubutedGroupsOfStreamComponent
  ],
  imports: [
    CommonModule,
    MatMenuModule,
    MatSlideToggleModule,
    RouterModule,
    SharedModule,
    ReactiveFormsModule,
    FormsModule,
    ComponentsModule,
    TranslateModule
  ],
  exports: [
    StreamCardComponent,
    ProlongateDateComponent,
    CuratorInfoComponent,
    DistrubutedGroupsOfStreamComponent
  ],
})
export class HeadTeacherModule {}

<div class="order-info p-24 radius-16 bg-white border">
  <ng-container *ngIf="this.streamIds.length > 0">
    <div class="title">{{'orderTotal' | translate}}</div>

    <div class="card" *ngFor="let card of itemsCart; last as last">
      <div class="header flex items-center" [class.no-border]="isExistActivePromoCode && last">
        <div class="image radius-8">
          <img [src]="subjectsId[card.subjectId]" alt=""/>
        </div>
        <div class="description flex flex-col">
          <div class="name">{{ card.name }}</div>
          <div class="duration-date flex items-center">
            <div class="duration">{{ card.duration }}</div>
            <div class="circle"></div>
            <div class="date">{{ card.startDate }}</div>
          </div>
        </div>
      </div>

      <div *ngIf="isExistActivePromoCode && last" class="promo-code">
        <app-input [formControl]="promoCode" name="PROMO_CODE"/>
      </div>
    </div>

    <div class="card" *ngFor="let card of activeCourses && isAuthenticated">
      <div class="header flex items-center">
        <div class="image radius-8">
          <img [src]="subjectsId[card.subjectId]" alt=""/>
        </div>
        <div class="description flex flex-col">
          <div class="name">{{ card.name }}</div>
          <!-- <div class="duration-date flex items-center">
            <div class="duration">{{ card.duration  }}</div>
            <div class="circle"></div>
            <div class="date">{{ card.startDate }}</div> -->
          <!-- </div> -->
        </div>
      </div>
    </div>

    <div class="body">
      <div class="price flex flex-col">
        <div class="sum flex justify-between">
          <div class="label">{{'sum' | translate}}</div>
          <div class="value">{{ totalPrice | number }} ₸</div>
        </div>
        <div class="discount flex justify-between">
          <div class="label">{{'discount' | translate}}</div>
          <div class="value">- {{ discountPrice | number }} ₸</div>
        </div>
        <div class="total-price flex justify-between">
          <div class="label">{{'sum'| translate}}</div>
          <div class="value">{{ sumPrice | number }} ₸</div>
        </div>
      </div>
    </div>

    <span class="circs">
      {{'confidentiality_1' | translate}}
      <a target="_blank"
         routerLink="/public-offer"
         class="circs-link">
        {{'confidentiality_2' | translate}}
      </a>
      {{'confidentiality_3' | translate}}
      <a class="circs-link"
         target="_blank"
         routerLink="/privacy-policy"
         [innerHTML]="'confidentiality_4' | translate"></a>
      <a
        target="_blank"
        href="https://docs.google.com/document/d/1IC6GMND4B99ffNFQfF79dmaYmzNMXAko/edit?usp=share_link&ouid=104488707150146731021&rtpof=true&sd=true"
        class="circs-link"
      >
        {{'confidentiality_5' | translate}}
      </a>
      {{'confidentiality_6' | translate}}
    </span>
    <div class="payment" [ngClass]="{disabled: (isPaymentDisabled())}">
      <div class="cover"></div>
      <div class="title">{{'selectPaymentType' | translate}}</div>
      <div class="payment-list flex flex-col">
        <button
          (click)="onChoosePaymentType('KASPI')"
          class="payment-item flex items-center radius-16 border"
          [ngClass]="{ selected: paymentType === 'KASPI' }"
        >
          <img src="./assets/img/kaspi.png" alt=""/>
          <div [ngClass]="{ selectedText: paymentType === 'KASPI' }" class="label">Kaspi.kz</div>
        </button>
        <button
          (click)="onChoosePaymentType('PAYBOX')"
          class="payment-item flex items-center radius-16 border"
          [ngClass]="{ selected: paymentType === 'PAYBOX' }"
        >
          <img width="40" src="./assets/svg/bank_card.svg" alt=""/>
          <div [ngClass]="{ selectedText: paymentType === 'PAYBOX' }" class="label">{{'bankCard' | translate}}</div>
        </button>
      </div>
    </div>

    <div class="payment flex flex-col">
      <button
        class="primary-button radius-8"
        (click)="openAttention()"
        [disabled]="isContinueButtonDisabled()"
      >
        {{'continue' | translate}}
      </button>
    </div>
  </ng-container>

  <div
    class="empty-cart flex items-center justify-center"
    *ngIf="streamIds.length === 0"
  >
    {{'youDontSelectedCoursesToPay' | translate}}
  </div>
</div>

<app-aggrement-viewer
  [isOpen]="isAttentionOpen"
  (closeEvent)="closeAttention()"
  (confirmEvent)="onGetPaymentLink()">
</app-aggrement-viewer>

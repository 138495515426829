import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-iconic-table',
  templateUrl: './iconic-table.component.html',
  styleUrls: ['./iconic-table.component.scss']
})
export class IconicTableComponent implements OnInit {
  @Input() headers: string[] = [];
  @Input() rows: any[] = [];
  @Output() rowClickedEvent: EventEmitter<number> = new EventEmitter<number>();
  public readonly Object = Object;

  constructor() { }

  ngOnInit() {
  }

}

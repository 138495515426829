import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Observable, of, take } from 'rxjs';
import { IShortLesson, LessonDTO, LessonType, QuizDTO } from 'src/app/core/model';
import { ThemeService } from 'src/app/core/services/theme/theme.service';
import {
  CdkDragDrop,
  moveItemInArray,
} from '@angular/cdk/drag-drop';
import { CuratorService } from 'src/app/core/services/curator/curator.service';

@Component({
  selector: 'app-lessons-list',
  templateUrl: './lessons-list.component.html',
  styleUrls: ['./lessons-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LessonsListComponent implements OnChanges{
  @Input() themeId: string = '';
  @Input() lessonsList!: IShortLesson[];
  @Input() isCurator: boolean | undefined;
  @Input() groupId: string | undefined;
  @Output() removedLessonEvent: EventEmitter<string> = new EventEmitter<string>();
  public lessonType = LessonType;

  public isOpenList: boolean[] = [];
  constructor(
    private themeService: ThemeService,
    private cdr: ChangeDetectorRef,
    private _curatorService: CuratorService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['lessonsList'] && this.lessonsList && this.lessonsList.length > 0) {
      this.isOpenList = [];
      for(let index of this.lessonsList) {
        this.isOpenList.push(false);
      }
    }
  }

  drop(event: CdkDragDrop<LessonDTO[]>): void {
    if (this.isCurator) {
      this._dropCuratorsList(event);
    } else {
      moveItemInArray(this.lessonsList, event.previousIndex, event.currentIndex);
      const lessonIds: string[] = this.lessonsList.map(
        (lesson: IShortLesson) => lesson.id
      );
      this.themeService
        .orderingTheme(this.themeId, lessonIds)
        .subscribe(() => {
          this.cdr.detectChanges();
      });
    }
  }

  private _dropCuratorsList(event: CdkDragDrop<LessonDTO[]>): void {
    moveItemInArray(this.lessonsList, event.previousIndex, event.currentIndex);
    const newArrOrder = this.lessonsList.map((lesson) => lesson.id) || [];
    this._curatorService
      .changeLecturesOrder(this.groupId!, this.themeId, newArrOrder)
      .subscribe((response) => {
        this.cdr.detectChanges();
      });
  }

  public toggle($event: boolean, index: number): void {
    this.isOpenList[index] = $event;
  }

  public removeLessonById(id: string): void {
    this.removedLessonEvent.emit(id);
  }
}

<table class="table">
  <thead class="head">
    <tr class="row">
      <th 
        class="cell head-cell" 
        *ngFor="let item of headers;let first=first"
        [ngClass]="{'head-cell--first': first }">{{item}}</th>
    </tr>
  </thead>
  <tbody class="body">
    <tr 
      class="row body-row" 
      *ngFor="let row of rows;let i = index"
      (click)="rowClickedEvent.emit(i)" 
      >
      <td 
        class="cell row-cell" 
        *ngFor="let key of Object.keys(row);let first=first"
        [ngClass]="{'row-cell--first': first}" >
        <span [innerHTML]="row[key]">
        </span>
      </td>
    </tr>
  </tbody>
</table>


<app-announcements-alert/>

<div class="app-header" [ngClass]="{'app-header--full': size === headerSizeEnum.FULL}">
  <div class="left">
      <button (click)="emptyName()" *ngIf="backButtonName" class="button-back secondary-button" [routerLink]="[backButtonValue]">
          <img class="button-icon" src="assets/svg/arrow-left.svg" alt="" />
          <span class="button-text">{{backButtonName | translate}}</span>
      </button>
      <button (click)="navigateTo()" class="mobile" *ngIf="!backButtonName && !(menuList && menuList.length > 0)">
          <img width="89px" height="39px" src="assets/svg/logo-new-black.svg" alt="" />
      </button>
      <div class="mobile">
        <button
          *ngIf="menuList && menuList.length > 0"
          class="specific-mobile-menu"
          [matMenuTriggerFor]="lessonsMenu"
          #lessonMenuTrigger="matMenuTrigger"
          (click)="toggleLessonMenu()"
        >
          <img src="assets/svg/mobile/menu.svg" alt="" />
          <h1 class="specific-text">{{activeMenuName}}</h1>
        </button>

        <mat-menu #lessonsMenu="matMenu"
        >
          <button
            class="close-menu-siblings"
            (click)="closeLessonMenu()"
            mat-menu-item
          >
            <img src="assets/svg/circles/close-circle.svg" alt=""/>
          </button>
          <button mat-menu-item *ngFor="let item of menuList" [routerLink]="generateLessonsSiblingsRoute(item)">
            {{item.name}}
          </button>
        </mat-menu>
      <div class="info">
          <h1 class="title">{{name | translate}}</h1>
          <span *ngIf="desc" class="info-add">{{desc}}</span>
      </div>
    </div>
  </div>
  <div class="right">
    <button class="button-link" *ngIf="isAbleToShowLoginThroughAnotherUser()" [routerLink]="['/admin/impersonation']">
      Войти под пользователем
    </button>
    <div *ngIf="!isMobile" class="desktop">
      <app-closest-deadline></app-closest-deadline>
    </div>
    <app-notification-modal/>
    <a *ngIf="isStudentRole && isStudentRoute" [routerLink]="['/student/all-tasks']" class="nav hide-nav">
      <img src="assets/svg/check-circle.svg" alt=""/>
    </a>
    <button class="desktop" (click)="toggleFullScreen()">
      <img *ngIf="!isFullScreen" src="assets/svg/ArrowsOutSimple.svg" alt="" />
      <img *ngIf="isFullScreen" src="assets/svg/ArrowsInSimple.svg" alt="" />
    </button>
    <a [routerLink]="['./calendar']" class="nav desktop">
      <img src="assets/svg/calendar-base.svg" alt=""/>
    </a>
    <a [routerLink]="['./calendar']" class="nav mobile" *ngIf="role === Roles.Curator">
      <img src="assets/svg/calendar-base.svg" alt=""/>
    </a>
    <button (click)="toggleNotificationModal()" class="nav-btn">
      <img src="assets/svg/bell-base.svg" *ngIf="!(nonReadExists$ | async)" alt=""/>
      <img src="assets/svg/bell-base-with-circle.svg" *ngIf="nonReadExists$ | async" alt=""/>
    </button>
    <a [routerLink]="['']" class="nav mobile" *ngIf="role === Roles.Student">
      <img src="assets/svg/shopping-cart.svg" alt=""/>
    </a>
    <button
      class="menu"
      *ngIf="!imageUrl"
      mat-button
      #baseMenuTrigger="matMenuTrigger"
      [matMenuTriggerFor]="baseMenu"
      (click)="toggleBaseMenu()">
      {{userName ? userName[0] : ''}}
    </button>
    <button
      class="menu"
      *ngIf="imageUrl"
      mat-button
      #baseMenuTrigger="matMenuTrigger"
      [matMenuTriggerFor]="baseMenu"
      [style.background-image]="'url(' + imageUrl + ')'"
      (click)="toggleBaseMenu()">
    </button>
    <mat-menu #baseMenu="matMenu">
      <div class="top-menu mobile"></div>
      <button class="menu-item" mat-menu-item [routerLink]="generateProfileRoute()">
        <img src="assets/svg/menu/user-octagon.svg" alt="" />
        <span>Профиль</span>
      </button>
      <button class="menu-item" (click)="setCurrentLang(languagesEnum.RU)" mat-menu-item>
        <img *ngIf="currentLang === languagesEnum.RU; else notRuChoosed" src="assets/svg/menu/tick-circle.svg" alt="" />
        <ng-template #notRuChoosed>
          <img src="assets/svg/menu/language-square.svg" alt=""/>
        </ng-template>
        <span>Русский язык</span>
      </button>
      <button class="menu-item" (click)="setCurrentLang(languagesEnum.KZ)" mat-menu-item>
        <img *ngIf="currentLang === languagesEnum.KZ; else notKzChoosed" src="assets/svg/menu/tick-circle.svg" alt="" />
        <ng-template #notKzChoosed>
          <img src="assets/svg/menu/language-square.svg" alt=""/>
        </ng-template>
        <span>Қазақ тілі</span>
      </button>
      <div class="mobile border w-100"></div>
      <button class="menu-item" mat-menu-item (click)="logout()">
        <img src="assets/svg/menu/logout.svg" alt="" />
        <span>{{'logoutFromAccount'| translate}}</span>
      </button>
    </mat-menu>
  </div>
</div>
<div *ngIf="isMobile" class="mobile deadline-mobile">
  <app-closest-deadline></app-closest-deadline>
</div>

<div class="lecture-card bg-white radius-16 border w-100">
  <div class="header">
    <div class="title flex justify-between">
      <a (click)="onSelectLesson()">
        {{ lesson.name }}
      </a>
      <div class="mobile">
        <div class="subject-type">
          {{'TYPE' | translate}}:
          {{ lesson.lessonType ? (lesson!.lessonType | reverseLessonType) : "-" }}
        </div>
      </div>
      <app-button
        leftIcon="./assets/svg/eye.svg"
        text="SEE"
        [type]="editButtonType"
        [size]="editButtonSize"
        [color]="editButtonColor"
        (onButtonClick)="navigateTo()"
        [fullWidth]="isMobile"
      ></app-button>
    </div>
    <div class="desktop">
      <div class="subject-type">
        {{'TYPE' | translate}}:
        {{ lesson.lessonType ? (lesson!.lessonType | reverseLessonType) : "-" }}
      </div>
    </div>
  </div>

  <div class="desktop">
    <div class="content flex justify-between">
      <div class="content__item curator flex flex-col justify-center">
        <div class="label">{{'course' | translate}}</div>
        <div class="value">{{ lesson.courseName || '' }}</div>
      </div>
      <div class="divider"></div>
      <div class="content__item passed flex flex-col items-center justify-center">
        <div class="label">{{'DID_NOT_PASS' | translate}}</div>
        <div class="value">
          {{ lesson.notSubmittedCount }} /
          {{ lesson.studentsCount }}
        </div>
      </div>
      <div class="divider"></div>
  
      <div class="content__item rated">
        <div
          [ngClass]="{
            'all-checked':
              lesson.reviewedCount ===
              lesson.submittedCount
          }"
          class="wrapper radius-8 flex flex-col items-center justify-center"
        >
          <div class="label">{{'RATED' | translate}}</div>
          <div class="value">
            {{ lesson.reviewedCount }} /
            {{ lesson.submittedCount }}
          </div>
        </div>
      </div>
      <div class="divider"></div>
  
      <div
        class="content__item average-grade flex flex-col items-center justify-center"
      >
        <div class="label">{{'AVERAGE_RATING' | translate}}</div>
        <div class="value">
          {{ lesson.averageScore ? (lesson.averageScore | number: '1.0-2') : '-' }} /
          {{ lesson.maxScore || "-" }}
        </div>
      </div>
      <div class="divider"></div>
      <div class="content__item deadline flex flex-col justify-center">
        <div class="label">{{'DEADLINE_EXAM' | translate}}</div>
        <div class="value flex items-center flex justify-center">
          <ng-container *ngIf="lesson?.deadline">
            {{ lesson.deadline ? (lesson.deadline | utcToLocal | date : "HH:mm dd.MM.yyyy") : '-' }}
          </ng-container>
          <ng-container *ngIf="!lesson?.deadline"> - </ng-container>
        </div>
      </div>
    </div>
  </div>
  <div class="mobile">
    <div class="content-mobile">
      <div class="content-mobile-item">
        <div class="element element-left">
          <div class="content__item curator flex flex-col justify-center ">
            <div class="label">{{'course' | translate}}</div>
            <div class="value">{{ lesson.courseName || '' }}</div>
          </div>
        </div>
        <div class="divider"></div>
        <div class="element element-right">
          <div class="content__item curator flex flex-col justify-center ">
            <div class="label">{{'AVERAGE_RATING' | translate}}</div>
            <div class="value">
              {{ lesson.averageScore ? (lesson.averageScore | number: '1.0-2') : '-' }} /
              {{ lesson.maxScore || "-" }}
            </div>
          </div>
        </div>
      </div>
      <div class="iconic-line"></div>
      <div class="content-mobile-item">
        <div class="element element-left">
          <div class="content__item curator flex flex-col justify-center rated">
            <div
              [ngClass]="{
                'all-checked':
                  lesson.reviewedCount ===
                  lesson.submittedCount
              }"
              class="wrapper"
            >
            <div class="label">{{'RATED' | translate}}</div>
            <div class="value">
              {{ lesson.reviewedCount }} /
              {{ lesson.submittedCount }}
            </div>
          </div>
          </div>
        </div>
        <div class="divider"></div>
        <div class="element element-right">
          <div class="content__item curator flex flex-col justify-center ">
            <div class="label">{{'DID_NOT_PASS' | translate}}</div>
            <div class="value">
              {{ lesson.notSubmittedCount }} /
              {{ lesson.studentsCount }}
            </div>
          </div>
        </div>
      </div>
      <div class="iconic-line"></div>
      <div class="content__item--last">
        <div class="label">{{'DEADLINE_EXAM' | translate}}</div>
        <div class="value flex items-center flex justify-center">
          <ng-container *ngIf="lesson?.deadline">
            {{ lesson.deadline ? (lesson.deadline | utcToLocal | date : "HH:mm dd.MM.yyyy") : '-' }}
          </ng-container>
          <ng-container *ngIf="!lesson?.deadline"> - </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { NgxConveyerDirective } from '@egjs/ngx-conveyer';
import { ICatalogue } from 'src/app/core/model/interface/catalogues';
import { CataloguesService } from 'src/app/core/services/catalogues/catalogues.service';
import { FilesService } from 'src/app/core/services/files/files.service';
import { SUBJECTS_ID } from 'src/app/shared/models/subjects';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-course-detail-main',
  templateUrl: './course-detail-main.component.html',
  styleUrls: ['./course-detail-main.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CourseDetailMainComponent implements AfterViewInit {
  @ViewChild('cards') cards: ElementRef | undefined;
  @ViewChildren('singleCard') singleCard: QueryList<ElementRef> | undefined;
  @ViewChild('line') line!: ElementRef;
  @ViewChild("conveyer") conveyer!: NgxConveyerDirective;

  @Input() course!: ICatalogue;
  public subjectsId = SUBJECTS_ID;

  public shows: boolean[] = [];

  constructor(
    private _cataloguesService: CataloguesService,
    private _fs : FilesService,
    private toastrService: ToastrService,
    private translateService: TranslateService
  ) {}

  ngAfterViewInit(): void {
   this.countReviewLineWidth();
   document.body.scrollTo(0, 0);
  }

  public prev(): void {
    if(window.innerWidth < 999) {
      this.conveyer.scrollIntoView("prev", {
        align: "center",
        duration: 500,
      });

      return;
    }

    this.conveyer.scrollIntoView("start", {
      align: "center",
      duration: 500,
    });
  }
  public next(): void {
    if(window.innerWidth < 999) {
      this.conveyer.scrollIntoView("next", {
        align: "start",
        duration: 500,
      });
      return;
    }
    this.conveyer.scrollIntoView("end", {
      align: "start",
      duration: 500,
    });
  }

  public onFinishScroll(): void {
    this.countReviewLineWidth();
  }

  public countReviewLineWidth(): void {
    if(this.cards) {
      const cardsElement = this.cards.nativeElement;
      const cardsWidth = cardsElement.offsetWidth;
      const cardsXPosition = cardsElement.getBoundingClientRect().left;
      const currentLeft = cardsWidth + cardsXPosition;

      const singleCardArray = this.singleCard?.toArray();
      const visibleSingleCardNumber: number | undefined = singleCardArray?.findIndex(item => {
        const element = item.nativeElement;
        const xPosition = element.getBoundingClientRect().left;
        return xPosition > currentLeft ? true : false;
      });

      const lineElement = this.line.nativeElement;
      if(this.singleCard && this.singleCard.length > 0 && visibleSingleCardNumber) {
        lineElement.style.width = (visibleSingleCardNumber === -1 ? (this.course.streamInfo?.streamComments.length || 1) : visibleSingleCardNumber)  * 100
        / (this.course.streamInfo?.streamComments.length || 1) + '%';
      }
    }
  };

  public calculateTallestDivHeight(index: number) {
    this.shows[index] = !this.shows[index];

    setTimeout(() => {

      let maxHeight = 0;

      if(!this.singleCard || this.singleCard.length === 0) {
        return;
      }

      this.singleCard.forEach((div: ElementRef) => {
        const height = div.nativeElement.offsetHeight;
        if (height > maxHeight) {
          maxHeight = height;
        }
      });

      this.singleCard.forEach((div: ElementRef) => {
        div.nativeElement.style.minHeight = this.shows[index] ? (maxHeight + 'px') : '100%';
      });
    }, 10)
  }

  public addToCart(): void {
    const genius = [
      '34884638-e59c-4225-9ea5-4b87e43fbf89',
      'c819836e-73ed-4154-92f9-54a96a1d9e4b',
      '4ba64454-bacf-46e5-85d2-f9f802cbf760',
      'bd504325-b1c7-4296-9088-aa9b447dd4ec',
      '5e2e0268-f023-478b-924a-85a21a3e07a2',
      '15535607-3d0e-48c6-bc67-7204664521da',
      '54319446-39e1-461b-9904-ed352033266c',
      '8d6ec86c-0b05-48b1-8586-dd33183169c1',
      '31b65925-2606-46b2-b9b2-fce232ba6b1f',
      'd9dbd307-3c26-4f22-b007-b597738a0c21',
      '59d784dd-3b93-458e-8ec2-04edbcc1aa4c',
      'ba7a1248-907e-43bb-827b-41fb5af89de2',
      '2962b0e6-a7c3-4aae-9af4-ae2b88ffc5a2',
      'f86cc706-7546-41f8-9565-681e2d96264f',
      'd89f0f26-2bdd-4c26-83c0-ac32a4e28052',
      'ab3c29de-dac9-4ba3-b268-59372ede0dd2',
      'fd2046da-448d-428f-9a56-5b9855b2a573',
      '4d999883-0cb7-435c-a240-0be9016594ef',
      'adae9e6c-05e5-4119-9afd-b436ab164c40',
      '30bde7fd-0cd1-4a88-8a48-63eb4fe4a38f',
      '536ecde8-2af8-4d20-8d62-7cd831b15f5a',
      'db6a54fb-8c63-48c2-b71f-1042d437c732',
      '27ac2b87-d628-49f6-a5ad-a79d0452bf2c',
      '6a6b4e68-dd3b-419d-ae2a-101c76857c36',
      '3248e108-06a4-4d4c-aa6b-56c253d0090e',
      '8719d23a-fbea-4436-b918-3671018e1c3e',
      'd3a3e890-58cd-4148-ae98-1b48f8933c92',
      'ccf1b889-c284-47b5-a056-7a655135e98a',
      'f2845346-c07c-4a17-8d77-00dbd729ac83',

    ]

    const smart = [
      "bd3447c4-cd51-4a0a-aa38-ad19c50b6303",
      "4ea76077-2e59-49b5-9515-f2f90cc49523",
      "22f0b353-5663-4a61-8758-4a6a5de9e8eb",
      "ca0a731c-5fad-4998-a6b7-d4fb07fbd90e",
      "5f31f1dd-9ae4-4d9e-95b3-e92ead17b96a",
      "8a77022e-227e-48f4-a050-04296e37a785",
      "a0757ceb-d992-4008-bf6c-8ac1276f72a4",
      "42c23ca3-bbe8-407b-b538-fb24efa61338",
      "7e7e1e20-07f6-4e7a-9f9f-e918c5eca6fb",
      "b15da185-179f-4731-a07b-1376b8691c99",
      "02d046fe-cf41-479b-9092-718d1c79f15a",
      "18f724fd-af64-41f4-ac88-20e0634a5951",
      "d633e89a-b150-4af5-824c-93982547164e",
      "27ae9c36-7377-437e-9ceb-cee0ce73a549",
      "1f8e966c-120a-4083-9574-262cdaac5cbb",
      "3e3dbbd5-2759-4dd8-a5cd-9b8b5c36628b",
      "13fa81e3-17b5-492e-aec1-ff35510b623e",
      "904f918b-e4d0-49ad-9be2-76577df5d5f3",
      "de6e1ed3-bcf5-4f00-bbb4-857096d461c0",
      "32e9896d-2e89-40d9-a157-dcb4e2fa318a",
      "a045acf8-f97c-4d59-b94f-0d8d15b4c20d",
      "581c1cf0-32a7-4f08-b47d-da6d8b3e0973",
      "f5d5112c-eb15-45c4-aeb9-6bab83e30c2b",
      "ceb33b95-b986-4bc7-b44a-5e570c3bf7be",
      "7035eefb-9860-439f-bb3e-aafe033a1efa",
      "6714bc40-2e18-404c-9e39-4ef0bab201c7",
      "4f81ae6f-84f7-4a40-a99c-e31ae0727073",
      "e735596c-e377-4bf2-9a52-8620b3fb1513",
      '3e3dbbd5-2759-4dd8-a5cd-9b8b5c36628b',
      '13fa81e3-17b5-492e-aec1-ff35510b623e',
      '904f918b-e4d0-49ad-9be2-76577df5d5f3',
      'de6e1ed3-bcf5-4f00-bbb4-857096d461c0',
      '32e9896d-2e89-40d9-a157-dcb4e2fa318a',
      'a045acf8-f97c-4d59-b94f-0d8d15b4c20d',
      '581c1cf0-32a7-4f08-b47d-da6d8b3e0973',
      'f5d5112c-eb15-45c4-aeb9-6bab83e30c2b',
      'ceb33b95-b986-4bc7-b44a-5e570c3bf7be',
      '145b0044-226c-4d3a-95cf-0030d0e14e1c',
      '7035eefb-9860-439f-bb3e-aafe033a1efa',
      '6714bc40-2e18-404c-9e39-4ef0bab201c7',
      '4f81ae6f-84f7-4a40-a99c-e31ae0727073',
      'e735596c-e377-4bf2-9a52-8620b3fb1513',
      '591f7975-5eb4-47ef-888e-a2703cab3a11',
      "730e4283-131a-4ac8-9126-03bf6fe1fbab",
      "dbb06e1a-98ae-4d0b-8509-52676c306d6c",
      "84fc269f-5f4b-40ed-a9d0-d2fc34d12a9e",
      "d66b237b-35d0-4311-bdbf-4414b5e4385d",
      "48d26588-e79f-4031-84bb-f0ff37e2b63d",
      "381ae239-2052-4c12-aa98-b46324873d47",
      "2ef27fd3-bdac-43b1-b447-779d30d006dd",
      "70a72bb3-e486-4b7b-8c1c-7b3457a26492",
      "3f68518c-b60f-47fe-a01d-206ee1290876",
      "6932c264-ac19-4edd-b0e8-bf05dfd45a14",
      "640d9310-c5da-4de5-a759-5eeb05192d7f",
      "b055f6e6-c7c2-40dd-836c-9e6137ae303b",
      "3a6def0c-be5c-4366-970b-e3f39fada683",
      "da13ffd4-0d62-408a-a0e9-81bbccade3ec",
      "3d9c05c6-80bd-438f-ac1e-fe5db4c4dc71",
      "efa470d5-72f7-49d7-8752-83cf27dbbdc0"
    ]
    const pickedCourses = this._cataloguesService.getCoursesFromLocalStorage();

    if(pickedCourses.length == 0) {
      this._cataloguesService.addToCard(this.course);
      return;
    }
    if(smart.includes(pickedCourses[0].id) && smart.includes(this.course.id)) {
      this._cataloguesService.addToCard(this.course);
      return
    }

    this.toastrService.info(this.translateService.instant('CANT_BUY_GENIUS'), this.translateService.instant('ATTENTION'))
  }

  public getImageUrl(url : string) : string
 {
  return this._fs.getImageUrl(url)
 }
}

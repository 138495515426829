<div *ngIf="!loading && profile" class="profile-page">
  <section class="main">
    <div class="main-head">
      <button *ngIf="false" class="edit">
        <img src="assets/svg/edit-2.svg" alt=""/>
      </button>
    </div>
    <div class="main-bottom">
      <button
        (click)="openFilePicker()"
        class="profile-picture"
        [style.background-image]="'url(' + imageUrl + ')'"
      >
        <div *ngIf="!imageUrl" class="profile-picture-title">
          {{profile.firstname ? profile.firstname[0] : ''}}
        </div>
        <input
          #fileInput
          class="picture-input"
          type="file"
          id="imageInput"
          (change)="onFileSelected($event)"
          accept=".png, .jpg, .jpeg, .svg"
        />
      </button>
      <div class="desktop">
        <button
          class="edit-button iconic-button secondary-button"
          (click)="openDialog()"
        >
          <img src="assets/svg/edit-2.svg" alt=""/>
          <span>{{ "edit" | translate }}</span>
        </button>
      </div>
      <div class="fields-wrapper" [ngSwitch]="role">
        <div class="mobile-flex">
          <h1 class="name">
            {{
            profile.firstname +
            " " +
            profile.lastname
            }}
          </h1>
          <button class="mobile" (click)="openDialog()">
            <img src="assets/svg/edit-2.svg" alt=""/>
          </button>
        </div>

        <div *ngSwitchCase="RolesEnum.Student" class="w-100">
          <div class="mobile">
            <app-expander [isExpanded]="isInfoOpen">
              <button class="block block--first" (click)="toggleProfileInfo()" button>
                <app-primary-arrow [isOpen]="!isInfoOpen" openColor="#6A7186"/>
              </button>
              <ng-container *ngTemplateOutlet="studentFields" content></ng-container>
            </app-expander>
          </div>
          <div class="desktop">
            <ng-container *ngTemplateOutlet="studentFields"></ng-container>
          </div>
          <ng-template #studentFields>
            <ul class="fields">
              <li class="item">
                <span class="key">Email</span>
                <span class="value">{{profile.username }}</span>
              </li>
              <div class="line desktop"></div>
              <li class="item">
                <span class="key">{{ "chosenExamineSubjects" | translate }}</span>
                <div class="value-list">
              <span class="value">{{
                subjectsService.subjectTranslations[
                  profile.firstSubjectId
                  ] ?
                  subjectsService.subjectTranslations[
                    profile.firstSubjectId
                    ][language] : ''
                }}</span>
                  <span class="value">{{
                    subjectsService.subjectTranslations[
                      profile.secondSubjectId
                      ] ?
                      subjectsService.subjectTranslations[
                        profile.secondSubjectId
                        ][language] : ''
                    }}</span>
                </div>
              </li>
              <div class="line desktop"></div>
              <li class="item">
                <span class="key">{{ "educationGrade" | translate }}</span>
                <span class="value">{{ profile.grade }}</span>
              </li>
              <div class="line desktop"></div>
              <li class="item">
                <span class="key">{{'INSTAGRAM' | translate}}</span>
                <span class="value">{{
                  profile.instagramLink
                  }}</span>
              </li>
            </ul>
          </ng-template>
        </div>

        <ul *ngSwitchCase="RolesEnum.Methodist" class="fields">
          <li class="item">
            <span class="key">Email</span>
            <span class="value">{{ profile.username }}</span>
          </li>
          <div class="line"></div>
          <li class="item">
            <span class="key">{{'INSTAGRAM' | translate}}</span>
            <span class="value">{{
              profile.instagramLink
              }}</span>
          </li>
          <div class="line"></div>
          <li class="item">
            <span class="key">{{'PHONE' | translate}}</span>
            <span class="value">{{profile.phoneNumber }}</span>
          </li>
          <div class="line"></div>
          <li class="item">
            <span class="key">{{ "jobTitle" | translate }}</span>
            <span class="value">{{'METHODIST' | translate}}</span>
          </li>
        </ul>

        <div *ngSwitchCase="RolesEnum.Curator" class="w-100">
          <div class="mobile">
            <app-expander [isExpanded]="isInfoOpen">
              <button class="block block--first" (click)="toggleProfileInfo()" button>
                <app-primary-arrow [isOpen]="!isInfoOpen" openColor="#6A7186"/>
              </button>
              <ng-container *ngTemplateOutlet="fields" content></ng-container>
            </app-expander>
          </div>
          <div class="desktop">
            <ng-container *ngTemplateOutlet="fields"></ng-container>
          </div>
          <ng-template #fields>
            <ul class="fields">
              <li class="item">
                <span class="key">Email</span>
                <span class="value">{{ profile?.username }}</span>
              </li>
              <div class="line"></div>
              <li class="item">
                <span class="key">{{'INSTAGRAM' | translate}}</span>
                <span class="value">{{ profile.instagramLink }}</span>
              </li>
              <div class="line"></div>
              <li class="item">
                <span class="key">{{'PHONE' | translate}}</span>
                <span class="value">{{ profile?.phoneNumber | phone }}</span>
              </li>
              <div class="line"></div>
              <li class="item">
                <span class="key">{{ "jobTitle" | translate }}</span>
                <span class="value">{{'CURATOR' | translate}}</span>
              </li>
              <div class="line"></div>
              <li class="item">
                <span class="key">{{ "yourHeadteacher" | translate }}</span>
                <div *ngIf="headTeachers;else other_content">
                  <div *ngFor="let headTeacher of headTeachers">
                    <span class="value">{{headTeacher.firstname + ' ' + headTeacher.lastname}}</span>
                  </div>
                </div>
                <ng-template #other_content>
                  <span class="value">{{"noHeadteacher" | translate}}</span>
                </ng-template>
              </li>
            </ul>
          </ng-template>
        </div>
        <ul *ngSwitchCase="RolesEnum.HeadTeacher" class="fields">
          <li class="item">
            <span class="key">Email</span>
            <span class="value">{{ profile?.username }}</span>
          </li>
          <div class="line"></div>
          <li class="item">
            <span class="key">{{'INSTAGRAM' | translate}}</span>
            <span class="value">{{ profile?.instagramLink }}</span>
          </li>
          <div class="line"></div>
          <li class="item">
            <span class="key">{{'PHONE' | translate}}</span>
            <span class="value">{{ profile?.phoneNumber | phone }}</span>
          </li>
          <div class="line"></div>
          <li class="item">
            <span class="key">{{ "jobTitle" | translate }}</span>
            <span class="value">{{'HEAD_TEACHER' | translate}}</span>
          </li>
        </ul>
        <!-- <ul *ngSwitchCase="RolesEnum.Curator" class="fields">
                    <li class="item">
                        <span class="key">Email</span>
                        <span class="value">exampleemail@maill.ru</span>
                    </li>
                    <div class="line"></div>
                    <li class="item">
                        <span class="key">Инстаграм</span>
                        <span class="value">n.anbk</span>
                    </li>
                    <div class="line"></div>
                    <li class="item">
                        <span class="key">Номер телефона</span>
                        <span class="value">+(747) 282 22 22</span>
                    </li>
                    <div class="line"></div>
                    <li class="item">
                        <span class="key">Должность</span>
                        <span class="value">Завуч</span>
                    </li>
                </ul> -->
      </div>
    </div>
  </section>

  <section *ngIf="role === RolesEnum.Student && studentActiveCourses.length"
           class="section">
    <app-active-course [activeCourses]="studentActiveCourses"></app-active-course>
  </section>

  <section *ngIf="role === RolesEnum.Student && studentInactiveCourses.length"
           class="section">
    <app-expander [isExpanded]="isOpen">
      <button class="duties-button" (click)="toggle()" button>
        <h1 class="title">{{ "myFinishedCourses" | translate }}</h1>
        <app-primary-arrow
          [isOpen]="!isOpen"
          openColor="#6A7186"
        ></app-primary-arrow>
      </button>
      <div class="duties-content" content>
        <app-duty-card *ngFor="let course of studentInactiveCourses"
                       [course]="course"
                       [role]="RolesEnum.Student"></app-duty-card>
      </div>
    </app-expander>
  </section>

  <section *ngIf="false && role === RolesEnum.Student" class="section">
    <h1 class="title">{{ "myMaterials" | translate }}</h1>
    <div class="switcher">
      <button
        class="iconic-button video-tab-button"
        [ngClass]="{
          'secondary-button': activeVideoTab !== VideoTabsEnum.All,
          'primary-button': activeVideoTab === VideoTabsEnum.All
        }"
        (click)="setActiveTab(VideoTabsEnum.All)"
      >
        {{ "all" | translate }}
      </button>
      <button
        class="iconic-button video-tab-button"
        [ngClass]="{
          'secondary-button': activeVideoTab !== VideoTabsEnum.First,
          'primary-button': activeVideoTab === VideoTabsEnum.First
        }"
        (click)="setActiveTab(VideoTabsEnum.First)"
      >
        {{ "firstOnes" | translate }}
      </button>
      <button
        class="iconic-button video-tab-button"
        [ngClass]="{
          'secondary-button': activeVideoTab !== VideoTabsEnum.Last,
          'primary-button': activeVideoTab === VideoTabsEnum.Last
        }"
        (click)="setActiveTab(VideoTabsEnum.Last)"
      >
        {{ "lastOnes" | translate }}
      </button>
    </div>
    <ul class="videos">
      <app-past-events-card></app-past-events-card>
      <app-past-events-card></app-past-events-card>
      <app-past-events-card></app-past-events-card>
    </ul>
  </section>

  <section class="flex justify-end mobile-justify-center" *ngIf="role === RolesEnum.Curator">
    <app-button
      text="addVideo"
      [color]="buttonColor"
      leftIcon="./assets/svg/plus-white.svg"/>
  </section>

  <app-personal-info-editor
    [data]="profile"
    [isOpen]="isDialogOpen"
    [role]="role"
    (closeEvent)="closeDialog()"
    (confirmEvent)="confirmDialog()">
  </app-personal-info-editor>
</div>
<app-page-progress-spinner *ngIf="loading"></app-page-progress-spinner>

import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { TestQuestionCustom, TestQuestionDTO } from 'src/app/core/model';
import { getFormControlErrors } from 'src/app/shared/validators';
import {TranslateService} from "@ngx-translate/core";
import { LongPressDirective } from 'src/app/shared/directives/long-press/long-press.directive';

@Component({
  selector: 'app-insert-test-controller',
  templateUrl: './insert-test-controller.component.html',
  styleUrls: ['./insert-test-controller.component.scss'],
  hostDirectives: [LongPressDirective],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InsertTestControllerComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('base') base!: ElementRef;
  @ViewChild('transformer') transformer: ElementRef | undefined;

  @Input() item!: TestQuestionDTO;
  @Output() removeEvent: EventEmitter<number> = new EventEmitter<number>();
  @Output() saveEvent: EventEmitter<TestQuestionCustom> = new EventEmitter<TestQuestionCustom>();

  private eventsSubscription!: Subscription;
  @Input() saveEventSubject!: Observable<number>;
  @Output() validQuestionStatus: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() index!: number;

  @ViewChild('input') input!: ElementRef;
  @HostListener('document:mousemove', ['$event'])
  onMouseMove(e: any) {
    // console.log(e);
  };

  public isOpen: boolean = false;
  private selectedWord: string = "";

  public form!: FormGroup;
  public formErrors = {
    questionText: "",
    answer: ""
  }

  constructor(
    private elRef:ElementRef,
    private formBuilder: FormBuilder,
    private cdr: ChangeDetectorRef,
    private translateService: TranslateService,
  ) {
    this.form = this.formBuilder.group({
      questionText: ['', Validators.required],
    });

    this.form.statusChanges.subscribe((status) => {
      const anyCustomError: boolean = this.formErrors.answer ? true : false;
      this.validQuestionStatus.emit((status === 'VALID' && !anyCustomError) ? true : false);
    });


  }
  ngOnInit(): void {
    if(this.saveEventSubject) {
      this.eventsSubscription = this.saveEventSubject.subscribe((index: number) => {
        if(index === this.index) {
          this.saveEvent.emit({
            questionText: this.form.get('questionText')?.value,
            questionPattern:   this.convertHtmlToCustomFormat(this.input.nativeElement.innerHTML),
            answerList: this.extractValuesFromHtml(this.input.nativeElement.innerHTML)
          })
        }
      });
    }
  }
  ngAfterViewInit(): void {
    if(this.input && this.item && this.item.questionPattern) {
      this.form.patchValue({questionText: this.item.questionText})
      const nativeElement = this.input.nativeElement;
      nativeElement.innerHTML = this.convertCustomFormatToHTML(this.item.questionPattern || '', this.item.answerList);
    }
    this.checkIsAnswerExist()
  }
  ngOnDestroy(): void {
    this.eventsSubscription.unsubscribe();
  }

  public handleFormControlErrors(form: FormGroup, error: any, controlName: string): void {
    getFormControlErrors(form, error, controlName, this.translateService);
    this.cdr.detectChanges();
  }

  onRightClick(event: any): void {
    event.preventDefault();
    this.isOpen = true;

    const parentRect = this.base.nativeElement.getBoundingClientRect();
    const x = event.clientX - parentRect.left;
    const y = event.clientY - parentRect.top;

    if(this.transformer) {

      const element = this.transformer.nativeElement;
      element.style.top = `${y}px`;
      element.style.left = `${x}px`;
    }

  }

  public convert(): void {
    const selection = window.getSelection();
    if(selection && selection.rangeCount > 0) {
      this.isOpen = false;

      this.selectedWord = selection.toString();

      const range = selection.getRangeAt(0);
      const startOffset = range.startOffset;

      const divElement = this.input.nativeElement;
      let innerHtml = divElement.innerHTML;

      const part1 = innerHtml.substring(0, startOffset);
      const part2 = innerHtml.substring(startOffset);

      const modifiedHtml = part1 + part2.replace(this.selectedWord, "<mark class='mark'>" + this.selectedWord + "</mark>&nbsp;");
      divElement.innerHTML = modifiedHtml;
    }

    this.checkIsAnswerExist();

  }

  convertCustomFormatToHTML(text: string, answerList?: any[]): string {
    let currentIndex = 0;
    return text.replace(/{}/g, () => {
      const replacement = `<mark class="mark">${answerList ? answerList[currentIndex] : ''}</mark>`;
      currentIndex++;
      return replacement;
    });
  }

  convertHtmlToCustomFormat(htmlString: string) {
    const tempElement = document.createElement('div');
    tempElement.innerHTML = htmlString;

    const markElements = tempElement.querySelectorAll('mark');

    markElements.forEach((markElement) => {
      const replacement = `{}`;
      if(markElement && markElement.parentNode) {
        markElement.parentNode.replaceChild(document.createTextNode(replacement), markElement);
      }
    });

    tempElement.innerHTML = tempElement.innerHTML.replace(/&nbsp;/g, ' ');

    return tempElement.innerHTML;
  }
  extractValuesFromHtml(html: string): string[] {
    const regex = /<mark[^>]*>([^<]+)<\/mark>/g;
    const values: string[] = [];

    let match;
    while ((match = regex.exec(html)) !== null) {
      const value = match[1];
      values.push(value);
    }

    return values;
  };

  public checkIsAnswerExist(): void {
    const length: number = this.extractValuesFromHtml(this.input.nativeElement.innerHTML).length;

    if(length > 2) {
      this.formErrors.answer = "";
      if(this.form.status === 'VALID') {
        this.validQuestionStatus.emit(true);
      }
    }
    else if(length === 0) {
      this.formErrors.answer = "HIGHLIGHT_DESIRED_WORDS";
      this.validQuestionStatus.emit(false);
    }
    else {
      this.formErrors.answer = "CREATE_AT_LEAST_THREE_QUESTIONS";
      this.validQuestionStatus.emit(false);
    }
  }

  public onPaste(event: ClipboardEvent) {
    event.preventDefault();

    const clipboardData = event.clipboardData || (window as any).clipboardData;
    if (clipboardData) {
      const pastedText = clipboardData.getData('text/plain');

      const inputElement = event.target as HTMLElement;
      const selection = window.getSelection();

      if (selection && selection.rangeCount > 0) {
        const range = selection.getRangeAt(0);
        range.deleteContents();
        range.insertNode(document.createTextNode(pastedText));
      } else {
        inputElement.textContent += pastedText;
      }

    }
  };

  public onKeyDown(event: KeyboardEvent): void {
    if(event.key === 'Enter') {
      event.preventDefault();
    }
  }
}

<app-dialog 
  [title]="'NOTE-2' | translate" 
  [confirmBntText]="'continue' | translate" 
  [isOpen]="isOpen"
  [showButtons]="isSignMode"
  [disabledConfirm]="!isChecked" 
  (closeEvent)="closeEvent.emit()"
  (confirmEvent)="confirmEvent.emit()">
  <div class="attention">
    <div class="paragraph" [innerHTML]="'GO_TO_FULL_AGGREMENT' | translate"></div>
    <h1 class="mobile go-down">Прокрутите вниз</h1>
    <div *ngIf="currLang === Language.RU" class="pdf-viewer">
      <h1 class="pdf-viewer__title">ЦЕНА, СРОК И ПОРЯДОК ОПЛАТЫ УСЛУГ</h1>
      <p class="pdf-viewer__text pdf-viewer__text--first"><span class="pdf-viewer__clause">5.1.</span>Заказчик имеет право на изменение текущего тарифа Информационной услуги по своему выбору. Для осуществления такого изменения не требуется заключать дополнительное соглашение. Заказчик должен уведомить письменно Исполнителя о своем намерении изменить тариф посредством электронной почты, 
        указанной в п.3.3 настоящего Договора. С момента получения уведомления о применении нового тарифа оплата услуг будет производиться согласно новому тарифу.</p>
      
      <p class="pdf-viewer__text"><span class="pdf-viewer__clause">5.1.1.</span>тариф Информационной услуги по предметам:</p>
      
      <h1 class="pdf-viewer__title">SMART</h1>
      <p class="pdf-viewer__text">- 1 (один) предмета 32 000</p>
      <p class="pdf-viewer__text">- 2 (два) предмета 38 000</p>
      <p class="pdf-viewer__text">- 3 (три) предмета 50 000</p>

      <h1 class="pdf-viewer__title">GENIUS</h1>
      <p class="pdf-viewer__text">- 3 (три) предмета +IELTS - 52 500 (пятьдесят две тысячи пятьсот) теңге;</p>
      <p class="pdf-viewer__text">- 4 (четыре) предмета - 70 000 (семьдесят тысяч) теңге.</p>

      <p class="pdf-viewer__text"><span class="pdf-viewer__clause">5.2.</span>Стоимость Услуг не включает в себя налог.</p>

      <p class="pdf-viewer__text"><span class="pdf-viewer__clause">5.3.</span>Оплата Услуг по соответствующему Заказу осуществляется Заказчиком на условиях 100 % (стопроцентной) предварительной оплаты в день оформления Заказа. Настоящим Стороны согласовали, что неисполнение или ненадлежащее исполнение Заказчиком обязательства по оплате соответствующего Заказа освобождает Исполнителя от исполнения такого Заказа, т.е. Заказ считается не оформленным Заказчиком, не полученным Исполнителем и соответственно не принятым к исполнению.</p>
      
      <p class="pdf-viewer__text"><span class="pdf-viewer__clause">5.4.</span>СОплата Услуг осуществляется в следующем порядке:</p>

      <p class="pdf-viewer__text"><span class="pdf-viewer__clause">5.4.1.</span>Регистрационный взнос, а также сумма оплаты первого месяца обучения
        оплачивается в день подписания настоящего Договора и направления Заказа
        Заказчиком.</p>
      
      <p class="pdf-viewer__text"><span class="pdf-viewer__clause">5.4.2.</span>Абонентская плата вносится в соответствии с установленными ежемесячными
        платежами, за каждый последующий месяц, в срок не позднее 1 (первого) числа
        соответствующего месяца обучения. 1 (первое) число месяца обучения означает
        календарную дату первого занятия группы, в безналичном порядке следующими
        способами:</p>
      
      <p class="pdf-viewer__text">- платежной картой (Visa, Visa Electron Mastercard, Mastercard Electronic, Maestro, Mastercard),</p>
      <p class="pdf-viewer__text">- путем перевода денег на банковский счет Исполнителя.</p>
      <p class="pdf-viewer__text">- Kaspi Pay</p>

      <p class="pdf-viewer__text"><span class="pdf-viewer__clause">5.5.</span>Некоторые способы оплаты Услуг могут предусматривать обязательство
        Заказчика по оплате комиссии или платы за услуги лиц, осуществляющих прием и
        перечисление платежей, банка и т. п.</p>
      
      <p class="pdf-viewer__text"><span class="pdf-viewer__clause">5.6.</span>Учитывая ограниченность мест и потенциальную упущенную выгоду Исполнителя,
        в случае если Заказчик передумает принимать участие, предварительная 100%
        (стопроцентной) оплата либо любая оплата по Договору не подлежит возврату.
        Возврат уплаченных денежных средств осуществляется лишь в указанных ниже
        случаях, а именно:</p>
      
      <p class="pdf-viewer__text"><span class="pdf-viewer__clause">5.6.1.</span>В случае просьбы возврата при наличии уважительной причины, связанной со
        здоровьем учащегося, либо его родителей.</p>
      
      <p class="pdf-viewer__text"><span class="pdf-viewer__clause">5.6.2.</span>При регистрации на курс без согласия родителей и наличия претензий от
        родителей.</p>
      
      <p class="pdf-viewer__text"><span class="pdf-viewer__clause">5.6.3.</span>При ошибочной регистрации на курс, считая, что курс проходит в оффлайн
        формате.</p>

      <p class="pdf-viewer__text"><span class="pdf-viewer__clause">5.6.4.</span>⁠При принятии решения поступления в иностранные государства и отказ от
        сдачи ЕНТ.</p>

      <p class="pdf-viewer__text"><span class="pdf-viewer__clause">5.7.</span>Документы, подтверждающие факт оплаты (чек, электронные уведомления и т.д.)
        предоставляются Заказчику по письменному запросу в течение 3 (Три) рабочих дней.</p>
    </div>
    <div *ngIf="currLang === Language.KZ" class="pdf-viewer">
      <h1 class="pdf-viewer__title">ҚЫЗМЕТТЕРДІҢ БАҒАСЫ, МЕРЗІМІ ЖӘНЕ ТӨЛЕУ ТӘРТІБІ</h1>
      <p class="pdf-viewer__text pdf-viewer__text--first"><span class="pdf-viewer__clause">5.1.</span>Тапсырыс берушінің өз таңдауы бойынша ақпараттық қызметтің ағымдағы тарифін
        өзгертуге құқығы бар. Мұндай өзгерісті жүзеге асыру үшін қосымша келісім жасасу
        талап етілмейді. Тапсырыс беруші осы Шарттың 3.3-тармағында көрсетілген
        электрондық пошта арқылы тарифті өзгерту ниеті туралы Орындаушыны жазбаша
        хабардар етуге тиіс. Жаңа тарифті қолдану туралы хабарлама алған сәттен бастап
        қызметтерге ақы төлеу жаңа тарифке сәйкес жүргізілетін болады.</p>
      
      <p class="pdf-viewer__text"><span class="pdf-viewer__clause">5.1.1.</span>пәндер бойынша Ақпараттық қызмет тарифтері:</p>
      
      <h1 class="pdf-viewer__title">SMART</h1>
      <p class="pdf-viewer__text">- 1 ( бір) пән 32 000</p>
      <p class="pdf-viewer__text">- 2 (екі) пән 38 000</p>
      <p class="pdf-viewer__text">- 3 (үш) пән 50 000</p>

      <h1 class="pdf-viewer__title">GENIUS</h1>
      <p class="pdf-viewer__text">- 3 (үш) пән +IELTS - 52 500 (елу екі мың бес жүз) теңге;</p>
      <p class="pdf-viewer__text">- 4 (төрт) пән - 70 000 (жетпіс мың) теңге.</p>

      <p class="pdf-viewer__text"><span class="pdf-viewer__clause">5.2.</span>Қызметтердің құны салықты қамтымайды.</p>

      <p class="pdf-viewer__text"><span class="pdf-viewer__clause">5.3.</span>Тиісті тапсырыс бойынша қызметтерге ақы төлеуді тапсырыс беруші тапсырысты ресімдеу күні 100 % (жүз пайыз) алдын ала төлем
        шарттарында жүзеге асырады. Осымен Тараптар Тапсырыс берушінің тиісті
        тапсырысты төлеу жөніндегі міндеттемені орындамауы немесе тиісінше орындамауы
        Орындаушыны осындай тапсырысты орындаудан босатады деп келісті, яғни
        тапсырысты Тапсырыс беруші ресімдемеген, Орындаушы алмаған және тиісінше
        орындауға қабылданбаған болып есептеледі.</p>
      
      <p class="pdf-viewer__text"><span class="pdf-viewer__clause">5.4.</span>Қызметтер үшін ақы төлеу мынадай тәртіппен жүзеге асырылады:</p>

      <p class="pdf-viewer__text"><span class="pdf-viewer__clause">5.4.1.</span>Тіркеу жарнасы, сондай-ақ Оқудың бірінші айы үшін төлем сомасы Орындаушы
        Тапсырыс берушіге осы Шартты және Тапсырыс берушінің тапсырысын жіберген күні
        төленеді.</p>
      
      <p class="pdf-viewer__text"><span class="pdf-viewer__clause">5.4.2.</span>Абоненттік төлем белгіленген ай сайынғы төлемдерге сәйкес әрбір келесі ай
        үшін тиісті оқу айының 1 (бірінші) күнінен кешіктірілмейтін мерзімде енгізіледі. Оқу
        айының 1 (бірінші) күні топтың бірінші сабағының күнтізбелік күнін білдіреді, қолма-қол
        ақшасыз тәртіпте келесі тәсілдермен:</p>
      
      <p class="pdf-viewer__text">- Төлем картасымен (Visa, Visa Electron Mastercard, Mastercard Electronic, Maestro,
        Mastercard),</p>
      <p class="pdf-viewer__text">- Орындаушының банктік шотына ақша аудару арқылы.</p>
      <p class="pdf-viewer__text">- Kaspi Pay</p>

      <p class="pdf-viewer__text"><span class="pdf-viewer__clause">5.5.</span>Қызметтерге ақы төлеудің кейбір тәсілдері Тапсырыс берушінің комиссияны төлеу
        жөніндегі міндеттемесін көздеуі мүмкін, немесе төлемдерді қабылдауды және
        аударуды жүзеге асыратын тұлғалардың, банктің қызметтері үшін төлеу міндеттемесін
        қарастыруы мүмкін.</p>
      
      <p class="pdf-viewer__text"><span class="pdf-viewer__clause">5.6.</span>Орындардың шектеулілігін және Орындаушының ықтимал жіберіп алған пайдасын
        ескере отырып, егер Тапсырыс беруші қатысу ниетін өзгерткен жағдайда, алдын 100%
        (жүз пайыз) Шарт бойынша төлем немесе кез келген төлем қайтаруға жатпайды.
        Төленген ақшаны қайтару тек төменде көрсетілген жағдайларда ғана жүзеге
        асырылады, атап айтқанда:</p>
      
      <p class="pdf-viewer__text"><span class="pdf-viewer__clause">5.6.1.</span>Оқушының немесе оның ата-анасының денсаулығына байланысты дәлелді себеп
        болған жағдайда қайтаруды сұраған жағдайда.</p>
      
      <p class="pdf-viewer__text"><span class="pdf-viewer__clause">5.6.2.</span>Ата-аналардың келісімінсіз және ата-аналардың шағымдарынсыз курсқа тіркелу
        кезінде.</p>
      
      <p class="pdf-viewer__text"><span class="pdf-viewer__clause">5.6.3.</span>Курс офлайн форматта өтеді деп есептей отырып, курсқа қате тіркелу кезінде.</p>

      <p class="pdf-viewer__text"><span class="pdf-viewer__clause">5.6.4.</span>Шет мемлекеттерге оқуға түсу туралы және ҰБТ тапсырудан бас тарту туралы
        шешім қабылдау кезінде.</p>

      <p class="pdf-viewer__text"><span class="pdf-viewer__clause">5.7.</span>төлем фактісін растайтын құжаттар (түбіртек, электрондық хабарламалар және
        т.б.) Тапсырыс берушіге жазбаша сұрау салу бойынша 3 (Үш) жұмыс күні ішінде
        беріледі.</p>
    </div>
    <button (click)="open()" class="pdf-open">
      <div class="left">
        <img src="assets/svg/files/pdf.svg" alt="" />
        <span class="paragraph-2">{{'PROFILE_FULL_AGGREMENT' | translate}}</span>
      </div>
      <button class="open">{{'open' | translate}}</button>
    </button>
    <div *ngIf="isSignMode" class="agree">
      <input type="checkbox" [(ngModel)]="isChecked" />
      <span *ngIf="currLang === Language.RU" class="label-text">
        Я ознакомлен и согласен с вышеуказанными и <span (click)="open()" class='link'> с полными условиями соглашения.</span>
      </span>
      <span *ngIf="currLang === Language.KZ" class="label-text">
        Мен <span class='label-bold'>жоғарыдағы шарттармен</span> және <span (click)="open()" class='link'>толық келісімшартпен</span> таныстым және келісемін.
      </span>
    </div>
  </div>
</app-dialog>
import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DiscountsService } from './../../../../core/services/discounts/discounts.service';
import { IDicountsSubjects, IDiscountOfUser, IDiscountOfUserBase } from 'src/app/core/model/interface/discounts';
import { SelectValue } from 'src/app/shared/models';
import { ToastrService } from 'ngx-toastr';
import { UsersService } from 'src/app/core/services/users/users.service';
import { debounceTime, distinctUntilChanged } from 'rxjs';
import { StudentUser } from 'src/app/core/model';
import { environment } from 'src/environment';
import { LoadingService } from "../../../../shared/services/loading/loading.service";

@Component({
  selector: 'app-discount-creater',
  templateUrl: './discount-creater.component.html',
  styleUrls: ['./discount-creater.component.scss']
})
export class DiscountCreaterComponent implements OnChanges {
  @Input() isCreate: boolean = true;
  @Input() isOpen: boolean = false;
  @Input() username: string = "";
  @Input() editData: IDiscountOfUser | undefined = undefined;
  @Output() confirmEvent = new EventEmitter<IDiscountOfUser | undefined>();
  @Output() closeEvent = new EventEmitter<void>();

  public form!: FormGroup;

  public data: IDicountsSubjects[] = [];
  public subjectsOption: SelectValue[] = [];
  public discountsOption: SelectValue[] = [];
  public accessOptions: SelectValue[] = [];

  public limitOptions: SelectValue[] = [
    {
      name: "1",
      value: 1
    },
    {
      name: "2",
      value: 2
    },
    {
      name: "3",
      value: 3
    },
    {
      name: "4",
      value: 4
    },
    {
      name: "5",
      value: 5
    },
  ]

  public showLimit: boolean = false;
  public disableConfirmBtn: boolean = false;

  public foundUser: StudentUser | undefined;
  public imageUrl: string = "";

  constructor(
    private formBuilder: FormBuilder,
    private discountsService: DiscountsService,
    private cdr: ChangeDetectorRef,
    private toastrService: ToastrService,
    private usersService: UsersService,
    private loadingService: LoadingService
  ) {
    this.form = this.formBuilder.group({
      username: ['', Validators.required],
      always: [true, Validators.required],
      subject: ['', Validators.required],
      discountId: ['', Validators.required],
      discountLeft: [0, Validators.required]
    });

    this.form.get('always')!.valueChanges.subscribe((value) => {
      if (value === 'false') {
        // Add the 'discountLeft' control if 'always' is true
        this.form.addControl('discountLeft', this.formBuilder.control('', Validators.required));

        this.showLimit = true;
      } else {
        // Remove the 'discountLeft' control if 'always' is false
        this.form.removeControl('discountLeft');
        this.showLimit = false;
      }
      this.cdr.detectChanges();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes['isOpen'] && this.isOpen) {
      if(this.isCreate) {
        this.form.addControl('username', this.formBuilder.control('', Validators.required));
      }
      else {
        this.form.get('subject')?.disable();
        this.form.removeControl('username')
      }

      this.discountsService.getSubjects().subscribe({
        next: (res) => {
          this.data = res;
          this.subjectsOption = res.map(item => {
            return {
              value: item.subjectId ? item.subjectId : 'ALL',
              name: item.subjectNameKz
            };
          });
          this.subjectsOption.unshift({name: 'GENIUS', value: '50e82ed9-c7d8-45f4-813e-0d670925d998'})


          this.accessOptions = [
            {
              name: 'Навсегда',
              value: true
            },
            {
              name: 'Ограниченный',
              value: false
            },
          ];

          this.form.patchValue({always: undefined});

          if(!this.isCreate) {
            console.log(this.editData?.discountId)
            const subjectId = this.subjectsOption.find(item => item.name === this.editData!.subjectNameKz)?.value;
            this.form.patchValue({subject: subjectId});
            this.form.get('subject')?.disable();
            this.subjectSelected(subjectId as string);
            setTimeout(() => {
              this.form.patchValue({discountId: this.editData?.discountId});
              this.cdr.detectChanges();
            }, 50)
            this.form.patchValue({always: this.editData?.always});
            if(!this.editData?.always) {
              this.showLimit = true;
              this.form.addControl('discountLeft', this.formBuilder.control(this.editData?.discountLeft, Validators.required));
            }
          }
          else if(this.isCreate && this.username) {
            this.form.patchValue({subject: undefined});
            this.form.patchValue({username: this.username});
            this.form.get('username')?.disable();
            this.form.get('subject')?.enable();
          }
          this.cdr.detectChanges();
        },
        error: (err) => {
          console.log(err);
        }
      })
    }
  }

  public subjectSelected(value: string | number): void {
    if(value === 'ALL' || !value) {
      this.discountsOption = this.data.find(item => item.subjectId === null)!.discounts.map(item => {
        return {
          value: item.id,
          name: item.name
        }
      });
    }
    else {
      this.discountsOption = this.data.find(item => item.subjectId === value)!.discounts.map(item => {
        return {
          value: item.id,
          name: item.name
        }
      });
    }
  }

  public usernameTyping(): void {
    let username = this.form.get('username')?.value;
    this.usersService.getMetaInfo(username).pipe(debounceTime(500), distinctUntilChanged()).subscribe({
      next: (res) => {
        if(res) {
          this.imageUrl = environment.filesUrl + res.profilePhotoUrl;
          this.foundUser = res;
        }
        else {
          this.foundUser = undefined;
        }
        this.cdr.detectChanges();
      },
      error: (err) => {
        this.foundUser = undefined;
        console.log(err);
        this.cdr.detectChanges();
      }
    })
  }

  public createDiscount(): void {
    this.disableConfirmBtn = true;
    this.loadingService.showLoading();
    let data: IDiscountOfUserBase = {
      username: this.form.get('username')?.value,
      always: this.form.get('always')?.value,
      discountId: this.form.get('discountId')?.value,
      discountLeft: this.form.get('discountLeft')?.value ? this.form.get('discountLeft')?.value : 0
    }
    if(this.isCreate) {
      this.discountsService.createDiscountStudentDetail(data).subscribe({
        next: (res) => {
          this.toastrService.success('Вы успешно создали скидки!', 'Успех')
          this.confirmEvent.emit(res);
          this.form.reset();
          this.foundUser = undefined;
          this.disableConfirmBtn = false;
          this.loadingService.hideLoading();
          this.cdr.detectChanges();
        },
        error: (err) => {
          console.log(err);
          this.disableConfirmBtn = false;
          this.loadingService.hideLoading();
        }
      });
    } else {
      this.discountsService.updateDiscountStudentDetail(this.editData!.id, data).subscribe({
        next: (res) => {
          this.toastrService.success('Вы успешно изменили скидку!', 'Успех');
          this.form.reset();
          this.foundUser = undefined;
          res.discountId = data.discountId;
          this.confirmEvent.emit(res);
          this.disableConfirmBtn = false;
          this.loadingService.hideLoading();
          this.cdr.detectChanges();
        },
        error: (err) => {
          console.log(err);
          this.disableConfirmBtn = false;
          this.loadingService.hideLoading();
        }
      });
    }
  }

  public closeDiscount(): void {
    this.form.reset();
    this.foundUser = undefined;
    this.subjectsOption = [];
    this.accessOptions = [];
    this.closeEvent.emit();
  }
}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ICdkSelectOption } from './_model';

@Component({
  selector: 'app-cdk-select',
  templateUrl: './cdk-select.component.html',
  styleUrls: ['./cdk-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CdkSelectComponent implements OnInit {
  @Input() name?: string;
  @Input() options: ICdkSelectOption[] = [];
  @Input() value?: string;
  @Input() withSearch: boolean = false;

  @Output() selectChangeHandler: EventEmitter<any> = new EventEmitter();
  @Output() searchInputHandler: EventEmitter<any> = new EventEmitter();

  ngOnInit(): void {}

  constructor(private cdr: ChangeDetectorRef) {}

  public selectChange(data: any): void {
    this.selectChangeHandler.emit(data)
  }

  public onSearchInput(searchInput: string): void {
    this.searchInputHandler.emit(searchInput)
  }
}

<div class="student-progress">
  <div class="header flex items-center">
    <div class="avatar">
      <ng-container *ngIf="progress.studentPhotoUrl; else defaultPhoto">
        <img
          class="student__avatar-img"
          [src]="getImageUrl()"
          alt="student avatar"
        />
      </ng-container>
      <ng-template #defaultPhoto>
        <div class="no-photo">{{progress.studentFirstname[0]}}</div>
      </ng-template>
    </div>
    <div class="description flex flex-col">
      <div class="name">{{ progress.studentFirstname }} {{ progress.studentLastname }}</div>
      <div class="group-email flex items-center">
        <div class="group">{{ progress.groupName }}</div>
        <div class="circle"></div>
        <div class="email">{{ progress.email }}</div>
      </div>
    </div>
  </div>

  <div class="next-prev-buttons">
    <button
      class="iconic-button secondary-button button-move"
      (click)="openLeftStudent()"
      [disabled]="first">
      <img src="assets/svg/arrow-left-2.svg" alt="" />
      <span>{{'PREVIOUS' | translate}}</span>
    </button>
    <button
    class="iconic-button secondary-button button-move"
      (click)="openRightStudent()"
      [disabled]="last">
      <span>{{'NEXT' | translate}}</span>
      <img src="assets/svg/arrow-right-2.svg" alt="" />
    </button>
  </div>

  <div class="progress-info">
    <div *ngIf="progress?.submissionTime" class="progress-info__status">
      <div class="label">Статус</div>
      <div class="value">
        <ng-container *ngIf="progress.status === 'SUCCESS'">
          <img class="icon" src="assets/svg/base-status_success.svg" alt="" />
        </ng-container>
        <span *ngIf="!isLate;else latePassing">{{progress.status | progressStatusConverter | translate}}</span>
        <ng-template #latePassing>
          <span class="late-text">{{'LATE_SUBMITTED' | translate}}</span>
        </ng-template>
      </div>
    </div>
    <div *ngIf="isLate" class="progress-info__date">
      <div class="label">{{'LATE' | translate}}</div>
      <div class="value">
        {{ formatTimeDifference(_lesson?.deadline!, progress.submissionTime)}}
      </div>
    </div>
    <div class="progress-info__date">
      <div class="label">{{'DUE_DATE' | translate}}</div>
      <div class="value">
        {{
          progress.finishTime
            ? (progress.finishTime
              | utcToLocal
              | date : "dd.MM.yyyy HH:mm" : " " : "ru-KZ")
            : "-"
        }}
      </div>
    </div>
    <div class="progress-info__score">
      <div class="label">{{'GRADE' | translate}}</div>
      <div class="value">
        {{ ((_lesson?.maxScore !== 0 && !_lesson?.maxScore) || progress.status === 'IN_REVIEW') ?
        ("NOT_RATED" | translate) :
        progress.score }}
      </div>
    </div>
  </div>

  <!-- student-grade -->
  <div class="progress">
    <div
      class="progress__test-link"
      *ngIf="lesson.lessonType === LessonType.Quiz && progress.status === LessonStatus.Success"
      (click)="onTestResultClick()"
    >
      {{'EXAM_RESULTS' | translate}}
    </div>

    <div class="files">
      <div *ngIf="lesson.lessonType !== LessonType.Lecture" class="files__title title">
        {{ getLessonTypeName(lesson.lessonType) }}
      </div>
      <div *ngIf="lesson.lessonType !== LessonType.Lecture" class="files__list">
        <ul class="materials" *ngIf="filesLinks.length">
          <li class="material-item" *ngFor="let file of filesLinks">
            <div class="mat-left">
              <img
                [src]="'assets/svg/files/' + (file | fileFormat: true)?.icon"
                alt=""
              />
              <span class="file-name">{{ (file | fileFormat).fileName }}</span>
            </div>
            <button
              class="open"
              [ngClass]="{'close': file === (pdfFileState$ | async)?.url}"
              (click)="openFile(file)">
              {{ file !== (pdfFileState$ | async)?.url ? ('open' | translate) : ('close' | translate)}}
            </button>
          </li>
        </ul>
        <ng-container *ngIf="!filesLinks.length">
          <li>-</li>
        </ng-container>
      </div>

      <div class="" *ngIf="lesson.lessonType === LessonType.Homework || lesson.lessonType === LessonType.WorkOnMistakes">
        <h1 class="title additional__title">{{'additional' | translate}}:</h1>
        <p class="additional">
          {{progress.submissionText || '-'}}
        </p>
      </div>

      <div *ngIf="canReturn">
        <div class="score__form-title title">{{ getLessonTypeName(lesson.lessonType) || "-" }}</div>

        <button
                class="progress__test-link"
                [disabled]="blockReturnLessonBtn"
                (click)="returnLessonEvent()"
        >
          {{'RETURN_LESSON' | translate}}
        </button>
      </div>

      <div *ngIf="showMarkAsCompleted" [class.score__form-title]="canReturn">
        <div *ngIf="!canReturn" class="score__form-title title">{{ getLessonTypeName(lesson.lessonType) || "-" }}</div>

        <app-button
          text="MARK_AS_COMPLETED"
          [isDisabled]="blockCompletedLessonBtn"
          (onButtonClick)="markAsCompleted()"
          [fullWidth]="true"/>
      </div>

      <!-- <div class="comment" *ngIf="lesson.lessonType === 'HOMEWORK'">
        <div class="comment__label title">Комментарий от студента:</div>
        <div class="comment__value">{{ progress?.comment ?? "-" }}</div>
      </div> -->

      <form
        class="score__form"
        [formGroup]="form"
        (submit)="submit()"
        *ngIf="canGrade && (
        lesson?.maxScore === 0 || lesson?.maxScore
        )"
      >
        <div class="score__form-title title">{{'GRADE' | translate}}</div>

        <div class="score__form-body">
          <input
            class="score__form-grade"
            type="number"
            formControlName="grade"
            [max]="lesson.lessonMaxScore"
          />

            <button
              class="score__form-btn"
              [disabled]="form.get('grade')?.value === '' || form.invalid || form.disabled"
            >
              {{'RATE' | translate}}
            </button>
          </div>
          <div class="max-score">
            <div class="max-score__label">{{'MAX_QUANTITY' | translate}}</div>
            <div class="max-score__value">{{ lesson.maxScore }}</div>
          </div>
      </form>
    </div>

    <div *ngIf="lesson.lessonType === LessonType.WorkOnMistakes || lesson.lessonType  === LessonType.Homework" class="comments">
      <div class="comments__header title">{{'COMMENTS' | translate}}</div>

      <form
        action=""
        class="comments__form"
        [formGroup]="formComment"
        (submit)="onSubmit()"
      >
        <div class="comments__form-wrapper">
          <textarea
            class="comments__form-textarea"
            rows="10"
            formControlName="text"
            placeholder="{{'LEAVE_COMMENT' | translate}}"
          >
          </textarea>

          <button class="comments__form-btn"
                  [disabled]="formComment.invalid || isSendingComments">
            <img src="assets/svg/send.svg" alt="" />
          </button>
        </div>
      </form>

      <div class="comments__list">
        <ng-container *ngFor="let comment of progress.comments; index as index">
          <div class="list-comment" *ngIf="comment.commentText">
            <div class="list-comment__header">
              <div class="list-comment__ava-author">
                <div class="list-comment__avatar">
                  <ng-container *ngIf="comment.commenterPhotoUrl">
                    <img
                      class="list-comment__avatar-img"
                      [src]="getMainImageUrl(comment.commenterPhotoUrl)"
                      alt="commentator avatar"
                    />
                  </ng-container>
                  <ng-container *ngIf="!comment.commenterPhotoUrl">
                    <img
                      class="list-comment__avatar-img"
                      src="assets/img/default-ava.webp"
                      alt="no commentator avatar"
                    />
                  </ng-container>
                </div>
                <div class="list-comment__detail flex flex-col">
                  <div class="list-comment__detail-author">
                    {{ comment?.commenterName }}
                    <ng-container *ngIf="comment.commenterId === myId">
                      <span class="list-comment__your-comment">({{'you' | translate}})</span>
                    </ng-container>
                  </div>

                  <div class="list-comment__detail-date">
                    {{ comment?.commentTime | utcToLocal | date : "dd.MM.yyyy в HH.mm" || "-"}}
                  </div>

                  <div class="list-comment__detail-body">
                    <p *ngIf="!comment.isEdit" [innerHTML]="comment?.commentText"></p>

                    <textarea
                      *ngIf="comment.isEdit"
                      class="textarea border radius-8"
                      rows="10"
                      [(ngModel)]="commentValue"
                      [placeholder]="'enterComments' | translate"
                    >
                    </textarea>
                  </div>

                </div>

                <div *ngIf="canRemoveComment(comment.commenterId)" class="list-comment__remove flex justify-end">
                  <button *ngIf="!comment.isEdit"
                          (click)="editComment(comment, index)"
                          class="list-comment__remove-btn edit-btn">
                  </button>
                  <button *ngIf="comment.isEdit"
                          (click)="submitComment(comment)"
                          class="list-comment__remove-btn success-btn">
                  </button>
                  <button
                          (click)="removeComment(comment.commentId)"
                          class="list-comment__remove-btn delete-btn">
                  </button>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>

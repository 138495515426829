import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'utcToLocal',
  pure: true
})
export class UtcToLocalPipe implements PipeTransform {
  transform(utcTime: Date | string | null): string | null {

    // Check if utcTime is provided
    if (!utcTime) {
      return null;
    }
    // Convert UTC time to local time
    const utcDate = new Date(utcTime);
    const localTimezoneOffset = new Date().getTimezoneOffset();
    const localTimeDate = new Date(utcDate.getTime() - localTimezoneOffset * 60000);

    // Format the local time as a string
    return localTimeDate.toISOString();
  }
}

import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { AdminService } from 'src/app/core/services/admin/admin.service';
import { environment } from 'src/environment';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { Roles } from 'src/app/core/model';

@Component({
  selector: 'app-table-info',
  templateUrl: './table-info.component.html',
  styleUrls: ['./table-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class TableInfoComponent implements OnInit, AfterViewChecked {
  @Input() headerColumns: any;
  @Input() rowColumns: any;
  @Input() totalPages?: number;
  @Input() totalCount?: number;
  @Input() pageNumber?: number = 0;
  @Input() isAverageRow: boolean = false;
  @Input() userId: string = "";
  @Input() isLoading: boolean = false;
  @Input() hidePositionMobile: boolean = false;
  @Output() onPageClickListener: EventEmitter<any> = new EventEmitter();
  @Output() onActionClickListener: EventEmitter<any> = new EventEmitter();
  @Output() onFioClickListener: EventEmitter<any> = new EventEmitter();
  @Output() onProcessClick: EventEmitter<any> = new EventEmitter();
  @Output() onTableDrawn: EventEmitter<void> = new EventEmitter<void>()
  public readonly Roles = Roles;
  public role: Roles = Roles.Curator;

  constructor(
    private _adminService: AdminService,
    private authService: AuthService
  ) {}

  public fileBaseUrl: string = environment.filesUrl;

  ngOnInit(): void {
    this.role = this.authService.getRole();
  }

  ngAfterViewChecked(): void {
    if(!this.isLoading) {
      return;
    }
    this.activateLoading();
  }

  private activateLoading(): void {
    const tableRows = document.querySelectorAll('.row-of-table-for-detect-spinner');
    if (tableRows.length > 0) {
      this.onTableDrawn.emit();
    }
  }

  public onActionClick(id: string): void {
    this._adminService.toggleIsShowModal();
    this.onActionClickListener.emit(id);
  }

  public onFioClick(row: any): void {
    this.onFioClickListener.emit(row);
  }

  public onPageClick(page: number): void {
    this.onPageClickListener.emit(page);
  }

  public getScrore(arr: any, acessor: string): string {
    const temp = arr.find((item: any) => item[acessor] >= 0);
    if (temp) {
      return parseInt(temp[acessor]).toFixed(2);
    }
    return '-';
  }

  public getAverage(arr: any, acessor: string): string | number {
    let averageArr: any = [];
    if (acessor !== 'fio')
      arr.forEach((student: any) => {
        if(acessor !== 'finalScore') {
          const scoreObj = student.items.find((item: any) => item[acessor] >= 0);
          if (scoreObj) {
            const score = scoreObj[acessor];
            averageArr.push(score);
          }
        }
        else {
          if(student.finalScore === '-') {
            averageArr.push(0)
          }
          else {
            averageArr.push(student.finalScore)

          }
        }
      });
    else {
      if (acessor === 'fio') {
        return 'Средний бал';
      } else {
        return '';
      }
    }
    const sumArr = averageArr.reduce((a: number, b: number) => a + b, 0);

    return sumArr / averageArr.length
      ? (sumArr / averageArr.length).toFixed(2)
      : '-';
  }

  public progressClick(row: any, header: any, columnId: number): void {
    this.onProcessClick.emit({ row, header, columnId });
  }
}

<div class="student-grade">
  <div class="label">Домашнее задание</div>

  <form class="form flex" [formGroup]="form" (submit)="submit()">
    <input
      class="grade border radius-8 w-100"
      type="number"
      formControlName="grade"
    />

    <button class="btn" [disabled]="form.invalid">Дать оценку</button>
  </form>

  <div class="max-score">Макс. кол-во: {{ progress.lessonMaxScore }}</div>
</div>

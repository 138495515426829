import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileFormatPipe } from './file-format/file-format.pipe';
import { LetterAtPositionPipe } from './letter-at-position/letter-at-position.pipe';
import { ReverseLessonTypePipe } from './reverse-lesson-type/reverse-lesson-type.pipe';
import { TranslateTaskTypePipe } from './translate-task-type/translate-task-type.pipe';
import { MonthConverterPipe } from './month-converter/month-converter.pipe';
import { SafePipe } from './safe/safe.pipe';
import { ProgressStatusConverterPipe } from './progress-status/progress-status-converter.pipe';
import { MyDatePipe } from './date-translate/date-translate.pipe';
import { PhonePipe } from './phone/phone.pipe';
import { UtcToLocalPipe } from './utc-to-local/utc-to-local.pipe';
import { SubjectColorPipe } from './subject-color/subject-color.pipe';
import { TypeWordsQuestionPipe } from './type-words-question/type-words-question.pipe';
import { DateFormatPipe } from './date-format/date-format.pipe';
import { EarlierThanGivenTimePipe } from './earlier-than-given-time/earlier-than-given-time.pipe';
import { TimeDiffPipe } from './time-diff/time-diff.pipe';
import { TimeDiffToDaysPipe } from './time-diff-to-days/time-diff-to-days.pipe';
import { TimeDiffToHoursPipe } from './time-diff-to-hours/time-diff-to-hours.pipe';
import { UtcToLocalDateFormetPipe } from './utcToLocalDateFormat/utcToLocalDateFormet.pipe';
import { TimeDiffToMinutesAndSecondsPipe } from './time-diff-to-minutes-seconds/time-diff-to-minutes-seconds.pipe';

@NgModule({
  declarations: [			
    FileFormatPipe,
    LetterAtPositionPipe,
    ReverseLessonTypePipe,
    TranslateTaskTypePipe,
    MonthConverterPipe,
    SafePipe,
    ProgressStatusConverterPipe,
    MyDatePipe,
    PhonePipe,
    UtcToLocalPipe,
    SubjectColorPipe,
    TypeWordsQuestionPipe,
    DateFormatPipe,
    EarlierThanGivenTimePipe,
    TimeDiffPipe,
    TimeDiffToDaysPipe,
    TimeDiffToHoursPipe,
    UtcToLocalDateFormetPipe,
    TimeDiffToMinutesAndSecondsPipe
   ],
  imports: [CommonModule],
  exports: [
    FileFormatPipe,
    LetterAtPositionPipe,
    ReverseLessonTypePipe,
    TranslateTaskTypePipe,
    MonthConverterPipe,
    SafePipe,
    ProgressStatusConverterPipe,
    MyDatePipe,
    PhonePipe,
    UtcToLocalPipe,
    SubjectColorPipe,
    TypeWordsQuestionPipe,
    DateFormatPipe,
    EarlierThanGivenTimePipe,
    TimeDiffPipe,
    TimeDiffToDaysPipe,
    TimeDiffToHoursPipe,
    UtcToLocalDateFormetPipe,
    TimeDiffToMinutesAndSecondsPipe
  ],
  providers: [TranslateTaskTypePipe, SubjectColorPipe],
})
export class PipesModule {}

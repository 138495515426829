import {Component, ElementRef, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Languages} from './shared/models';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  constructor(private translate: TranslateService,) {
    translate.setDefaultLang(Languages.KZ);
    translate.use(Languages.KZ);          
    const message = {
      content: 'Login',
      channel: 'authorization' // Specify the channel identifier
    };

    window.parent.postMessage(message, '*');

    localStorage.setItem('activeCourses', '')

  }
  
}
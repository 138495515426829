<ul class="list" cdkDropList (cdkDropListDropped)="drop($event)">
    <li class="item" *ngFor="let item of lessonsList;let i = index" [cdkDragDisabled]="isOpenList[i]" cdkDrag>
        <app-lessons-item 
            [shortLesson]="item" cdkDragPlaceholder 
            [isOpen]="isOpenList[i]" 
            (toggle)="toggle($event, i)"
            [groupId]="groupId"
            (removedEvent)="removeLessonById($event)">
        </app-lessons-item>
    </li>
</ul>

import { BaseRoutes, StudentGroupDTO, StudentGroupThemeDTO, StudentRoutes } from 'src/app/core/model';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { getGroupAction, setCurrentGroupId } from 'src/app/core/state/student/groups/groups.actions';
import { StudentHelperService } from '../../../service/student-helper.service';

@Component({
  selector: 'app-side-bar-courses-card',
  templateUrl: './side-bar-courses-card.component.html',
  styleUrls: ['./side-bar-courses-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SideBarCoursesCardComponent implements OnChanges {
  public isOpen: boolean = false;

  @Input() currentGroup: StudentGroupThemeDTO | null | undefined;
  @Input() groupsList: StudentGroupDTO[] | null | undefined = [];
  @Input() percent: number = 0;
  @Input() sortType: 'SORT_BY_NEW' | 'SORT_BY_OLD' = 'SORT_BY_OLD';

  @Output() setLoading: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private router: Router,
    private store: Store,
    private studentHelperService: StudentHelperService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
      console.log(this.groupsList);
      console.log(this.currentGroup)
  }
  toggle(id: string | undefined): void {
    if(!id) {
      return;
    }
    if(window.innerWidth < 999) {
      this.store.dispatch(setCurrentGroupId({id: id}));
      this.router.navigate([`${BaseRoutes.Student}/${StudentRoutes.Education}/${StudentRoutes.ThemesMobile}/${id}`]);
      return;
    }
    this.isOpen = !this.isOpen;
  };
  //
  public openGroup(id: string): void {
    this.studentHelperService.setCurrentGroupId(id);
    this.store.dispatch(getGroupAction({groupId: id, direction: this.sortType === 'SORT_BY_NEW' ? 'DESC' : 'ASC'}));
    this.setLoading.emit();
    this.router.navigate([`${BaseRoutes.Student}/${StudentRoutes.Main}`]);
  }
}

import { DiffucltLevel, TestType } from '../enum';
import { CommentDTO, ProgressDTO, QuizPassingDTO, UserAnswer } from './progress';
import { LessonType } from 'src/app/core/model';

export interface LessonDTO extends LectureDTO, HomeworkDTO, QuizDTO {
  lessonMaxScore: number,
}


export interface LessonWithProgress {
  lesson: LessonDTO,
  progress: ProgressDTO
}

export interface LectureWithProgress {
  lesson: LectureDTO,
  progress: ProgressDTO,
  rated?: boolean
}

export interface HomeworkWithProgress {
  lesson: HomeworkDTO,
  progress: ProgressDTO
}

export interface WorkOnMistakeWithProgress {
  lesson: WorkOnMistakesDTO,
  progress: ProgressDTO
}

export interface QuizWithProgress {
  lesson: QuizDTO,
  progress: ProgressDTO
}

export interface IShortLesson {
  name: string,
  id: string,
  published?: boolean,
  lessonType: LessonType,
  general?: boolean,
  checked?: boolean
}

export interface LectureDTO extends IShortLesson {
  additionalMaterials: string[];
  content: string;
  curator?: boolean;
  description: string;
  lessonProgress?: LessonProgress;
  miniTestMaxScore: number;
  mustHaveMaterials: string[];
  questions?: ForAnswerTestQuestions[];
  rating: boolean;
  usefulLinks: UsefullLink[];
  videoUrl: string;
  common?: boolean;
  courseId?: boolean;
  groupId?: string;
  usersIds?: string[],
  courseName?: string,
  courseFolderId?: string

}

export interface UsefullLink {
  name: string;
  link: string;
}

export interface HomeworkDTO extends IShortLesson {
  description: string;
  rating: boolean;
  deadline: string | null;
  maxScore: number | null;
  hasMaxScore?: boolean;
  hasDate?: boolean;
  materials: string[];
  comments?: CommentDTO[];
  common?: boolean;
  usersIds?: string[],
  courseFolderId?: string
}

export interface WorkOnMistakesDTO extends IShortLesson {
  description: string;
  rating: boolean;
  deadline: string;
  maxScore: number;
  materials: string[];
  comments?: CommentDTO[];
  common?: boolean;
  videoUrl: string;
  usersIds?: string[],
  courseFolderId?: string

}

export interface LessonCreateRequest {
  lessonType: LessonType;
  name: string;
  themeId: string;
}
export interface LessonCreateResponse {
  lessonType: LessonType;
  name: string;
  themeId: string;
}

export interface MiniTestQuestionsListRequest {
  lectureLessonId: string;
}
export interface MiniTestCreateRequest {
  id?: string;
  several?: boolean;
  lectureLessonId: string;
  questionText?: string;
  questionImageUrl?: string;
  shuffle?: boolean;
  answerList?: MiniTestAnswer[];
  questionPattern?: string;
}

export interface ForAnswerTestQuestions extends MiniTestCreateRequest {
  correctAnswerTexts: string[];
  answerList: MiniTestAnswer[];
  difficultyLevel: DiffucltLevel;
  explanation: string;
  id: string;
  keyList: string[];
  quizLessonId: string;
  quizQuestionType: TestType;
  referenceUrl: string;
  valueList: string[];
  userAnswers: UserAnswer[] | null,
  score: number
}

export interface MiniTestQuestion extends MiniTestCreateRequest {
  id: string;
}
export interface MiniTestAnswer {
  id: string;
  answerText: string;
  answerImageUrl: string;
  correct: boolean;
}

export interface MiniTestQuestionAnswerRequest {
  miniTestQuestionId: string;
}

export interface QuizDTO extends IShortLesson {
  description: string;
  rating: boolean;
  minutesDuration: number;
  maxScore: number | null;
  showResults: boolean;
  attachSolution: boolean;
  atypeCount: number;
  btypeCount: number;
  ctypeCount: number;
  deadline: string | null,
  workOnMistakesText: string,
  workOnMistakesReferenceUrl: string;
  common?: boolean;
  questions?: ForAnswerTestQuestions[],
  usersIds?: string[],
  courseFolderId?: string
}

export interface QuizQuestionDTO extends TestQuestionDTO, MatchingDTO {
  id?: string;
  questionId?: string;
  quizQuestionType: TestType;
  difficultyLevel?: DiffucltLevel;
  explanation?: string;
  referenceUrl?: string;
}

export interface TestDTO {
  id: string;
  answerText: string;
  answerImageUrl: string;
  correct: boolean;
}

export interface MatchingDTO {
  questionText?: string;
  keyList?: string[];
  valueList?: string[];
}

export interface TestQuestionDTO {
  quizLessonId?: string;
  questionImageUrl?: string;
  questionText?: string;
  shuffle?: boolean;
  answerList?: any[];
  questionPattern?: string;
  several?: boolean;
}
export interface TestQuestionCustom extends TestQuestionDTO {
  id?: string;
  questionId?: string;
  isSaved?: boolean;
  isValid?: boolean;
}

export interface CustomTestAnswerItem {
  id: string;
  answerImageUrl: string;
  answerText: string;
  correct: boolean;
}

export interface QuizQuestianResponseDTO {
  quizLessonId: string;
  quizQuestionType: TestType;
  difficultyLevel: DiffucltLevel;
  questionText: string;
  explanation: string;
  referenceUrl: string;
  deadline: string
}
export interface LessonProgress {
  curatorFullName: string;
  totalStudentCount: number;
  submittedStudentCount: number;
  reviewedStudentCount: number;
  averageScore: number;
  deadline: string;
  studentProgresses?: StudentProgress[];
}

export interface LessonSummary {
  name: string;
  id: string;
  published: boolean;
  curatorLastname: string;
  submittedCount: number;
  reviewedCount: number;
  curatorFirstname: string;
  averageScore: number | null;
  rating: boolean;
  deadline: string | null;
  lessonType: LessonType
  maxScore: number;
  studentsCount: number;
  notSubmittedCount: number;
  groupId?: string;
  courseId?: string;
  themeId?: string;
  groupName?: string;
  courseName?: string;
}

export interface StudentProgress {
  id: string;
  comments: any[];
  comment: string;
  commented: boolean;
  studentId: string;
  studentFirstname: string;
  studentLastname: string;
  studentProfilePhotoUrl: string;
  lessonId: string;
  lessonName: string;
  themeId: string;
  themeName: string;
  courseId: string;
  courseName: string;
  groupId: string;
  groupName: string;
  score: number;
  status: StudentProgressStatusType;
  quizQuestionIds?: string[];
  questionPassingDtos?: QuizPassingDTO[];
  lessonMaxScore: number;
  dueDate: string;
  submissions: string[],
  submissionTime: string | null,
  submissionText: string | null,
  student: any
  finishTime: string,
  studentPhotoUrl: string | null,
  email: string,
  unreadComments?: boolean
}

export interface QuestionPassingDto {
  questionId: string;
  groupId: string;
  lessonId: string;
  quizQuestionType: string;
  userAnswers: UserAnswer[];
  correctAnswerTexts: string;
  score: number;
}

export type StudentProgressStatusType = 'CREATED' | 'IN_REVIEW' | 'SUCCESS' | 'FAILED';


export enum StudentProgressStatus {
  CREATED = 'Создано',
  IN_REVIEW = 'Не оценено',
  SUCCESS = 'Оценено',
  FAILED = '',
}


export enum HomeworkPassingStatus {
  READ = "READ",
  EDIT = "EDIT"
}

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { UsersService } from '../users/users.service';
import { SortableResponseData } from '../../model';
import { Params } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class ManagerService {
  constructor(private _http: HttpClient, private userService: UsersService) {}

  public getById(id: string): Observable<any> {
    return this._http.get<any>(`/v1/users/managers/${id}`);
  }

  public getAllPageable(arg: {
    page: number;
    size: number;
    search?: string;
    month?: number;
    status?: string;
    from?: string;
    to?: string;
  }): Observable<SortableResponseData<any>> {
    let params: any = {
      page: arg.page,
      size: arg.size,
    };

    if (arg.search) params.search = arg.search;
    if (arg.month) params.month = arg.month;
    if (arg.status) params.status = arg.status;
    if (arg.from) params.from = arg.from;
    if (arg.to) params.to = arg.to;
    return this._http.get<any>(`/v1/users/managers`, { params });
  }

  public getManagersStatistic(arg: {
    page: number;
    size: number;
    search?: string;
    month?: number;
    status?: string;
    from?: string;
    to?: string;
    sort?: string;
    managerRole?: string;
  }): Observable<SortableResponseData<any>> {
    let params: any = {
      page: arg.page,
      size: arg.size,
      sort: [arg.sort ?? '']
    };

    if (arg.search) params.search = arg.search;
    if (arg.month) params.month = arg.month;
    if (arg.status) params.status = arg.status;
    if (arg.from) params.dateFrom = new Date(arg.from).toISOString();
    if (arg.to) params.dateTo = new Date(arg.to).toISOString();
    if (arg.managerRole) params.managerRole = arg.managerRole;
    return this._http.get<any>(`/v1/users/managers/statistics`, { params });
  }

  public activateManager(id: string): Observable<any> {
    let params = {
      id,
    };
    return this._http.patch<any>(`/v1/users/managers/${id}/status/ACTIVE`, {
      params,
    });
  }

  public fireManager(id: string): Observable<any> {
    let params = {
      id,
    };
    return this._http.patch<any>(`/v1/users/managers/${id}/status/FIRED`, {
      params,
    });
  }

  public linkManagersToMonth(
    managers: string[],
    month: number
  ): Observable<any> {
    let params = {
      managerIds: managers,
      month,
    };
    return this._http.patch<any>(`/v1/users/managers/months/${month}`, null, {
      params,
    });
  }


  public _linkManagersToMonth(
    externalManagerIds: string[],
    internalManagerIds: string[],
    month: number
  ): Observable<any> {
    const body = {
      externalManagerIds: externalManagerIds,
      internalManagerIds: internalManagerIds,
    }
    const params = { month };
    return this._http.patch<any>(`/v2/users/managers/months/${month}`, body, {
      params,
    });
  }

  public changeManager(paymentHistoryId: string, managerId: string | null): Observable<any> {
    let params = new HttpParams().set('managerId', managerId ? managerId : ''); // Convert params into HttpParams

    return this._http.patch<any>(`/v1/admin/paymenthistories/${paymentHistoryId}/changemanager`, null, {
      params: params,
    });
  }
}

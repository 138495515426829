import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MethodistUser, ROLES_LABEL, Roles, StudentUser, BaseRoutes, CuratorRoutes } from 'src/app/core/model';
import { ManagerService } from 'src/app/core/services/manager/manager.service';
import { UsersService } from 'src/app/core/services/users/users.service';
import { ToastService } from 'src/app/shared/services/toast/toast.service';
import { DiscountsService } from './../../../../services/discounts/discounts.service';
import { IDiscountOfUser } from "../../../../model/interface/discounts";
import { Router } from "@angular/router";

@Component({
  selector: 'app-admin-modal',
  templateUrl: './admin-modal.component.html',
  styleUrls: ['./admin-modal.component.scss'],
})
export class AdminModalComponent implements OnInit {
  public selectedUser: any;
  public rolesLabel = ROLES_LABEL;
  private userId: string;
  private groupId: string;
  public username: string;
  public role: Roles;
  public isDataLoading = false;
  public isDiscount: boolean = false;
  public isRegistered: boolean = true;

  public isDataChanged: boolean = false;
  avatarSize: 'small' | 'medium' | 'large' = 'large';

  constructor(
    private _userService: UsersService,
    private _managerService: ManagerService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AdminModalComponent>,
    private cdr: ChangeDetectorRef,
    public ts: ToastService,
    private discountsService: DiscountsService,
    private route: Router
  ) {
    this.userId = data.userId;
    this.role = data.role;
    this.isDiscount = data.isDiscount;
    this.username = data.username;
    this.groupId = data.groupId;

    this.dialogRef.backdropClick().subscribe(() => {
      this.cancel();
    });
  }

  ngOnInit(): void {
    this.getUserInfo();
  }

  public cancel(): void {
    this.dialogRef.close(this.isDataChanged ? this.selectedUser : null);
  }

  public getUserInfo(): void {
    this.isDataLoading = true;
    if (this.role === 'MANAGER') {
      this._managerService.getById(this.userId).subscribe({
        next: (res) => {
          this.selectedUser = res;
          this.cdr.detectChanges();
        },
        error: (err) => {
          this.ts.showError('Ошибка при получении данных по пользователю');
        },
        complete: () => {
          this.isDataLoading = false;
          this.cdr.detectChanges();
        },
      });
      return;
    } else if(this.userId) {
      this._userService.getUserById(this.userId).subscribe({
        next: (res) => {
          this.selectedUser = res;

          this._userService.checkUserRegistered(res.username).subscribe({
            next: (res) => {
              this.isRegistered = res;
              this.cdr.detectChanges();
            },
            error: (err) => {
              console.log(err);
            }
          })
          this.cdr.detectChanges();
        },
        error: (err) => {
          this.ts.showError('Ошибка при получении данных по пользователю');
        },
        complete: () => {
          this.isDataLoading = false;
          this.cdr.detectChanges();
        },
      });
    } else if(this.username) {
      this.discountsService.getDiscountStudentDetail(this.username).subscribe({
        next: (res) => {
          this.selectedUser = {
            id: null,
            fio: res?.studentFirstname + " " + res?.studentLastname,
            roles: [Roles.Student],
            phoneNumber: res?.studentPhoneNumber,
            username: res?.studentUsername
          };
          this.cdr.detectChanges();
        },
        error: (err) => {
          console.log(err);
        }
      })
    }
  }

  public edited(data: StudentUser | MethodistUser): void {
    this.selectedUser = data;
    this.isDataChanged = true;
    this.cdr.detectChanges();
  }

  changedEvent(changedData: IDiscountOfUser[] = []): void {
    if (changedData.length) {
      this.selectedUser.discountUserDetails = changedData;
      this.isDataChanged = true;
    }
  }

  navigate(): void  {
    this.cancel();
    this.route.navigate([`/${BaseRoutes.Curator}/${CuratorRoutes.StudentProgress}/${this.groupId}/${this.userId}`]).then();
  }
}

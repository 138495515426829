import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {SystemService} from "../../../../core/services/system/system.service";
import {AlertMessageType, SystemAlerts} from "../../../models/interfaces/system-alerts";
import {Router} from "@angular/router";
import {CommonRoutes} from "../../../../core/model";

@Component({
  selector: 'app-technical-works-page',
  templateUrl: './technical-works-page.component.html',
  styleUrls: ['./technical-works-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TechnicalWorksPageComponent implements OnInit {
  systemAlert: SystemAlerts | null = null;
  constructor(
    private systemService: SystemService,
    private router: Router,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.systemService.getSystemAlerts()
      .subscribe({
        next: value => {
          if (value?.length) {
            this.systemAlert = value[0];
            this.systemService.setSystemAlert(value[0]);
            this.cd.detectChanges();
          } else {
            this.router.navigate([CommonRoutes.Authorization]).then();
          }
        }
      });
  }
}

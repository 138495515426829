<div class="app-drag-words">
  <div *ngIf="!(_QuizState.View === quizState)" class="options-container">
    <div class="options">
      <div
        class="option-option disable-selection"
        *ngFor="let item of options; let i = index"

        draggable="true"
        cdkDrag
        (cdkDragEnded)="onDragEnd($event, i)"
        [cdkDragDisabled]="isQuizStateView() || disabled"
      >
        {{item}}
      </div>
    </div>
  </div>
  <div class="question-container" #container>
    <div class="sentence-wrapper" *ngFor="let sentence of sentences; let last = last; let i = index">
      <span class="sentence disable-selection">{{sentence}}</span>
      <div
        *ngIf="!last"
        class="answer-text disable-selection"
        [ngClass]="
          {
            'answer-correct': (_QuizState.View === quizState) && this.question.correctAnswerTexts && (this.question.correctAnswerTexts[i] === answerText.innerHTML.trim()),
            'answer-incorrect': (_QuizState.View === quizState) && this.question.correctAnswerTexts && (this.question.correctAnswerTexts[i] !== answerText.innerHTML.trim()),
            'answer-incorrect-empty': (_QuizState.View === quizState) && this.question.correctAnswerTexts && ( answerText.innerHTML.trim() === plugText)
          }"
        (cdkDragEnded)="onDragReturn($event, i)"
        [cdkDragDisabled]="(_QuizState.View === quizState) || disabled || !answerText.innerHTML.trim()"
        cdkDrag
        #answerText>{{plugText}}</div>
    </div>
  </div>
</div>
<app-result
  [explanation]="question.explanation"
  [referenceUrl]="question.referenceUrl"
  [score]="question.score"
  *ngIf="(_QuizState.View === quizState) && this.question.correctAnswerTexts"
  [title]="'result' | translate" infoText="">
  <div class="answer-wrapper">
    <div class="block-wrapper">
      <h1 class="answer-title">{{'correctAnswer' | translate}}</h1>
      <div class="answer-sentences">
              <span class="answer-sentence" *ngFor="let sentence of sentences; let last = last; let i = index">
                  <span>{{sentence}}</span>
                  <span *ngIf="!last" class="answer-correct">{{ " " + this.question.correctAnswerTexts[i]}}</span>
              </span>
      </div>
    </div>
  </div>
</app-result>



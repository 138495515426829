<form class="content" [formGroup]="baseForm">
    <app-select
        name="CHOOSE_FOLDER_FOR_TYPE_LESSON"
        placeholder="CHOOSE_FROM_LIST"
        formControlName="courseFolderId"
        [options]="foldersOptions">
    </app-select>

    <app-multi-select
    *ngIf="!data?.common"
    name="STUDENTS_ASSIGNED"
    [options]="studentsList"
    [withSearch]="true"
    [preSelectedOptions]="baseForm.get('usersIds')?.value"
    (selectedValue)="studentsSelected($event)">

    </app-multi-select>
    <div class="deadline">
        <app-date-picker
            *ngIf="isOpen"
            [error]="baseFormErrors.date"
            name="DEADLINE"
            formControlName="date"
            (input)="handleFormControlErrors(baseForm, baseFormErrors, 'date')"
        />
        <app-select-input
            [error]="baseFormErrors.time"
            name="DEADLINE_TIME"
            formControlName="time"
            mask="00:00"
            [options]="timeOptions"
        />
    </div>
    <app-slide-toggle text="TAKE_IN_RATING" formControlName="rating"></app-slide-toggle>
    <div class="inputs">
        <div class="row-1">
            <app-input
                [error]="baseFormErrors.maxScore"
                name="MAX_POINTS_FOR_TASK"
                formControlName="maxScore"
                mask="0*"
                (input)="handleFormControlErrors(baseForm, baseFormErrors, 'maxScore')"
            >
            </app-input>
            <app-input
                [error]="baseFormErrors.minutesDuration"
                name="TEST_DURATION_MIN"
                formControlName="minutesDuration"
                mask="9*"
                (input)="handleFormControlErrors(baseForm, baseFormErrors, 'minutesDuration')">
            </app-input>
            <app-select class="attachSolution" [options]="defaultOptions" name="DEMAND_SOLUTION" formControlName="attachSolution"></app-select>

        </div>
        <div class="row-2">
            <!-- <app-select [options]="defaultOptions" name="Отображение результатов" formControlName="showResults"></app-select> -->
            <app-input mask="0*" name="DISPLAY_TYPE_A" formControlName="atypeCount" [error]="baseFormErrors['atypeCount']"></app-input>
            <app-input mask="0*" name="DISPLAY_TYPE_B" formControlName="btypeCount" [error]="baseFormErrors['btypeCount']"></app-input>
            <app-input mask="0*" name="DISPLAY_TYPE_C" formControlName="ctypeCount" [error]="baseFormErrors['ctypeCount']"></app-input>
            <div class="result">
                <span class="result-label">{{'total' | translate}}</span>
                <span class="result-value">{{ (parseInt(baseForm.get('atypeCount')?.value) + parseInt(baseForm.get('btypeCount')?.value) + parseInt(baseForm.get('ctypeCount')?.value))}}</span>
            </div>
        </div>
    </div>
    <div class="textarea">
        <app-textarea
            [error]="baseFormErrors.description"
            formControlName="description"
            name="TEST_DESCRIPTION"
            (input)="handleFormControlErrors(baseForm, baseFormErrors, 'description')">
        </app-textarea>
    </div>
    <div class="video">
        <div>
            <app-input
                name="NAME_FOR_THE_ERROR_FIELD"
                placeholder="WRITE_NAME"
                formControlName="workOnMistakesText"
            ></app-input>
        </div>
        <div>
            <app-input
                name="LINK_TO_VIDEO"
                placeholder="PASTE_LINK"
                formControlName="workOnMistakesReferenceUrl"
            ></app-input>
        </div>
    </div>
    <form class="quiz-form" [formGroup]="quizForm">
        <ul class="list" formArrayName="list">
            <li class="item-quiz" *ngFor="let itemForm of quizzes.controls;let i = index;" [formGroupName]="i">
                <app-test-content-closed
                    [isExpanded]="!itemForm.get('expanded')?.value"
                    [data]="itemForm.value"
                    [index]="i"
                    (OpenEvent)="openQuestion($event)"
                    [type]="itemForm.value.quizQuestionType"
                    (RemoveEvent)="removeQuestion($event)">
                </app-test-content-closed>
                <div *ngIf="itemForm.get('expanded')?.value"  class="quiz-content">
                    <app-select
                        name="DIFFICULTY_LEVEL"
                        [options]="diffucltLevelOptions"
                        formControlName="difficultyLevel">
                    </app-select>
                    <app-select
                        name="QUESTION_TYPE"
                        formControlName="quizQuestionType"
                        [options]="optionsTest">
                    </app-select>
                    <app-test-test-controller
                        [index]="i"
                        [item]="itemForm.value"
                        (saveEvent)="saveQuestion(i,testType.Test, $event, itemForm.get('id')?.value)"
                        *ngIf="testType.Test === itemForm.value.quizQuestionType"
                        [saveEventSubject]="saveQuizQuestionSubject.asObservable()"
                        (validQuestionStatus)="setValidityOfQuestion($event, i)">
                    </app-test-test-controller>
                    <app-open-test-creator
                        [index]="i"
                        [item]="itemForm.value"
                        (saveEvent)="saveQuestion(i ,testType.Open, $event, itemForm.get('id')?.value)"
                        *ngIf="testType.Open === itemForm.value.quizQuestionType"
                        [saveEventSubject]="saveQuizQuestionSubject.asObservable()"
                        (validQuestionStatus)="setValidityOfQuestion($event, i)">
                    </app-open-test-creator>
                    <app-matching-test-controller
                        [index]="i"
                        [item]="itemForm.value"
                        (saveEvent)="saveQuestion(i ,testType.Matching, $event, itemForm.get('id')?.value)"
                        *ngIf="testType.Matching === itemForm.value.quizQuestionType"
                        [saveEventSubject]="saveQuizQuestionSubject.asObservable()"
                        (validQuestionStatus)="setValidityOfQuestion($event, i)">
                    </app-matching-test-controller>
                    <app-insert-test-controller
                        [index]="i"
                        [item]="itemForm.value"
                        (saveEvent)="saveQuestion(i ,testType.DragAndDrop, $event, itemForm.get('id')?.value)"
                        *ngIf="testType.DragAndDrop === itemForm.value.quizQuestionType"
                        [saveEventSubject]="saveQuizQuestionSubject.asObservable()"
                        (validQuestionStatus)="setValidityOfQuestion($event, i)">
                    </app-insert-test-controller>
                    <app-insert-test-controller
                        [index]="i"
                        [item]="itemForm.value"
                        (saveEvent)="saveQuestion(i ,testType.Fill, $event, itemForm.get('id')?.value)"
                        *ngIf="testType.Fill === itemForm.value.quizQuestionType"
                        [saveEventSubject]="saveQuizQuestionSubject.asObservable()"
                        (validQuestionStatus)="setValidityOfQuestion($event, i)">
                    </app-insert-test-controller>
                    <div class="textarea">
                        <app-textarea formControlName="explanation" name="explanation"></app-textarea>
                    </div>
                    <div class="url">
                        <app-input formControlName="referenceUrl" name="linkAddress"></app-input>
                    </div>
                    <div class="buttons">
                        <button (click)="removeQuestion(i)">
                            <img src="assets/svg/informative/trash.svg" alt="" />
                        </button>
                        <button
                            class="iconic-button primary-button button"
                            [disabled]="!itemForm.get('isValid')?.value"
                            (click)="sendSaveToChild(i)">
                          {{'save' | translate}}
                        </button>
                    </div>
            </div>
            </li>
        </ul>
    </form>
    <button class="iconic-button additional-button button" (click)="addQuiz()">{{'ADD_QUESTION' | translate}}</button>
</form>

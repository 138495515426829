import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MethodistUser, ROLES_LABEL, Roles, StudentUser } from 'src/app/core/model';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { SubjectsService } from 'src/app/core/services/subjects/subjects.service';
import { Languages } from 'src/app/shared/models';
import { TranslateService } from '@ngx-translate/core';
import { UsersService } from '../../../../core/services/users/users.service';
import { ToastrService } from 'ngx-toastr';
import { ButtonTypes } from "../../../models/enums/button.enum";

@Component({
  selector: 'app-block-role-phone-insta-info',
  templateUrl: './block-role-phone-insta-info.component.html',
  styleUrls: ['./block-role-phone-insta-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BlockRolePhoneInstaInfoComponent implements OnInit, OnChanges {
  public rolesLabel = ROLES_LABEL;
  public readonly Roles = Roles;

  public yourRole: Roles = Roles.Admin;
  public isOpen: boolean = false;
  public isOpenContact: boolean = false;
  public isExpanded: boolean = false;
  buttonTypes = ButtonTypes;

  public language: Languages = Languages.KZ;

  @Input() isRegistered: boolean = true;
  @Input() role: Roles = Roles.Student;
  @Input() phone?: string;
  @Input() instagram?: string;
  @Input() user: any;
  @Input() username: string="";
  @Input() isDiscount: boolean = false;

  @Output() editedEvent: EventEmitter<StudentUser | MethodistUser> = new EventEmitter<StudentUser | MethodistUser>()
  @Output() navigateToProgress: EventEmitter<boolean> = new EventEmitter<boolean>();

  public isEditDiscountUserOpen: boolean = false;
  constructor(
    private authService: AuthService,
    public subjectsService: SubjectsService,
    public translateService: TranslateService,
    private cdr: ChangeDetectorRef,
    private usersService: UsersService,
    private toastrService: ToastrService
    ) {
      this.language = translateService.currentLang as Languages;
    }
  ngOnInit(): void {
    this.yourRole = this.authService.getRole()
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log(this.user);
  }
  public openDialog(): void {
    if (this.isDiscount) {
      this.isOpenContact = true;
    } else {
      this.isOpen = true;
    }
  }

  public closeDialog(): void {
    this.isOpen = false;
    this.isOpenContact = false;
  }

  public confirmDialog(data: StudentUser | MethodistUser): void {
    this.isOpen = false;
    this.isOpenContact = false;
    this.user = data;
    this.phone = data.phoneNumber;
    this.instagram = data.instagramLink;
    this.editedEvent.emit(data);
    this.cdr.markForCheck();
  }

  editDiscountUserOpen(): void {
    this.isEditDiscountUserOpen = true;
  }
  editDiscountUserClose(): void {
    this.isEditDiscountUserOpen = false;
  }
  editDiscountUserConfirm(): void {
    this.editDiscountUserClose();
  }

  public toggle(): void {
    this.isExpanded = !this.isExpanded;
  }

  public sendRegistration(): void {
    this.usersService.sendRegistrationLink(this.user.id).subscribe({
      next: (res) => {
        this.toastrService.success('Вы успешно отправили приглашение на регистрацию!','Успех');
      },
      error: (err) => {
        console.log(err);
      }
    })
  }

  public navigateProgress(): void {
    this.navigateToProgress.emit();
  }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  private loadingCount = 0;
  private isLoadingSubject = new BehaviorSubject<boolean>(false);
  public isLoading$ = this.isLoadingSubject.asObservable();
  public loadingPage$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public loadingSideBar$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  showLoading(): void {
    this.loadingCount++;
    this.isLoadingSubject.next(true);
  }

  hideLoading(): void {
    if (this.loadingCount > 0) {
      this.loadingCount--;
    }
    if (this.loadingCount === 0) {
      this.isLoadingSubject.next(false);
    }
  }
}

import { UsersService } from './../../../../core/services/users/users.service';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormControl, FormGroup, FormBuilder, AbstractControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs';
import { IGroupStudentCurator, Roles } from 'src/app/core/model';
import { HeadTeacherService } from 'src/app/core/services/head-teacher/head-teacher.service';
import { ICdkSelectOption } from 'src/app/shared/components/material/cdk-select/_model';
import { SelectValue } from 'src/app/shared/models';
import { ToastService } from 'src/app/shared/services/toast/toast.service';

@Component({
  selector: 'app-distrubuted-groups-of-stream',
  templateUrl: './distrubuted-groups-of-stream.component.html',
  styleUrls: ['./distrubuted-groups-of-stream.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DistrubutedGroupsOfStreamComponent implements OnInit { 
  @Input() streamId!: string;
  @Input() subjectId: string = '';
  @Output() onRowClickedEvent: EventEmitter<{event: Event, groupId: string}> = new EventEmitter<{event: Event, groupId: string}>();

  public coursesOption: ICdkSelectOption[][] = [[]];
  public curatorOption: ICdkSelectOption[][] = [];
  public curatorOption2: SelectValue[][] = [[]];
  public coursesOptionCopy: ICdkSelectOption[][] = [[]];
  public curatorOptionCopy: ICdkSelectOption[][] = [[]];
  public curatorOption2Copy: SelectValue[][] = [];

  public groupsList: any[] = [];
  public groupsForm!: FormGroup;

  constructor(
    private headteacherService: HeadTeacherService,
    private cdr: ChangeDetectorRef,
    private userService: UsersService,
    private ns: ToastService,
    private formBuilder: FormBuilder
  ) {

    this.groupsForm = this.formBuilder.group({
      list: this.formBuilder.array([])
    });
  }

  ngOnInit(): void {
    this.getGroups();
  }
  get groups(): FormArray {
    return this.groupsForm.controls['list'] as FormArray;
  }

  public getGroups(): void {
    this.headteacherService.getGroupsByStreamId(this.streamId).subscribe({
      next: (response) => {
        this.groupsList = response;
        for(let item of response) {
          let form = this.formBuilder.group({
            group: [item.name],
            course: [item.courseId],
            curator: [item.curator ? item.curator.id : ''],
            studentCount: [item.studentCount],
            addCurator: [item.additionalCurators.filter((item: { id: any; }) => item.id).map((item: { id: any; }) => item.id)]
          });
          this.groups.push(form);
        }
        this.changeGroup();
        this.getCourses();
        this.getCurators();
        this.cdr.detectChanges();
      },
    });
  }

  public changeGroup(): void {
    this.groups.controls.forEach((control: AbstractControl, index: number) => {
      if (control instanceof FormGroup) {
        control.valueChanges
        .pipe(debounceTime(500), distinctUntilChanged())
        .subscribe((changedValues) => {

          const data = {
            name: changedValues.group,
            courseId: changedValues.course,
            curatorId: changedValues.curator,
            additionalCuratorIds: changedValues.addCurator
          };

          const groupId = this.groupsList[index].id;

          this.headteacherService.updateGroup(groupId, data).subscribe({
            next: (response) => {
              this.ns.showSuccess(`Группа ${groupId} изменена`);
              this.cdr.detectChanges();
            },
            error: (err) => this.ns.showError(err),
          });
          
          this.cdr.detectChanges();
        });
      }
    });
  }

  public getCourses(): void {
    this.headteacherService.getCoursesBySubjectId(this.subjectId).subscribe({
      next: (response) => {
        const courseOption = response.map((course: any) => ({
          viewValue: course.name,
          value: course.id,
        }));

        for(let i=0; i<this.groups.length; i++) {
          this.coursesOption[i] = courseOption;
        }
        this.coursesOptionCopy = [...this.coursesOption];
        this.cdr.detectChanges();
      },
    });
  }

  public getCurators(): void {
    this.userService.getAllUsersByRole([Roles.Curator]).subscribe({
      next: (response) => {
        const curatorOption = response.map((curator) => ({
          viewValue: `${curator.firstname} ${curator.lastname}`,
          value: curator.id,
        }));
        const curatorOption2 = response.map((curator) => ({
          name: `${curator.firstname} ${curator.lastname}`,
          value: curator.id,
        }));
  
        for(let i=0; i<this.groups.length; i++) {
          this.curatorOption[i] = curatorOption;
        }

        for(let i=0; i<this.groups.length; i++) {
          this.curatorOption2[i] = curatorOption2;
        }

        this.curatorOptionCopy = [...this.curatorOption];
        this.curatorOption2Copy = [...this.curatorOption2];

        this.cdr.detectChanges();
      },
    });
  }

  public onCuratorSearchInputChange(search: string, index: number): void {
    this.userService.getAllUsersByRole(['CURATOR'], search).subscribe({
      next: (response) => {
        this.curatorOption[index] = response.map((curator) => ({
          viewValue: `${curator.firstname} ${curator.lastname}`,
          value: curator.id,
        }));
        this.cdr.detectChanges();
      },
    });
  }

  public onStudentGroupChange(fromGroupId: string, toGroupId: string, student: any): void {
    this.headteacherService
      .switchStudentGroup(this.streamId, student.id, {fromGroup: fromGroupId, toGroup: toGroupId})
      .subscribe({
        next: (res) => {
          this.ns.showSuccess('Студент успешно переведен');
          this.getGroups();
          this.cdr.detectChanges();
        },
        error: (err) => {
          this.ns.showError('Произошла ошибка');
        },
      });
  }

  public onRowClick(event: Event, index: number): void {
    this.onRowClickedEvent.emit({event: event, groupId: this.groupsList[index].id});
  }

  public courseSelected($event: any, index: number): void {
    // this.coursesOption = this.coursesOptionCopy.filter(item => item)
    const groupToUpdate = this.groups.at(index);
    groupToUpdate.patchValue({ course: $event });
  }
  public curatorSelected($event: any, index: number): void {
    const groupToUpdate = this.groups.at(index);
    groupToUpdate.patchValue({ curator: $event });
  }

  public curator2Selected($event: any, index: number): void {
    const groupToUpdate = this.groups.at(index);
    groupToUpdate.patchValue({ addCurator: $event });
  }

  public courseSearching($event: any, index: number): void {
    this.coursesOption[index] = this.coursesOptionCopy[index].filter(item => item.viewValue.toLowerCase().includes($event.toLowerCase()));
  }

  public curatorSearching($event: any, index: number): void {
    this.curatorOption[index] = this.curatorOptionCopy[index].filter(item => item.viewValue.toLowerCase().includes($event.toLowerCase()));
  }

  public curator2Searching($event: any, index: number): void {
    this.curatorOption2[index] = this.curatorOption2Copy[index].filter(item => item.name.toLowerCase().includes($event.toLowerCase()));
  }
}

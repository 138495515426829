<div class="app-lessons-list">
    <div class="base">
        <button
            *ngIf="(!addMaterials?.additionalMaterials || addMaterials?.additionalMaterials?.length === 0)
            && (!addMaterials?.usefulLinks || addMaterials?.usefulLinks?.length === 0 ); else AditionalMaterialsBlog"
            class="block block--first"
            (click)="openAddtionalMaterials()">
            <h1 class="title">{{'ADDITIONALLY_MATERIALS' | translate}}</h1>
            <button>
                <img src="assets/svg/plus-default-gray.svg" alt="">
            </button>
        </button>
        <ng-template #AditionalMaterialsBlog>
            <app-expander [isExpanded]="isAddMatExpanded">
                <button class="block block--first" (click)="toggleAddMat()" button>
                    <h1 class="title">{{'ADDITIONALLY_MATERIALS' | translate}}</h1>
                    <app-primary-arrow [isOpen]="isAddMatExpanded" openColor="#6A7186"></app-primary-arrow>
                </button>
                <div class="content" content>
                    <ul class="add-list">
                        <li class="add-item" *ngFor="let item of materials">
                            <img [src]="'assets/svg/files/' + (item.id | fileFormat).icon" alt="" />
                            <button (click)="openFile(item.link)">
                                <span class="material-name">{{(item.id | fileFormat).fileName }}</span>
                            </button>
                        </li>
                    </ul>
                    <ul class="add-list" *ngIf="addMaterials">
                        <li class="add-item" *ngFor="let item of addMaterials.usefulLinks">
                            <img src="assets/svg/informative/Link.svg" alt="" />
                            <a class="link" [href]="item.link">{{item.name}}</a>
                        </li>
                    </ul>
                    <div class="buttons">
                        <button class="add-button" (click)="openAddtionalMaterials()">
                            <img src="assets/svg/informative/edit.svg" alt="" />
                            <span class="add-button-text">{{'edit' | translate}}</span>
                        </button>
                        <button class="add-button" (click)="deleteAdditionalMaterials()">
                            <img src="assets/svg/informative/trash.svg" alt="" />
                            <span class="add-button-text add-button-text--delete">{{'delete' | translate}}</span>
                        </button>
                    </div>
                </div>
            </app-expander>
        </ng-template>

        <button class="folders" (click)="openFolders.emit()">
            <img src="assets/svg/circles/files-circle.svg" alt="" />
            <span class="folders-text">{{'COURSE_LESSON_TYPE' | translate}}</span>
            <app-primary-arrow
                class="max-left"
                openColor="#285B6E"
                closeColor="#285B6E"
                [initialSlope]="90"
                [slopeDegree]="90"></app-primary-arrow>
        </button>
    </div>
    <div class="block-lesson">
        <button class="block" >
            <h1 class="title">{{'LESSONS' | translate}}</h1>
            <button (click)="openCreateTheme()">
                <img src="assets/svg/plus-default-gray.svg" alt="">
            </button>
        </button>
        <div *ngIf="list.length  < 1" class="no-lesson">
            <img src="assets/img/add-category.png" alt="" />
            <h1 class="no-lesson-text">{{'NO_LESSONS_ADD_THEM' | translate}}</h1>
        </div>
        <ul class="lesson-list">
            <li *ngFor="let item of list">
                <button class="item" (click)="doOpenTheme(item.id ,$event)">
                    <div class="left">
                        <h1 class="name">{{item.name}}</h1>
                        <span class="date">{{'OPENING_DATE' | translate}}: <br> <span class="date-bold">{{formatDate(item.startTime) }}</span></span>
                    </div>
                    <button [matMenuTriggerFor]="menu">
                        <img class="icon" src="assets/svg/triple-dot.svg" alt="" />
                    </button>
                </button>
                <mat-menu #menu="matMenu">
                    <app-menu-default (editClick)="openCreateThemeInEditMode(item)" (deleteClick)="delete(item.id)" [canDuplicate]="false" [canDelete]="isCanDelete"></app-menu-default>
                </mat-menu>
            </li>
        </ul>
    </div>
</div>
<app-add-new-theme [data]="themeData" [isOpen]="isThemeCreateOpen" (closeDialog)="closeCreateTheme()" (createEvent)="themeCreated()"></app-add-new-theme>

<div class="uploader">
    <app-additional-materials-controller [data]="addMaterials" (saveEvent)="closeAdditionalMaterials()" [isOpen]="isAdditionalMaterialsOpen" (cancelEvent)="closeAdditionalMaterials()"></app-additional-materials-controller>
</div>

import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-search-functionality',
  templateUrl: './search-functionality.component.html',
  styleUrls: ['./search-functionality.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchFunctionalityComponent {
  @Output() searchListener: EventEmitter<any> = new EventEmitter<any>();
  @Input() placeholder: string = "search";
  @Input() allowTypingSpace: boolean = true;
}

<div *ngIf="comments && comments.length > 0" class="comments flex flex-col">

  <ng-container *ngFor="let comment of progress?.comments;let index = index">
    <div *ngIf="comment.commentText" class="comment flex flex-col">
      <div class="comment-personal flex flex-row">
        <div class="img__wrapper flex items-center justify-center">
          <app-avatar [imageSrc]="comment.commenterPhotoUrl" [firstLetter]="comment.commenterName[0]"></app-avatar>
        </div>
        <div class="comment-author-info">
          <span class="comment-author text-md">
          {{ comment?.commenterName }}:
          </span>
          <span class="date">
            {{ comment.commentTime | utcToLocal | date: 'd.M.y, HH:mm' : 'kk' }}
          </span>
        </div>

        <div *ngIf="canEditComment(comment)" class="comment-personal-remove">
          <button *ngIf="!comment.isEdit"
                  (click)="editComment(comment, index)"
                  class="comment-personal-remove-btn edit-btn">
          </button>
          <button *ngIf="comment.isEdit"
                  (click)="submitComment(comment)"
                  class="comment-personal-remove-btn success-btn">
          </button>
          <button (click)="removeComment(comment, index)"
                  class="comment-personal-remove-btn delete-btn">
          </button>
        </div>
      </div>
      <div class="comment-text text-md">
        <p *ngIf="!comment.isEdit" [innerHTML]="comment?.commentText"></p>

        <textarea
          *ngIf="comment.isEdit"
          class="text w-100 border radius-8"
          rows="10"
          [(ngModel)]="commentValue"
          [placeholder]="'enterComments' | translate"
        >
        </textarea>
      </div>
    </div>
  </ng-container>

  <div class="score radius-8 flex items-center" *ngIf="progress?.score && isScoreShown">
    <img class="icon" src="assets/svg/success.svg" alt="" />
    <span>{{'GRADE' | translate}}: {{ progress?.score }}</span>
  </div>
</div>

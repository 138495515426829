import { NgModule } from '@angular/core';

import { SvgModule } from './svg/svg.module';
import { BaseModule } from './base/base.module';
import { MaterialModule } from './material/material.module';
import { PagesModule } from './pages/pages.module';
import { NonBaseModule } from './non-base/non-base.module';
import { SkeletonsModule } from './skeletons/skeletons.module';
import { BusinessModule } from './business/business.module';
import { TablesModule } from './tables/tables.module';
@NgModule({
  declarations: [
  ],
  imports: [
    SvgModule,
    BaseModule,
    MaterialModule,
    PagesModule,
    NonBaseModule,
    SkeletonsModule,
    BusinessModule,
    TablesModule
  ],
  exports: [
    SvgModule,
    BaseModule,
    MaterialModule,
    PagesModule,
    NonBaseModule,
    SkeletonsModule,
    BusinessModule,
    TablesModule
  ],
})
export class ComponentsModule {}

import { Injectable } from '@angular/core';
import { RedirectInfo, HeaderSize } from 'src/app/shared/models';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HeaderService {
  private name: string = '';
  private backButtonName: string = '';
  private backButtonValue: string = '';
  private size: HeaderSize = HeaderSize.FULL;
  private description: string = '';
  private menuList: RedirectInfo[] = [];
  private activeMenuName: string = "";

  private nameSubject: BehaviorSubject<string> = new BehaviorSubject<string>(
    this.name
  );
  private backButtonNameSubject: BehaviorSubject<string> =
    new BehaviorSubject<string>(this.backButtonName);
  private backButtonValueSubject: BehaviorSubject<string> =
    new BehaviorSubject<string>(this.backButtonValue);
  private sizeSubject: BehaviorSubject<HeaderSize> =
    new BehaviorSubject<HeaderSize>(this.size);
  private descriptionSubject: BehaviorSubject<string> =
    new BehaviorSubject<string>(this.description);
  private menuSubject: BehaviorSubject<RedirectInfo[]> = new BehaviorSubject<
  RedirectInfo[]
  >(this.menuList);
  private activeMenuNameSubject: BehaviorSubject<string> =
    new BehaviorSubject<string>(this.activeMenuName);
  private deadlineSubject: BehaviorSubject<any> = 
    new BehaviorSubject<any>(null);  

  constructor() {}

  public setName(name: string): void {
    this.name = name;
    this.nameSubject.next(this.name);
  }

  public getName(): Observable<string> {
    return this.nameSubject.asObservable();
  }

  public setBackButtonName(name: string): void {
    this.backButtonName = name;
    this.backButtonNameSubject.next(this.backButtonName);
  }

  public getBackButtonName(): Observable<string> {
    return this.backButtonNameSubject.asObservable();
  }

  public setBackButtonValue(name: string): void {
    this.backButtonValue = name;
    this.backButtonValueSubject.next(this.backButtonValue);
  }

  public getBackButtonValue(): Observable<string> {
    return this.backButtonValueSubject.asObservable();
  }

  public setSize(size: HeaderSize): void {
    this.size = size;
    this.sizeSubject.next(this.size);
  }

  public getSize(): Observable<HeaderSize> {
    return this.sizeSubject.asObservable();
  }

  public setDescription(description: string): void {
    this.description = description;
    this.descriptionSubject.next(this.description);
  }

  public getDescription(): Observable<string> {
    return this.descriptionSubject.asObservable();
  }

  public setMenuList(list: RedirectInfo[]): void {
    this.menuList = list;
    this.menuSubject.next(this.menuList);
  }

  public getMenuList(): Observable<RedirectInfo[]> {
    return this.menuSubject.asObservable();
  }

  public setActiveMenuName(name: string): void {
    this.activeMenuName = name;
    this.activeMenuNameSubject.next(this.activeMenuName);
  }

  public getActiveMenuName(): Observable<string> {
    return this.activeMenuNameSubject.asObservable();
  }

  public passedDeadline(): void {
    this.deadlineSubject.next(null);
  }
  public getUpdateDeadline(): Observable<void> {
    return this.deadlineSubject.asObservable();
  }

  public cleanAll(): void {
    this.name = '';
    this.backButtonName = '';
    this.backButtonValue = '';
    this.size = HeaderSize.FULL;
    this.description = '';
    this.menuList = [];
    this.activeMenuName = "";

    this.nameSubject.next(this.name);
    this.backButtonNameSubject.next(this.backButtonName);
    this.backButtonValueSubject.next(this.backButtonValue);
    this.sizeSubject.next(this.size);
    this.descriptionSubject.next(this.description);
    this.menuSubject.next(this.menuList);
    this.activeMenuNameSubject.next(this.activeMenuName);
  }
}

import {AuthService} from 'src/app/core/services/auth/auth.service';
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {BaseRoutes, Roles} from '../../model';

@Injectable({
  providedIn: 'root'
})
export class RolesGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router
  ) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const role: Roles = this.authService.getRole();
    const targetRoute = this.getTargetRoute(role);

    const currentRoute = this.getBaseRoute(route);

    if (currentRoute !== targetRoute) {
      return this.router.createUrlTree([targetRoute, 'profile']); // Navigate to the profile page of the user's role
    }
    return true;
  }

  private getBaseRoute(routeSnapshot: ActivatedRouteSnapshot): string {
    let baseRoute = '';

    // Traverse up the route tree until reaching the root route
    while (routeSnapshot.parent) {
      baseRoute = routeSnapshot.url.join('/') + baseRoute;
      routeSnapshot = routeSnapshot.parent;
    }
    return baseRoute;
  }

  private getTargetRoute(role: Roles): string {
    // Implement logic to determine the target route based on the role
    // For example, you can use an object or a switch statement to map roles to routes
    switch (role) {
      case Roles.Student:
        return BaseRoutes.Student;
      case Roles.Methodist:
        return BaseRoutes.Methodist;
      case Roles.Curator:
        return BaseRoutes.Curator;
      case Roles.HeadTeacher:
        return BaseRoutes.HeadTeacher;
      case Roles.Teacher:
        return BaseRoutes.Teacher;
      case Roles.Admin:
        return BaseRoutes.Admin;
      case Roles.Manager:
        return BaseRoutes.Manager;
      default:
        return ''; // Handle the default case
    }
  }

}

<div class="app-questions"
    [ngClass]="{'app-questions-not-answered': quizState === QuizState.Attention && question.quizQuestionType !== TestType.Matching}">
    <span class="position">{{(position + 1) + " - " + ('QUESTION' | translate)}}</span>
    <app-editor-viewer [content]="question.questionText || ''"></app-editor-viewer>
    <app-view-photo [image]="question.questionImageUrl"></app-view-photo>
    <div class="excercise">
        <app-default-question
            *ngIf="question.quizQuestionType === TestType.Test && !question.several"
            [question]="question"
            [quizState]="quizState"
            [disabled]="isViewTaskMode"
            (answeredEvent)="answeredEvent.emit($event)"
            >
        </app-default-question>
        <app-multi-correct
            *ngIf="question.quizQuestionType === TestType.Test && question.several"
            [question]="question"
            [quizState]="quizState"
            [disabled]="isViewTaskMode"
            (answeredEvent)="answeredEvent.emit($event)"
            >
        </app-multi-correct>

        <app-type-words
            *ngIf="question.quizQuestionType === TestType.Open"
            [question]="question"
            [quizState]="quizState"
            [readOnly]="isViewTaskMode"
            (answeredEvent)="answeredEvent.emit($event)"
            >
        </app-type-words>

        <app-change-queue
            *ngIf="question.quizQuestionType === TestType.Matching"
            [question]="question"
            [quizState]="quizState"
            [disabled]="isViewTaskMode"
            (answeredEvent)="answeredEvent.emit($event)">
        </app-change-queue>

        <app-insert-words
            *ngIf="question.quizQuestionType === TestType.Fill"
            [question]="question"
            [quizState]="quizState"
            [readOnly]="isViewTaskMode"
            (answeredEvent)="answeredEvent.emit($event)">
        </app-insert-words>

        <app-drag-words
            *ngIf="question.quizQuestionType === TestType.DragAndDrop"
            [question]="question"
            [quizState]="quizState"
            [disabled]="isViewTaskMode"
            (answeredEvent)="answeredEvent.emit($event)">
        </app-drag-words>
    </div>
</div>

import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { debounceTime, distinctUntilChanged, Subject, take, takeUntil } from 'rxjs';
import { BaseRoutes, CataloguesRoutes } from 'src/app/core/model';
import {
  ICatalogue,
} from 'src/app/core/model/interface/catalogues';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { CataloguesService } from 'src/app/core/services/catalogues/catalogues.service';

@Component({
  selector: 'app-cart-modal',
  templateUrl: './cart-modal.component.html',
  styleUrls: ['./cart-modal.component.scss'],
})
export class CartModalComponent implements OnInit, OnDestroy {
  public isAuth: boolean = false;
  public isShow: boolean = false;

  public cartItems: ICatalogue[] = [];
  public totalPrice: number = 0;
  public discountPrice: number = 0;
  public sumPrice: number = 0;

  public activeCourses: any[] = [];

  public catalogueRoutes = CataloguesRoutes;

  public isMobileApp: boolean = false;

  public streamIds: string[] = [];

  public test: any = null;
  public test1: any = null;
  public test2: any = null;
  public test3: any = null;
  public test4: any = null;


  private destroy$ = new Subject<void>();

  constructor(
    private _cataloguesService: CataloguesService,
    private cdr: ChangeDetectorRef,
    private _router: Router,
    private authService: AuthService
  ) {}

  ngOnInit() {
    if(this._router.url.includes(BaseRoutes.MobileApp)) {
      this.isMobileApp = true;
    }
    this._cataloguesService._isShowCartModal$.subscribe((response) => {
      this.isShow = response;

      if(this.isShow) {
        document.body.style.height = '100vh';

        const element = document.getElementById('cart-modal');

        if(element) {
          element.style.position = 'absolute';
        }
      }
      else {
        document.body.style.height = '100%';

        const element = document.getElementById('cart-modal');
        if(element) {
          element.style.position = 'fixed';
        }

      }
      this.cdr.detectChanges();
    });
    this._cataloguesService.cartItems$
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        takeUntil(this.destroy$)
      )
      .subscribe((response) => {
        this.cartItems = response || [];
        this.calculatePrice();

        this.cdr.detectChanges();
      });

    let isMobileApp = localStorage.getItem('mobile_app') ? true : false;
    setTimeout(() => {
      if(!isMobileApp) {
        this.authService._isAuthenticated$.pipe(take(1), debounceTime(1500), distinctUntilChanged()).subscribe((response) => {
          this.isAuth = response;
          if (this.isAuth || localStorage.getItem('email')) {
            this._cataloguesService.getActiveCourse().subscribe({
              next: (response) => {
                let activeCourses = [];

                const storageActiveCourses = JSON.parse(localStorage.getItem('activeCourses') || '[]');
                if(storageActiveCourses?.length) {
                  activeCourses = JSON.parse(localStorage.getItem('activeCourses')!);
                }
                else {
                  localStorage.setItem('activeCourses', JSON.stringify(response.map(item => { return {...item, checked: true}})));
                  activeCourses = JSON.parse(localStorage.getItem('activeCourses')!);
                }

                this.activeCourses = activeCourses;

                this._cataloguesService.activeCourses$.next(response);
                this.calculatePrice();
              },
            });
          }
        });
      }
      else {
        this._cataloguesService.getActiveCourse().subscribe({
          next: (response) => {
            let activeCourses = [];
  
            const storageActiveCourses = JSON.parse(localStorage.getItem('activeCourses') || '[]');
            if(storageActiveCourses?.length) {
              activeCourses = JSON.parse(localStorage.getItem('activeCourses')!);
            }
            else {
              localStorage.setItem('activeCourses', JSON.stringify(response.map(item => { return {...item, checked: true}})));
              activeCourses = JSON.parse(localStorage.getItem('activeCourses')!);
            }
  
            this.activeCourses = activeCourses;
  
            this._cataloguesService.activeCourses$.next(response);
            this.calculatePrice();
            this.cdr.detectChanges();
          },
          error: (err) => {
            this.cdr.detectChanges();
          }
        });
      }
      this.cdr.detectChanges()
    }, 1000)
  }

  private calculatePrice(): void {
      this.streamIds = (JSON.parse(localStorage.getItem('cart')!) as any) ?
      (JSON.parse(localStorage.getItem('cart')!) as any).map((item: { id: string; }) => item.id) : [];
      const activeCourse = this.activeCourses
      .filter(item => item.id && item.checked)
      .map(item => item.id);

      if(activeCourse && this.activeCourses[0] && activeCourse.length > 0) {
        this.streamIds.push(...activeCourse)
      }
      const username: string | null = localStorage.getItem('username') || localStorage.getItem('email');

      if(this.streamIds && this.streamIds.length > 0) {
        this._cataloguesService.calculateTotalSum(this.streamIds, username, "").subscribe({
          next: (res) => {
            this.totalPrice = res.totalPrice;
            this.sumPrice = res.resultPrice;
            console.log()
            this.discountPrice = res.totalPrice-res.resultPrice;
            this.cdr.detectChanges();
          },
          error: (err) => {
            console.log(err);
          }
        })
      }
      else {
        this.totalPrice = 0;
        this.sumPrice = 0;
        this.discountPrice = 0;
      }
  }

  public onToggleCheckedActiveCourse(id: string): void {
    const indx = this.activeCourses.findIndex((group) => group.id === id);
    this.activeCourses[indx].checked = !this.activeCourses[indx].checked;
    this._cataloguesService.activeCourses$.next(
      this.activeCourses.filter((group) => group.checked)
    );
    localStorage.setItem('activeCourses', JSON.stringify(this.activeCourses))
    this.calculatePrice();
  }

  public toggleIsShowModal(): void {
    this._cataloguesService.toggleIsShowCartModal();
    document.body.style.height = '100%'
  }

  public goToOrder(): void {
    try {
      if(this.isMobileApp) {
        this._router.navigate([
          BaseRoutes.MobileApp,
          this.catalogueRoutes.ORDER,
        ]);
      }
      else {
        this._router.navigate([
          this.catalogueRoutes.MAIN,
          this.catalogueRoutes.ORDER,
        ]);
      }
      this._cataloguesService.closeShowCardModal();
    }
    catch {
      this._router.navigate([
        BaseRoutes.MobileApp,
        this.catalogueRoutes.ORDER,
      ]);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}

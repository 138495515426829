import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormControlName,
  FormGroup,
} from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { SelectValue } from 'src/app/shared/models';

@Component({
  selector: 'app-multi-select',
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class MultiSelectComponent implements OnInit, OnChanges {
  ngOnChanges(changes: SimpleChanges): void {
    this.ngOnInit();

  }
  @ViewChild('select') select!: MatSelect;

  @Input() name: string = '';
  @Input() isActive: boolean = true;
  @Input() options: SelectValue[] = [];
  @Input() preSelectedOptions: string[] | number[] = [];
  @Input() error: string = '';
  @Input() withSearch: boolean = false;
  @Input() placeholder: string = '';
  @Input() withApply: boolean = true;
  @Input() disabled: boolean = false;
  @Output() selectedValue: EventEmitter<any[]> = new EventEmitter<any[]>();
  @Output() searchInputHandler: EventEmitter<any> = new EventEmitter();

  public values: any[] = [];
  public focused: boolean = false;
  public filteredOptions: any[] = [];
  private originalOptions: any[] = [];
  public allSelected: boolean = false;

  onFocus(): void {
    this.focused = true;
  }

  onBlur(): void {
    this.focused = false;
  }

  onValueChange(value: any[]): void {
    this.preSelectedOptions = value;
  }

  onClosed(): void {
    this.selectedValue.emit(this.preSelectedOptions);
  }

  closeSelect(): void {
    this.select.close();
  }

  filterOptions(event: Event): void {
    const value = (event?.target as HTMLInputElement).value.trim();

    if (value) {
      this.filteredOptions = this.originalOptions.filter(
        (option) =>
          option.name.toString().toLowerCase().includes(value.toLowerCase()) &&
          option !== this.options
      );
    } else {
      this.filteredOptions = [...this.originalOptions];
    }
    if (this.filteredOptions.length > 0) return;
    this.searchInputHandler.emit(value);
  }

  public onSearch(value: string): void {
    // debugger
    if (value) {
      this.filteredOptions = 
      this.originalOptions.filter((option) => (option.name.toString().toLowerCase().includes(value.toLowerCase()) &&
      option !== this.options));

      this.filteredOptions.push(...this.originalOptions.filter(
        (option) =>
          this.preSelectedOptions.includes(option.value as never)
      ))
    } else {
      this.filteredOptions = [...this.originalOptions];
    }
    if (this.filteredOptions.length > 0) return;
    this.searchInputHandler.emit(value);
  }

  toggleAllSelection() {
    this.allSelected = !this.allSelected;

    if (this.allSelected) {
      this.select.options.forEach((item: MatOption) => {
        if(item.value) {
          item.select()
        }
        else {
          item.deselect();
        }
      });
    } else {
      this.select.options.forEach((item: MatOption) => item.deselect());
    }

  }

  ngOnInit(): void {
    this.originalOptions = [...this.options];
    this.filteredOptions = this.originalOptions;
  }
}

<app-dialog [isOpen]="isOpen" [title]="'additionalCourseMaterials' | translate" (closeEvent)="closeDialog()"
            [showButtons]="false">
  <div class="app-additional-materials-dialog">
    <ul class="materials" *ngIf="materials && materials.length > 0">
      <li class="material-item" *ngFor="let material of materials">
        <div class="mat-left">
          <img [src]="'assets/svg/files/' + (material.link | fileFormat)?.icon" alt=""/>
          <span class="file-name">{{(material.link | fileFormat).fileName }}</span>
        </div>
        <button class="open" (click)="openFile(material.link)">{{'open' | translate}}</button>
      </li>
    </ul>
    <h1 class="helpfull-title" *ngIf="data?.usefulLinks?.length">{{'helpfulLinks' | translate}}</h1>
    <ul class="links">
      <li class="link" *ngFor="let item of data?.usefulLinks;let i=index">
        <span>{{i + 1}}. <a [href]="item.link" target="_blank" class="special">{{item.name}}</a></span>
      </li>
    </ul>
  </div>
</app-dialog>

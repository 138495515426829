import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IShortLesson, LessonDTO } from '../../model';

@Injectable({
  providedIn: 'root'
})
export class MethodistService {

  constructor(private httpClient: HttpClient) { }

  public getLessonsList(themeId: string): Observable<IShortLesson[]> {
    return this.httpClient.get<IShortLesson[]>(`/v2/methodist/themes/${themeId}/lessons`);
  }

  public getLesson(lessonId: string): Observable<LessonDTO> {
    return this.httpClient.get<LessonDTO>(`/v2/methodist/lessons/${lessonId}`)
  }

}

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Languages, SelectValue } from 'src/app/shared/models';
import { RegionService } from './../../../../core/services/region/region.service';
import { FormBuilder, FormGroup, Validators, } from '@angular/forms';

@Component({
  selector: 'app-select-town',
  templateUrl: './select-town.component.html',
  styleUrls: ['./select-town.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectTownComponent implements OnInit, OnChanges {
  @Input() preSelectedValue: string = "";
  @Output() valueSelected: EventEmitter<any> = new EventEmitter<any>();

  private optionsKZ: SelectValue[] = [];
  private optionsRU: SelectValue[] = [];
  public options: SelectValue[] = this.optionsKZ;
  
  public currentLang: Languages = Languages.KZ;
  
  public form!: FormGroup;
  constructor(
    private translateService: TranslateService,
    private regionService: RegionService,
    private cdr: ChangeDetectorRef,
    private formBuilder: FormBuilder
  ) { 
    this.translateService.onLangChange.subscribe((lang) => {
      this.currentLang = lang.lang as Languages; 
      if(this.currentLang === Languages.KZ) {
        this.options = this.optionsKZ;
      }
      if(this.currentLang === Languages.RU) {
        this.options = this.optionsRU;
      }
      this.cdr.detectChanges();
    });

    this.form = this.formBuilder.group({
      region: ['', Validators.required]
    })
  }

  ngOnInit() {
    this.regionService.getRegions().subscribe({
      next: (res) => {
        this.optionsKZ = res.map(item => { return {value: item.id, name: item.nameKz}});
        this.optionsRU = res.map(item => { return {value: item.id, name: item.nameRu}});

        if(this.currentLang === Languages.KZ) {
          this.options = this.optionsKZ;
        }
        if(this.currentLang === Languages.RU) {
          this.options = this.optionsRU;
        }
        this.cdr.detectChanges();
      },
      error: (err) => {

      }
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
      if(changes['preSelectedValue']) {
        this.form.patchValue({'region': this.preSelectedValue});
      }
  }

  public selected($event: any): void {
    this.valueSelected.emit($event);
  }
}

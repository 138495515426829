import { StorageService } from './../../../../core/services/storage/storage.service';
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges,} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ICatalogue, PaymentType,} from 'src/app/core/model/interface/catalogues';
import {CataloguesService} from 'src/app/core/services/catalogues/catalogues.service';
import {SUBJECTS_ID} from 'src/app/shared/models/subjects';
import {OrderDialogComponent} from '../order-dialog/order-dialog.component';
import {environment} from 'src/environment';
import { debounceTime, distinctUntilChanged, switchMap, tap, filter } from 'rxjs';
import { FormControl } from "@angular/forms";
import { StudentService } from "../../../../core/services/student/student.service";
import { LoadingService } from 'src/app/shared/services/loading/loading.service';
import { AuthService } from "../../../../core/services/auth/auth.service";

@Component({
  selector: 'app-order-info',
  templateUrl: './order-info.component.html',
  styleUrls: ['./order-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderInfoComponent implements OnInit, OnChanges {
  @Input() isValidFormInfo: boolean = false;
  @Input() formInfo: any = '';
  @Input() phone: string = "";
  @Input() email: string = "";
  @Input() catalogues: ICatalogue[] = [];

  public isOpenDialog: boolean = true;
  public isExistActivePromoCode: boolean = false;

  public itemsCart: ICatalogue[] = [];
  public subjectsId = SUBJECTS_ID;
  public totalPrice: number = 0;
  public discountPrice: number = 0;
  public sumPrice: number = 0;
  public paymentType: PaymentType = null;

  public activeCourses: any = [];
  public streamIds: string[] = [];

  promoCode: FormControl = new FormControl<any>(null);

  public isAttentionOpen: boolean = false;
  constructor(
    private _cataloguesService: CataloguesService,
    private _studentService: StudentService,
    private _cdr: ChangeDetectorRef,
    public dialog: MatDialog,
    private storageService: StorageService,
    private loadingService: LoadingService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this._cataloguesService.cartItems$.subscribe((response) => {
      this.itemsCart = response;
      this._cdr.detectChanges();
    });

    this._cataloguesService.activeCourses$.pipe(
      debounceTime(1500),
      distinctUntilChanged())
      .subscribe((response) => {
      this.activeCourses = JSON.parse(localStorage.getItem('activeCourses') || '[]').filter((item: { checked: any; }) => item.checked);
      this.streamIds = (JSON.parse(localStorage.getItem('cart')!) as any) ?
      (JSON.parse(localStorage.getItem('cart')!) as any).map((item: { id: string; }) => item.id) : [];
      const username: string | null = localStorage.getItem('username') || localStorage.getItem('email');

      if(this.activeCourses && this.isAuthenticated) {
        this.streamIds.push(...this.activeCourses.map((item: { id: any; }) => item.id))
      }

      if(this.streamIds && this.streamIds.length > 0) {
        this.checkActivePromoCodes();
        this._cataloguesService.calculateTotalSum(this.streamIds, this.email,this.phone).pipe(debounceTime(1500), distinctUntilChanged()).subscribe({
          next: (res) => {
            this.totalPrice = res.totalPrice;
            this.sumPrice = res.resultPrice;
            this.discountPrice = res.totalPrice-res.resultPrice;
            this._cdr.detectChanges();
          },
          error: (err) => {
            console.log(err);
          }
        })
      }
      this._cdr.detectChanges();
    });

    this.promoCode.valueChanges!
    .pipe(
      debounceTime(500)
    )
    .subscribe({
        next: value => {
          const uppercaseValue = value.toUpperCase();
          if (value !== uppercaseValue) {
            this.promoCode.setValue(uppercaseValue);
          }
          const streamIds: string[] = (JSON.parse(localStorage.getItem('cart')!) as any) ?
            (JSON.parse(localStorage.getItem('cart')!) as any).map((item: { id: string; }) => item.id) : [];

          if(this.activeCourses && this.isAuthenticated) {
            streamIds.push(...this.activeCourses.map((item: { id: any; }) => item.id))
          }

          this._cataloguesService.calculateTotalSum(streamIds, this.email, this.phone, this.promoCode.value)
            .subscribe({
              next: (res) => {
                this.totalPrice = res.totalPrice;
                this.sumPrice = res.resultPrice;
                this.discountPrice = res.totalPrice-res.resultPrice;
                this._cdr.detectChanges();
              },
              error: (err) => {
                console.log(err);
              }
            });
        }
    });
  }

  private checkActivePromoCodes(): void {
    this._studentService.checkActivePromoCode(this.streamIds, this.itemsCart?.map(x=>x.subjectId))
      .subscribe({
        next: res => {
          this.isExistActivePromoCode = res;
          this._cdr.detectChanges();
        }
      })
  }

  ngOnChanges(changes: SimpleChanges): void {
      if(changes['phone'] && !changes['phone'].firstChange) {
        this._cataloguesService.activeCourses$.subscribe((response) => {
          this.activeCourses = response;
          console.log(this.activeCourses);

          const streamIds: string[] = (JSON.parse(localStorage.getItem('cart')!) as any) ?
          (JSON.parse(localStorage.getItem('cart')!) as any).map((item: { id: string; }) => item.id) : [];

          if(this.activeCourses && this.isAuthenticated) {
            streamIds.push(...this.activeCourses.map((item: { id: any; }) => item.id))
          }
          if(streamIds && streamIds.length > 0) {
            this._cataloguesService.calculateTotalSum(streamIds, this.email, this.phone).pipe(debounceTime(500), distinctUntilChanged()).subscribe({
              next: (res) => {
                this.totalPrice = res.totalPrice;
                this.sumPrice = res.resultPrice;
                this.discountPrice = res.totalPrice-res.resultPrice;
                this._cdr.detectChanges();
              },
              error: (err) => {
                console.log(err);
              }
            })
          }
          this._cdr.detectChanges();
        });
      }
      if(changes['email'] && !changes['email'].firstChange) {
        this._cataloguesService.activeCourses$.subscribe((response) => {
          this.activeCourses = response;
          console.log(this.activeCourses);

          const streamIds: string[] = (JSON.parse(localStorage.getItem('cart')!) as any) ?
          (JSON.parse(localStorage.getItem('cart')!) as any).map((item: { id: string; }) => item.id) : [];

          if(this.activeCourses && this.isAuthenticated) {
            streamIds.push(...this.activeCourses.map((item: { id: any; }) => item.id))
          }
          if(streamIds && streamIds.length > 0) {
            this._cataloguesService.calculateTotalSum(streamIds, this.email, this.phone).pipe(debounceTime(500), distinctUntilChanged()).subscribe({
              next: (res) => {
                this.totalPrice = res.totalPrice;
                this.sumPrice = res.resultPrice;
                this.discountPrice = res.totalPrice-res.resultPrice;
                this._cdr.detectChanges();
              },
              error: (err) => {
                console.log(err);
              }
            })
          }

          this._cdr.detectChanges();
        });
      }
  }

  public onChoosePaymentType(paymentType: PaymentType): void {
    this.paymentType = paymentType;
  }

  public openAttention(): void {

    this._cataloguesService.checkCoursesInCart(this.catalogues);

    if(localStorage.getItem('token')) {
      this.onGetPaymentLink();
      return;
    }

    setTimeout(() => {
      document.body.scrollTop = document.documentElement.scrollTop = 0;
      this.isAttentionOpen = true;
      this._cdr.detectChanges();
    }, 10)
  }
  public closeAttention(): void {
    this.isAttentionOpen = false;
  }
  public onGetPaymentLink(): void {
    this.loadingService.showLoading();
    this._cataloguesService
      .getPaymentLink({
        mail: this.formInfo.email,
        firstName: this.formInfo.firstName,
        type: this.paymentType,
        lastName: this.formInfo.lastName,
        phoneNumber: this.getPhoneNumber(),
        iin: this.formInfo.iin,
        managerId:
          this.formInfo.managerId !== 'null'
            ? this.formInfo.managerId
            : null,
        successUrl: `${environment.base}/order/success?email=${this.formInfo.email}`,
        failUrl: `${environment.base}/order/failed?email=${this.formInfo.email}`,
        buyStreamIds: this.itemsCart.map((item) => item.id),
        prolongStreamIds: (this.activeCourses && this.isAuthenticated) ? this.activeCourses.map((course: any) => course.id) : [],
        promoCode: this.isExistActivePromoCode ? this.promoCode?.value?.trim() : ''
      })
      .subscribe({
        next: (response) => {
          window.open(response.paymentLink, '_blank');
          this.dialog.open(OrderDialogComponent, {
            data: { link: response.paymentLink },
          });
          this.loadingService.hideLoading();
          this._cdr.detectChanges();
        },
        error: (err) => {
          this.loadingService.hideLoading();
          this._cdr.detectChanges();
        }
      });
  }

  private getPhoneNumber(): string {
    const phone = this.formInfo.phone;
    if (phone?.includes('+7')){
      return phone;
    }
    return `+7${phone}`;
  }

  public toggleIsOpenDialog(): void {
    this.isOpenDialog = !this.isOpenDialog;
  }

  public isPaymentDisabled(): boolean {
    if(this.storageService.getToken()) {
      return false;
    }
    else {
      return (
        !this.formInfo ||
        !this.isValidFormInfo ||
        (this.itemsCart.length < 1 && this.activeCourses.length < 0)
      );
    }
  }

  public isContinueButtonDisabled(): boolean {
    return this.isPaymentDisabled() || !this.paymentType;
  }

  get isAuthenticated(): boolean {
    return this.authService._isAuthenticated$?.value;
  }
}

import { ToastrService } from 'ngx-toastr';
import { HelperService } from 'src/app/shared/services/helper/helper.service';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FilesService } from 'src/app/core/services/files/files.service';
import { environment } from 'src/environment';
import { Roles } from 'src/app/core/model';
import { AuthService } from './../../../../core/services/auth/auth.service';
import { ConverterService } from './../../../../core/services/converter/converter.service';
import { HttpClient } from '@angular/common/http';
import { AmplitudeService } from 'src/app/core/services/analytics/amplitude.service';

@Component({
  selector: 'app-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileUploaderComponent implements OnChanges {
  @Output() downloadedEvent = new EventEmitter<string>();
  @Output() removeEvent = new EventEmitter<string>();

  public fileLinks: string[] = [];
  @Input() idList: string[] = [];
  @Input() error: string = "";
  @Input() allowAdditionalFormats: string = "";
  @Input() isViewTaskMode: boolean = false;

  public dragging: boolean = false;

  private acceptedTypes = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/pdf'];

  constructor(
    private filesService: FilesService,
    private cdr: ChangeDetectorRef,
    private toastrService: ToastrService,
    private authService: AuthService,
    private converterService: ConverterService,
    private httpClient: HttpClient,
    private amplitudeService: AmplitudeService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['idList']) {
      this.fileLinks = [];

      for (const id of this.idList) {
        this.fileLinks.push(environment.filesUrl + id);
      }
    }
  }
  public onDragOver(event: Event): void {
      event.preventDefault();
      this.dragging = true;
  }
  public onDragLeave(): void {
    this.dragging = false;
  };

  public onDropSuccess(event: DragEvent): void {
    event.preventDefault();
    this.dragging = false;
    const file = event.dataTransfer?.files;
    this.sendFile(file);
  }


  public onFileSelected(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    this.sendFile(inputElement.files);
    inputElement.value = '';
  }

  private sendFile(files: FileList | undefined |  null): void {
    if(files && files.length > 0) {
      const maxFileSize: number = 100 * 1024 * 1024; //100mb
      if(files[0].size > maxFileSize) {
        this.toastrService.error('Размер файла должен быть меньше 100МБ', 'Ошибка');
        return;
      }
      let data: FormData = new FormData();
      data.append('material', files[0], files[0].name);

      this.filesService.sendFiles(data).subscribe(res => {
        this.fileLinks.push(res.fileId);
        this.downloadedEvent.emit(res.fileId);

        if(this.authService.getRole() === Roles.Student) {
          this.amplitudeService.trackEvent('getPlayed', res.fileId)
        }
        this.cdr.detectChanges();
      });
    }
  }

  public deleteFile(index: number): void {
    this.removeEvent.emit(this.idList[index]);
    this.fileLinks.splice(index, 1);
  };

  downloadFile(url: string): Promise<Blob | undefined> {
    return this.httpClient.get(url, { responseType: 'blob' }).toPromise();
  }

  private extractFileNameFromUrl(url: string): string {
    // Extract the filename from the URL
    const urlParts = url.split('/');
    const fileName = urlParts[urlParts.length - 1];
    return fileName;
  }

  private changeUntilLastDot(inputString: string, replacement: string): string {
    // Find the index of the last dot in the input string
    const lastDotIndex = inputString.lastIndexOf('.');
    
    // If there's no dot in the string, return the original string
    if (lastDotIndex === -1) {
        return inputString;
    }
    
    // Extract the substring until the last dot (excluding)
    const substringUntilLastDot = inputString.slice(0, lastDotIndex);
    
    // Concatenate the substring with the replacement
    const result = substringUntilLastDot + replacement;
    
    return result;
  }

  getFileExtension(fileName: string): string {
    return fileName.split('.').pop()?.toLowerCase() || '';
  }
}





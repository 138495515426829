import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import {Observable, finalize} from 'rxjs';

import { environment } from 'src/environment';
import { StorageService } from '../services/storage/storage.service';
import { TranslateService } from "@ngx-translate/core";
import { LoadingService } from 'src/app/shared/services/loading/loading.service';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  constructor(
    private storageService: StorageService,
    private translationService: TranslateService,
    private loadingService: LoadingService
    ) {
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Exclude specific requests from adding the token
    if (request.url.includes('/assets/i18n/')) {
      return next.handle(request);
    }

    request = request.clone({
      setHeaders: {
        'Accept-Language': this.translationService.currentLang
      }
    })

    if (
      (request.url.includes('/auth/signin') && !request.url.includes('/auth/signintoken')) ||
      request.url.includes('/auth/signup') ||
      request.url.includes('/auth/registrationtoken/') ||
      request.url.includes('/auth/recoverpassword')
    ) {
      const corsRequest = request.clone({
        url: environment.baseUrl + request.url,
        setHeaders: {
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE',
          'Access-Control-Allow-Headers': 'Content-Type',
        },
      });
      return next.handle(corsRequest);
    }

    const token = this.storageService.getToken();
    if (!token) {
      // Handle case where token is not available (e.g., redirect to login)
    }

    const modifiedRequest = request.clone({
      url: request.url.includes("converter.juz40-edu.kz") ? request.url : environment.baseUrl + request.url,
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });


    let shouldShowLoading = false;
    if(
      request.url.includes('/v1/student/groups') ||
      // request.url.includes('/v1/student/progresses/groups') ||
      request.url.includes('/v1/files') ||
      request.url.includes('/v1/courses') ||
      request.url.includes('/v1/lessons') ||
      request.url.includes('/v1/auth/signin') ||
      request.url.includes('/v2/student/progresses/groups/')
      
      // request.url.includes('/v2/curator/groups') ||
      // request.url.includes('/v2/student')
    ) {
      if(!request.url.includes('videoviewed')) {
        shouldShowLoading = true;
      }
    }

    if (shouldShowLoading) {
      this.loadingService.showLoading();
    }

    return next.handle(modifiedRequest).pipe(
      finalize(() => {
        if (shouldShowLoading) {
          this.loadingService.hideLoading();
        }
      })
    );
  }
}

<app-dialog
    [title]="title"
    [isOpen]="isOpen"
    (closeEvent)="closeEvent.emit()"
    (confirmEvent)="save()"
    [disabledConfirm]="isDisableForm"
    >
    <div *ngIf="tabs && tabs.length > 0" class="tabs">
        <button
            *ngFor="let tab of tabs"
            class="tab"
            [ngClass]="{'tab-active': tab.active}"
            (click)="tabClicked(tab)">
            {{tab.name | translate}}
        </button>
    </div>
    <form class="form form-scrollable" [formGroup]="form">
        <div class="double">
            <div class="full-width">
                <app-multi-select
                    *ngIf="form.get('subjects')"
                    name="SUBJECTS"
                    [options]="subjectOptions"
                    [withSearch]="true"

                    [preSelectedOptions]="form.get('subjects')?.value || []"
                    (selectedValue)="subjectSelected($event)">
                </app-multi-select>
            </div>
            <div class="full-width">
                <app-multi-select
                    [options]="courseOptions"
                    *ngIf="form.get('courses')"
                    [withSearch]="true"

                    (selectedValue)="courseSelected($event)"
                    [preSelectedOptions]="form.get('courses')?.value || []"
                    name="COURSES">
                </app-multi-select>
            </div>
        </div>
        <div class="double">
            <div class="full-width">
                <app-multi-select
                    *ngIf="form.get('groups')"
                    name="groups"
                    [options]="groupOptions"
                    [withSearch]="true"

                    [preSelectedOptions]="form.get('groups')?.value || []"
                    (selectedValue)="groupSelected($event)">
                </app-multi-select>
            </div>
            <div class="full-width">
                <app-multi-select
                    [options]="invitedOptions"
                    *ngIf="form.get('inviteds') && !isInvitedOneLine"
                    [withSearch]="true"

                    (selectedValue)="invitedSelected($event)"
                    [preSelectedOptions]="form.get('inviteds')?.value || []"
                    [name]="invitedsLabelName">
                </app-multi-select>
            </div>
        </div>
        <app-multi-select
            [options]="invitedOptions"
            *ngIf="form.get('inviteds') && isInvitedOneLine"
            [withSearch]="true"

            (selectedValue)="invitedSelected($event)"
            [preSelectedOptions]="form.get('inviteds')?.value || []"
            [name]="invitedsLabelName">
        </app-multi-select>
        <app-date-picker
            *ngIf="isOpen && form.get('startDate')"
            name="START_DATE"
            formControlName="startDate">
        </app-date-picker>
        <div class="double">
            <div class="full-width">
                <app-select-input
                    mask="00:00"
                    *ngIf="form.get('startTime')"
                    [options]="timeOptions"
                    (input)="handleTimeInput($event)"
                    name="START_TIME"
                    formControlName="startTime">
                </app-select-input>
            </div>
            <div class="full-width">
                <app-select-input
                    mask="00:00"
                    *ngIf="form.get('endTime')"
                    [options]="timeOptions"
                    (input)="handleTimeInput($event)"
                    name="END_TIME"
                    formControlName="endTime">
                </app-select-input>
            </div>
        </div>
        <app-input
            *ngIf="form.get('header')"
            formControlName="header"
            name="HEADING">
        </app-input>
        <app-input
            *ngIf="form.get('redirectUrl')"
            formControlName="redirectUrl"

            name="link"
            placeholder="PASTE_LINK">
        </app-input>
        <app-textarea
            *ngIf="form.get('description')"
            name="DESCRIPTION"
            formControlName="description">
        </app-textarea>
    </form>
</app-dialog>

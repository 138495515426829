<div class="app-insert-words disable-selection">
  <div class="sentence" *ngFor="let sentence of sentences; let last = last; let i = index">
    <span class="text" #sentences>{{sentence}}</span>
    <input
    size="25"
      (input)="sendAnswers()"
      *ngIf="!last"
      class="input"
      [ngClass]="{
                'input--correct' : (_QuizState.View === quizState) && question.correctAnswerTexts && ((myInput.value.trim() | lowercase)  === (question.correctAnswerTexts[i] | lowercase)),
                'input--incorrect' : (_QuizState.View === quizState) && question.correctAnswerTexts && ((myInput.value.trim()  | lowercase) !== (question.correctAnswerTexts[i] | lowercase)),
            }"
      [ngStyle]="{ 'width.px': calculateWidth(myInput.value) , 'max-width': 'calc(100% - ' + sentences?.offsetWidth + 'px)'}"
      [readOnly]="(_QuizState.View === quizState) || readOnly"
      #myInput/>
  </div>
</div>
<app-result
  [explanation]="question.explanation"
  [referenceUrl]="question.referenceUrl"
  [score]="question.score"
  *ngIf="(_QuizState.View === quizState) && question.correctAnswerTexts"
  [title]="'result' | translate" infoText="">
  <div class="answer-wrapper">
    <div class="block-wrapper">
      <h1 class="answer-title">{{'correctAnswer' | translate}}</h1>
      <div class="answer-sentences">
        <span class="answer-sentence" *ngFor="let sentence of sentences; let last = last; let i = index">
            <span>{{sentence}}</span>
            <span *ngIf="!last" class="answer-correct">{{ " " + question.correctAnswerTexts[i]}}</span>
        </span>
      </div>
    </div>
  </div>
</app-result>

import { Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { ConverterService } from '../../../../core/services/converter/converter.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { PdfFileServiceService } from '../../../services/pdf-file-service/pdf-file-service.service';
import { environment } from 'src/environment';
import { ToastrService } from "ngx-toastr";

@Component({
  selector: 'app-pdf-file-viewer',
  templateUrl: './pdf-file-viewer.component.html',
  styleUrls: ['./pdf-file-viewer.component.scss']
})
export class PdfFileViewerComponent implements OnChanges {
  @Input() mobileAdaptive: boolean = false;
  @Input() url: string | null = null;
  iframeSrc: SafeResourceUrl | undefined;
  isMobilePdfLoading: boolean = false;

  @ViewChild('content', { static: false }) content!: ElementRef;

  constructor(private converterService: ConverterService,
    private sanitizer: DomSanitizer,
    private PdfFileServiceService: PdfFileServiceService,
    private toastrService: ToastrService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['url'] && this.url) {
      this.iframeSrc = this.sanitizer.bypassSecurityTrustResourceUrl("https://converter.juz40-edu.kz/converter/convert?links=" + environment.filesUrl + this.url + '#toolbar=1');
      this.isMobilePdfLoading = window.innerWidth < 999;
    }
    if(changes['url'] && !this.url) {
      this.iframeSrc = undefined;
    }
  }

  public close(): void {
    this.PdfFileServiceService.close();
  }

  setPdfLoading(): void {
    this.isMobilePdfLoading = true;
  }

  offPdfLoading(): void {
    this.isMobilePdfLoading = false;
  }

  setError(event: { message: string }): void {
    this.toastrService.warning(event?.message || '', 'Ошибка');
    this.offPdfLoading();
  }
}

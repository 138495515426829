<div class="app-file-uploader">
  <div class="drop-zone"
       *ngIf="!isViewTaskMode"
       [ngClass]="{'drop-zone--error': error}"
       [class.drop-zone--dragging]="dragging"
       (dragover)="onDragOver($event)"
       (dragleave)="onDragLeave()"
       (dragend)="onDragLeave()"
       (drop)="onDropSuccess($event)">
    <img src="assets/img/upload-file.png" alt=""/>
    <span class="text">{{'moveHereOr' | translate}}
      <button class="text--special" (click)="fileInput.click()">{{'clickToSave' | translate}}</button>
        </span>
    <input
      class="input"
      (change)="onFileSelected($event)"
      #fileInput
      type="file"
      [accept]="'.xlsx,.xls,.doc,.docx,.pdf,.jpeg,.jpg,.png,' + allowAdditionalFormats"
    />
  </div>
  <div *ngIf="isViewTaskMode" class="drop-zone">
    <img src="assets/img/upload-file.png" alt=""/>
    <span class="text">{{'moveHereOr' | translate}}
      <button class="text--special">{{'clickToSave' | translate}}</button>
    </span>
    <input
      class="input"
      type="file"
      [accept]="'.xlsx,.xls,.doc,.docx,.pdf,' + allowAdditionalFormats"
    />
  </div>
  <span *ngIf="error" class="error-message">{{error | translate}}</span>
  <ul class="list">
    <li class="item" *ngFor="let link of fileLinks; let i = index;">
      <div class="left">
        <img [src]="'assets/svg/files/' + (link | fileFormat)?.icon" alt=""/>
        <span class="file-name">{{(link | fileFormat).fileName }}</span>
      </div>
      <button *ngIf="!isViewTaskMode" class="remove" (click)="deleteFile(i)">{{'delete' | translate}}</button>
    </li>
  </ul>
</div>

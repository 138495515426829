import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { LessonSummary } from 'src/app/core/model';
import { CuratorService } from 'src/app/core/services/curator/curator.service';
import { DateHelperServiceService } from "../../../../shared/services/dateHelperService/date-helper-service.service";
import { EducationService } from "../../../../shared/services/education/education.service";
import { ButtonColors, ButtonSize, ButtonTypes } from "../../../../shared/models/enums/button.enum";

@Component({
  selector: 'app-task-card',
  templateUrl: './task-card.component.html',
  styleUrls: ['./task-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskCardComponent {
  @Input() lesson!: LessonSummary;
  @Input() groupId: string = '';
  @Input() themeId: string = '';

  @Output() onSelectLessonListener: EventEmitter<any> = new EventEmitter();

  public editButtonType: ButtonTypes = ButtonTypes.Outline;
  public editButtonSize: ButtonSize = ButtonSize.Small;
  public editButtonColor: ButtonColors = ButtonColors.Black;

  constructor(
    private _curatorService: CuratorService,
    private dateHelhepService: DateHelperServiceService,
    private educationService: EducationService
  ) {}

  public onSelectLesson(): void {
    this._curatorService.selectedLesson$!.next(this.lesson);
    this.onSelectLessonListener.emit(this.lesson);
  }

  public isItLessThanHour(date: string | null) {
    if (!date) return false;
    const serverDate = new Date(date);
    const currentDate = new Date();
    const differenceMs = serverDate.getTime() - currentDate.getTime();
    const daysDifference = this.dateHelhepService.getDaysDifference(date);
    return daysDifference === 1 && differenceMs < (1000 * 60 * 60);
  }

  public navigateTo() {
    this.educationService.navigateByLessonType(this.lesson, this.lesson?.groupId, this.themeId);
  }
}

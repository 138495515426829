<div class="notification-modal-root"
     [class.notification-modal-root_show]="isShow"
     (click)="closeNotification()">
  <div class="notification-modal border"
       [ngClass]="{'notification-modal_show': isShow, 'is-system-alert': (currentSystemAlert$ | async)}">
    <div class="body" (scroll)="onScroll($event)">
      <div *ngIf="!isLoading && notifications.length" class="header flex justify-center">
        <button
          class="mark-btn flex items-center justify-center"
          [disabled]="!notifications.length || !nonReadExists"
          (click)="markAllAsRead()"
        >
          {{'MARK_ALL_AS_READ' | translate}}
        </button>
      </div>
      <app-notification-item-card *ngFor="let item of notifications; index as i"
                                  (onReadNotification)="changedItem($event)"
                                  [notification]="item"
                                  [isRead]="item.isRead"
                                  (click)="$event.stopPropagation()"/>
      <div class="flex justify-center p-12">
        <mat-spinner *ngIf="isLoading" mode="indeterminate" color="primary"/>

        <p *ngIf="!isLoading && !notifications.length">{{'noNotifications' | translate}}</p>
      </div>
    </div>
  </div>

</div>

<app-dialog
  [isOpen]="isOpen"
  [title]="isCreate ? 'ACCEPT_DISCOUNT' : 'EDIT_DISCOUNT'"
  (closeEvent)="closeDiscount()"
  (confirmEvent)="createDiscount()"
  [disabledConfirm]="form.invalid">
  <form [formGroup]="form" class="form">
    <app-input
      *ngIf="isCreate"
      name="PHONE_NUMBER_OR_MAIL"
      formControlName="username"
      (input)="usernameTyping()"
      (keydown.space)="$event.preventDefault()"/>
    <div *ngIf="foundUser" class="user">
      <div class="profile" [style.background-image]="'url(' + imageUrl + ')'"></div>
      <span class="fio">{{foundUser.firstname + ' ' + foundUser.lastname}}</span>
    </div>
    <app-select
      name="CHOOSE_SUBJECTS"
      formControlName="subject"
      [options]="subjectsOption"
      (selectedValue)="subjectSelected($event)">
    </app-select>
    <app-select
      name="PROMO_CODE_AMOUNT"
      formControlName="discountId"
      [options]="discountsOption" >
    </app-select>
    <app-select
      name="AVAILABILITY"
      formControlName="always"
      [options]="accessOptions"
    >
    </app-select>
    <app-select
      *ngIf="showLimit"
      name="LIMIT"
      formControlName="discountLeft"
      [options]="limitOptions"
      >
    </app-select>

  </form>
</app-dialog>

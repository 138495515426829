import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EventsCreatorComponent } from './events-creator/events-creator.component';
import { BaseModule } from '../base/base.module';
import { MaterialModule } from "../material/material.module";
import { DiscussionComponent } from './discussion/discussion.component';
import { NonBaseModule } from '../non-base/non-base.module';
import { SvgModule } from '../svg/svg.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {MatTabsModule} from '@angular/material/tabs';
import { BaseComponent } from '@fullcalendar/core/internal';


@NgModule({
    declarations: [
        EventsCreatorComponent,
        DiscussionComponent,
    ],
    exports: [
        EventsCreatorComponent,
        DiscussionComponent,
    ],
    imports: [
        CommonModule,
        MaterialModule,
        NonBaseModule,
        SvgModule,
        MatTabsModule,
        BaseModule,
        FormsModule,
        ReactiveFormsModule,
    ]
})
export class BusinessModule { }

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ForAnswerTestQuestions, QuizState, UserAnswer } from 'src/app/core/model';

interface CustomValueList {
  index: number,
  value: string | number
}

@Component({
  selector: 'app-change-queue',
  templateUrl: './change-queue.component.html',
  styleUrls: ['./change-queue.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChangeQueueComponent implements OnInit {
  public readonly _QuizState = QuizState;
  @Input() quizState: QuizState = QuizState.Passing;
  @Input() question!: ForAnswerTestQuestions;
  @Input() disabled: boolean = false;
  @Output() answeredEvent: EventEmitter<{ questionId: string, answer: UserAnswer[]}> = new EventEmitter<{ questionId: string, answer: UserAnswer[]}>();

  customValueList: CustomValueList[] = [];

  constructor(
    private cdr: ChangeDetectorRef,
  ) {}

  @HostListener('window:resize', ['$event'])
  onResize() {
    console.log('resize')
    if(this.question.valueList) {
      this.customValueList = [];
      this.question.valueList.map((item, index) => this.customValueList.push({ index: index, value: item}));
      this.setQuestionsHeight();
    }
  }

  ngOnInit(): void {
    if(this.question.userAnswers && this.question.userAnswers.length > 0) {
      this.question.keyList = this.question.userAnswers.map(item => this.question.keyList.filter(key => item.answerTextValue?.includes(key))[0]);
      this.question.valueList = this.question.userAnswers.map(item => this.question.valueList.filter(value => item.answerTextValue?.includes(value))[0]);
    }

    if(this.question.valueList) {
      this.customValueList = [];
      this.question.valueList.map((item, index) => this.customValueList.push({ index: index, value: item}));
      this.setQuestionsHeight();
    }


    if(this.question.correctAnswerTexts) {
      this.question.correctAnswerTexts = this.filterArrayBySubstrings(this.question.correctAnswerTexts, this.question.keyList);
      this.cdr.detectChanges();
    }

    if(this.question.keyList && this.question.keyList.length > 0 && this.question.valueList && this.question.valueList.length > 0) {
      if(this.question.userAnswers && this.question.userAnswers.length > 0) {
        return;
      }
      this.sendToParentResult();
    }
  }

  public setQuestionsHeight(): void {
    this.cdr.detectChanges();
    for (let i = 0; i < this.question.keyList.length; i++) {
      const questionElement = (document.getElementsByClassName('question' + i)[0]) as HTMLElement;
      const answerElement = (document.getElementsByClassName('answer' + i)[0]).getElementsByClassName('card')[0] as HTMLElement;
      const maxHeigth: number = (questionElement.offsetHeight > answerElement.offsetHeight) ? questionElement.offsetHeight : answerElement.offsetHeight;
      questionElement.style.height = maxHeigth + "px";
      answerElement.style.height = maxHeigth + "px";
    }
  }

  private sendToParentResult(): void {
    const userAnswers: UserAnswer[] = this.customValueList.map((item, index) => {
      const answerTextValue = this.question.keyList[index] + " " + item.value;
      return { answerTextValue } as UserAnswer;
    });

    this.answeredEvent.emit({ questionId: this.question.id, answer: userAnswers})
  }
  public drop(event: CdkDragDrop<string[]>): void {
    moveItemInArray(this.customValueList, event.previousIndex, event.currentIndex);
    this.sendToParentResult();
    this.setQuestionsHeight();
  }

  public stepForward(first: boolean, index: number): void {
    if(first) {
      return;
    }
    moveItemInArray(this.customValueList, index, index -1);
    this.sendToParentResult();
  }
  public stepBack(last: boolean, index: number): void {
    if(last) {
      return;
    }
    moveItemInArray(this.customValueList, index, index + 1);
    this.sendToParentResult();
  }

  public filterArrayBySubstrings(arr: string[], substrings: string[]): (string)[] {
    const array: string[] = [];
    for(let key of substrings) {
      for(let item of arr) {
        if(item.toString().includes(key.toString())) {
          array.push(item.toString());
        }
      }
    }
    return array;
  }
}

import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { AdditionalMaterials, CoursesDTO, CreateCourse, UpdateCourse } from '../../model/interface/courses';
import { HttpClient } from '@angular/common/http';
import { SortableResponseData } from '../../model';
import { Params } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class CoursesService {

  constructor(private httpClient: HttpClient) { }

  public _getCoursesList(subjectId: string = '', pageIndex: number = 0): Observable<SortableResponseData<CoursesDTO>> {
    const data = {
      subjectIds: subjectId,
      size: 10,
      page: pageIndex,
      sort: "createdAt,DESC"
    }
    return this.httpClient.get<SortableResponseData<CoursesDTO>>(`/v1/courses`, { params: data}).pipe(
      map((res:SortableResponseData<CoursesDTO>) => {
        return res;
      })
    );
  };

  public getCoursesListMethodist(subjectId: string = '', pageIndex: number = 0, searchWord: string = ""): Observable<SortableResponseData<CoursesDTO>> {
    const data = {
      size: 10,
      page: pageIndex,
      searchWord: searchWord
    }
    return this.httpClient.get<SortableResponseData<CoursesDTO>>(`/v1/methodist/subjects/${subjectId}/courses`, { params: data}).pipe(
      map((res:SortableResponseData<CoursesDTO>) => {
        return res;
      })
    );
  };

  public getCoursesList2(subjectId: string = '', pageIndex: number = 0): Observable<SortableResponseData<CoursesDTO>> {
    const data = {
      size: 10,
      page: pageIndex,
      sort: "createdAt,DESC"
    }
    return this.httpClient.get<SortableResponseData<CoursesDTO>>(`/v1/headteacher/subjects/${subjectId}/courses`, { params: data}).pipe(
      map((res:SortableResponseData<CoursesDTO>) => {
        return res;
      })
    );
  };
  public getCoursesList(): Observable<SortableResponseData<CoursesDTO>> {
    const params = {
      page: 0,
      size: 10,
    }
    return this.httpClient.get<SortableResponseData<CoursesDTO>>(`/v1/courses`, { params}).pipe(
      map((res:SortableResponseData<CoursesDTO>) => {
        return res;
      })
    );
  };

  public createCourse(data: CreateCourse): Observable<CoursesDTO> {
    return this.httpClient.post<CoursesDTO>(`/v1/courses`, data).pipe(
      map((res: CoursesDTO) => {
        return res;
      })
    )
  };
  public getCourseById(id: string): Observable<CoursesDTO> {
    return this.httpClient.get<CoursesDTO>(`/v1/courses/${id}`).pipe(
      map((res: CoursesDTO) => {
        return res;
      })
    )
  };
  public getCoursesBySign(sign: string): Observable<CoursesDTO[]> {
    const data = {
      page: 0,
      size: 100,
      sort: sign
    }
    return this.httpClient.get<CoursesDTO[]>(`/v1/courses`, { params: data }).pipe(
      map((res: CoursesDTO[]) => {
        return res;
      })
    )

  }
  public updateCourseById(id: string, data: UpdateCourse): Observable<CoursesDTO> {
    return this.httpClient.put<CoursesDTO>(`/v1/courses/${id}`, data).pipe(
      map((res: CoursesDTO) => {
        return res;
      })
    )
  };
  public duplicateCourseById(id: string): Observable<CoursesDTO> {
    return this.httpClient.post<CoursesDTO>(`/v1/courses/${id}/duplicate`, {}).pipe(
      map((res: CoursesDTO) => {
        return res;
      })
    )
  };
  public deleteCourseById(id: string): Observable<CoursesDTO> {
    return this.httpClient.delete<CoursesDTO>(`/v1/courses/${id}`).pipe(
      map((res: CoursesDTO) => {
        return res;
      })
    )
  };
  public usefulinkandadditionalmaterial(id: string, data: AdditionalMaterials): Observable<AdditionalMaterials> {
    return this.httpClient.patch<AdditionalMaterials>(`/v1/courses/${id}/usefulinkandadditionalmaterial`, data).pipe(
      map(res => {
        return res;
      })
    )
  }
}

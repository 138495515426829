import {TranslateTaskTypePipe} from 'src/app/shared/pipes/translate-task-type/translate-task-type.pipe';
import {LessonsService} from 'src/app/core/services/lessons/lessons.service';
import {HelperService} from './../../../../../../shared/services/helper/helper.service';
import {AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {
  TestQuestionCustom,
  DiffucltLevel,
  LessonType,
  MatchingDTO,
  QuizDTO,
  QuizQuestionDTO,
  TestType,
  ForAnswerTestQuestions,
  UsefullLink,
  Roles,
  LessonDTO
} from 'src/app/core/model';
import {SelectValue} from 'src/app/shared/models';
import {TranslateService} from '@ngx-translate/core';
import {Observable, Subject, Subscription} from 'rxjs';
import {getFormControlErrors} from 'src/app/shared/validators';
import {ActivatedRoute, NavigationStart, Router} from '@angular/router';
import {DateHelperServiceService} from 'src/app/shared/services/dateHelperService/date-helper-service.service';
import {DatePipe} from '@angular/common';
import {getTimeOptions} from "../common/common";
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { CuratorService } from 'src/app/core/services/curator/curator.service';
import { FoldersService } from './../../../../../../core/services/folders/folders.service';

@Component({
  selector: 'app-test-controller',
  templateUrl: './test-controller.component.html',
  styleUrls: ['./test-controller.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TestControllerComponent implements OnInit, OnChanges, OnDestroy {
  @Input() data: QuizDTO | undefined;
  @Input() name!: string;
  @Input() isOpen: boolean = false;

  private eventsSubscription!: Subscription;
  private routerSubscription!: Subscription;

  @Input() saveEvent!: Observable<void>;
  @Output() allowSaveEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() savedEvent: EventEmitter<LessonDTO> = new EventEmitter<LessonDTO>();

  public saveQuizQuestionSubject: Subject<number> = new Subject<number>();

  public baseForm!: FormGroup;
  public baseFormErrors = {
    date: '',
    time: '',
    description: "",
    rating: "",
    minutesDuration: "",
    maxScore: "",
    showResults: "",
    attachSolution: "",
    atypeCount: "",
    btypeCount: "",
    ctypeCount: "",
  }

  public quizForm!: FormGroup;

  public optionsTest: SelectValue[] = [];
  public testType = TestType;

  public diffucltLevelOptions: SelectValue[] = this.helperService.convertEnumToSelectValue(DiffucltLevel);

  public defaultOptions: SelectValue[] = [
    {
      name: "Да",
      value: true
    },
    {
      name: "Нет",
      value: false,
    }
  ];

  public timeOptions: SelectValue[];

  public userFulLinks: UsefullLink[] = [];
  public unsavedChanges: boolean = false;
  public confirmationShown: boolean = false;


  public openQuestions: boolean[] = [];

  public roles = Roles;
  public role: Roles = Roles.Methodist;

  public studentsList: SelectValue[] = [];
  public groupId: string = "";
  public themeId: string = ""

  public foldersOptions: SelectValue[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private helperService: HelperService,
    private translate: TranslateService,
    private lessonsService: LessonsService,
    private translateTaskTypePipe: TranslateTaskTypePipe,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private datePipe: DatePipe,
    private dateHelper: DateHelperServiceService,
    private authService: AuthService,
    private curatorService: CuratorService,
    private route: ActivatedRoute,
    private FoldersService: FoldersService

  ) {

    this.timeOptions = getTimeOptions();
    this.baseForm = this.formBuilder.group({
      date: ['', Validators.required],
      time: ['', Validators.required],
      description: ['', Validators.required],
      rating: [true],
      minutesDuration: [0, Validators.required],
      maxScore: [0, Validators.required],
      showResults: [true],
      attachSolution: [false],
      atypeCount: [0],
      btypeCount: [0],
      ctypeCount: [0],
      workOnMistakesText: [''],
      workOnMistakesReferenceUrl: [''],
      usersIds: [[]],
      courseFolderId: ['']

    });

    this.baseForm.valueChanges.subscribe(res => {
      this.unsavedChanges = true;
      this.checkFromValidity();
    })

    this.quizForm = this.formBuilder.group({
      list: this.formBuilder.array([])
    });

    this.quizForm.valueChanges.subscribe(res => {
      this.checkQuestionAmountSatisfaction();
      this.checkFromValidity();
    })

    this.optionsTest = helperService.convertEnumToSelectValue(TestType).map(item => {
      return {name: this.translateTaskTypePipe.transform(item.value as TestType), value: item.value}
    });
  }

  ngOnInit(): void {
    this.role = this.authService.getRole();

    this.eventsSubscription = this.saveEvent.subscribe(() => {

      const baseFormValue = this.baseForm.value;

      const hours = baseFormValue.time.slice(0, 2);
      const minutes = baseFormValue.time.slice(-2);
      const date = new Date(baseFormValue.date);

      const utcDate = new Date(
        date.getUTCFullYear(),
        date.getUTCMonth(),
        date.getUTCDate(),
        hours,
        minutes,
      );

      const data: QuizDTO = {
        lessonType: LessonType.Quiz,
        id: this.data!.id,
        name: this.name,
        description: baseFormValue.description,
        rating: baseFormValue.rating,
        minutesDuration: baseFormValue.minutesDuration,
        maxScore: baseFormValue.maxScore,
        showResults: baseFormValue.showResults,
        attachSolution: baseFormValue.attachSolution,
        atypeCount: baseFormValue.atypeCount,
        btypeCount: baseFormValue.btypeCount,
        ctypeCount: baseFormValue.ctypeCount,
        deadline: utcDate.toISOString(),
        workOnMistakesText: baseFormValue.workOnMistakesText,
        workOnMistakesReferenceUrl: baseFormValue.workOnMistakesReferenceUrl,
        usersIds: baseFormValue.usersIds,
        courseFolderId: baseFormValue.courseFolderId

      };
      this.lessonsService.updateQuiz(this.groupId, this.themeId, this.data!.id, data).subscribe(res => {
        this.savedEvent.emit(res);
        this.cdr.detectChanges()
      })
    });

    this.routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        if (this.isOpen && this.unsavedChanges && !this.confirmationShown) {
          this.confirmationShown = true;
          const shouldContinue = confirm(`В "${this.data?.name}" есть не сохраненые изменения.Хотите продолжить?`);
          if (!shouldContinue) {
            const currentRoute = this.router.routerState;
            this.router.navigateByUrl(currentRoute.snapshot.url, {skipLocationChange: true});
          }
        }
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['data'] && this.data) {
      if(this.authService.getRole() === Roles.Methodist) {
        this.route.params.subscribe(params => {
          this.FoldersService.getFoldersMethodist(params['id']!).subscribe({
            next: (res) => {
              this.foldersOptions = res[0].courseFolders.map(item => {
                return {
                  name: item.courseFolderName,
                  value: item.courseFolderId
                }
              });
              this.cdr.detectChanges();
              this.baseForm.patchValue({ courseFolderId: this.data?.courseFolderId || []})

              this.cdr.detectChanges();
            },
            error: (err) => {
              console.log(err);
            }
          })
          this.cdr.detectChanges();
        })
      }
      if(this.authService.getRole() === Roles.Curator) {
        this.route.params.subscribe(params => {
          this.groupId = params['groupId'];
          this.themeId = params['themeId'];

          const groups = [this.groupId];

          this.curatorService.getStudentsByGroupIds(groups).subscribe(res => {
            this.studentsList = res.map(item => {
              return {
                name: item.firstname + " " + item.lastname,
                value: item.id || ''
              }
            }).sort((a, b) => (a.name).localeCompare((b.name)));
            this.cdr.detectChanges();
          });

          if(!this.data?.common) {
            this.baseForm.setControl('usersIds', new FormControl([], ));
            this.baseForm.patchValue({'usersIds': this.data?.usersIds})
          }

          this.FoldersService.getFoldersCurator(this.groupId).subscribe({
            next: (res) => {
              this.foldersOptions = res[0].courseFolders.map(item => {
                return {
                  name: item.courseFolderName,
                  value: item.courseFolderId
                }
              });
              this.cdr.detectChanges();
              this.baseForm.patchValue({ courseFolderId: this.data?.courseFolderId || []})

              this.cdr.detectChanges();
            },
            error: (err) => {
              console.log(err);
            }
          })
          this.cdr.detectChanges();
        });
      }

      if(this.authService.getRole() === Roles.HeadTeacher) {
        this.route.params.subscribe(params => {
          const groupId = params['groupId'];
          const courseId = params['id'];
          this.FoldersService.getFoldersHeadTeacher(courseId, groupId).subscribe({
            next: (res) => {

              this.foldersOptions = res[0].courseFolders.map(item => {
                return {
                  name: item.courseFolderName,
                  value: item.courseFolderId
                }
              });
              this.cdr.detectChanges();
              this.baseForm.patchValue({ courseFolderId: this.data?.courseFolderId || []})

              this.cdr.detectChanges();
            },
            error: (err) => {
              console.log(err);
            }
          })
          this.cdr.detectChanges();
        });

        if(!this.data?.common) {
          this.baseForm.setControl('usersIds', new FormControl([], []));
          this.baseForm.patchValue({'usersIds': this.data?.usersIds});
        }
      }

      if(this.data.deadline) {
        const deadline = this.dateHelper.convertUTCToLocal(new Date(this.data.deadline));
        this.datePipe.transform(deadline, 'yyyy-MM-dd');

        this.baseForm.patchValue({date: this.datePipe.transform(deadline, 'yyyy-MM-dd')});
        this.baseForm.patchValue({
          time: (deadline.getHours() < 9 ? ('0' + deadline.getHours()) : deadline.getHours())
            + ':' + ((deadline.getMinutes() < 9) ? '0' + deadline.getMinutes() : deadline.getMinutes())
        });
      }

      this.baseForm.patchValue({description: this.data?.description});
      this.baseForm.patchValue({rating: this.data?.rating});
      this.baseForm.patchValue({minutesDuration: this.data?.minutesDuration});
      this.baseForm.patchValue({maxScore: this.data?.maxScore});
      this.baseForm.patchValue({showResults: this.data?.showResults});
      this.baseForm.patchValue({attachSolution: this.data?.attachSolution});
      this.baseForm.patchValue({atypeCount: this.data?.atypeCount});
      this.baseForm.patchValue({btypeCount: this.data?.btypeCount});
      this.baseForm.patchValue({ctypeCount: this.data?.ctypeCount});
      this.baseForm.patchValue({workOnMistakesText: this.data?.workOnMistakesText});
      this.baseForm.patchValue({workOnMistakesReferenceUrl: this.data?.workOnMistakesReferenceUrl});
      this.cdr.detectChanges();

      this.openQuestions = [];
      this.quizzes.clear();

      if(this.data.questions) {
        for (let item of this.data.questions) {
          const itemGroup = this.buildQuestion(item);
          this.quizzes.push(itemGroup);

          this.openQuestions.push(false);
        }
      }
      console.log(this.quizzes);
      this.cdr.detectChanges();
    }
  }
  ngOnDestroy(): void {
    this.eventsSubscription.unsubscribe();
    this.routerSubscription.unsubscribe();
  }

  get quizzes(): FormArray {
    return this.quizForm.controls['list'] as FormArray;
  }

  private buildQuestion(item: QuizQuestionDTO): FormGroup {
    return this.formBuilder.group({
      id: [item.id],
      quizLessonId: [item.quizLessonId],
      quizQuestionType: [item.quizQuestionType, Validators.required],
      questionText: [item.questionText, Validators.required],
      questionImageUrl: [item.questionImageUrl, Validators.required],
      explanation: [item.explanation, Validators.required],
      referenceUrl: [item.referenceUrl, Validators.required],
      difficultyLevel: [item.difficultyLevel, Validators.required],
      answerList: [item.answerList ?? this.formBuilder.array([]), Validators.required],
      keyList: [item.keyList ?? this.formBuilder.array([]), Validators.required],
      valueList: [item.valueList ?? this.formBuilder.array([]), Validators.required],
      questionPattern: [item.questionPattern, Validators.required],
      expanded: [false],
      isValid: [false]
    });
  }

  private checkFromValidity(): void {
    this.checkQuestionAmountSatisfaction();

    const anyCustomError: boolean =
      this.baseFormErrors.atypeCount
      || this.baseFormErrors.btypeCount
      || this.baseFormErrors.ctypeCount
        ? true : false;
    this.allowSaveEvent.emit((this.baseForm.status === 'VALID' && !anyCustomError) ? true : false);
  }

  removeQuizById(id: string): void {

  }

  addQuiz(): void {
    const itemGroup = this.formBuilder.group({
      id: [''],
      quizQuestionType: [TestType.Test, Validators.required],
      questionText: ['', Validators.required],
      explanation: ['', Validators.required],
      referenceUrl: ['', Validators.required],
      difficultyLevel: [DiffucltLevel.A, Validators.required],
      answerList: [[], Validators.required],
      keyList: [[], Validators.required],
      valueList: [[], Validators.required],
      questionImageUrl: [''],
      expanded: [true],
      isValid: [false]
    });
    this.quizzes.push(itemGroup);

    this.checkQuestionAmountSatisfaction();
  };

  updateQuiz(id: string, data: QuizQuestionDTO): void {
    const quizzes = this.quizzes;
    const quizIndex = quizzes.controls.findIndex((control: AbstractControl) => control.value.id === id);

    if (quizIndex !== -1) {
      const quizControl = quizzes.controls[quizIndex] as FormGroup;
      quizControl.patchValue(data);
    }
  }

  public parseInt(value: string): number {
    return parseInt(value, 10) ? parseInt(value, 10) : 0;
  };

  public sendSaveToChild(index: number): void {
    this.unsavedChanges = false;

    this.quizzes.controls[index].patchValue({expanded: false});
    this.saveQuizQuestionSubject.next(index);
    this.cdr.detectChanges();
  }

  public saveQuestion(index: number, type: TestType, data: TestQuestionCustom & MatchingDTO, questionId: string | undefined) {
    const quizFormValue = this.quizzes.at(index).value;
    const correctData: QuizQuestionDTO = {
      quizLessonId: this.data!.id,
      quizQuestionType: type,
      difficultyLevel: quizFormValue.difficultyLevel,
      questionText: data.questionText,
      explanation: quizFormValue.explanation,
      referenceUrl: quizFormValue.referenceUrl,
      questionImageUrl: data.questionImageUrl,
      shuffle: data.shuffle,
      answerList: data.answerList,
      keyList: data.keyList,
      valueList: data.valueList,
      questionPattern: data.questionPattern,
    }

    switch (type) {
      case TestType.Test: {
        if (!questionId) {
          this.lessonsService.createTestQuiz(this.data!.id, correctData).subscribe(res => {
            this.quizzes.setControl(index, this.buildQuestion(res));
            this.cdr.detectChanges();
          });
        } else {
          this.lessonsService.updateTestQuiz( this.data!.id, questionId, correctData).subscribe(res => {
            this.quizzes.at(index).patchValue(this.buildQuestion(res).value);
            this.cdr.detectChanges();
          });
        }
        break;
      }
      case TestType.Open: {
        if (!questionId) {
          this.lessonsService.createOpenQuiz(this.data!.id, correctData).subscribe(res => {
            this.quizzes.setControl(index, this.buildQuestion(res));
            this.cdr.detectChanges();
          })
        } else {
          this.lessonsService.updateOpenQuiz(this.data!.id, questionId, correctData).subscribe(res => {
            this.quizzes.at(index).patchValue(this.buildQuestion(res).value);
            this.cdr.detectChanges();
          })
        }
        break;
      }
      case TestType.Matching: {
        if (!questionId) {
          this.lessonsService.createMatchingQuiz(this.data!.id, correctData).subscribe(res => {
            this.quizzes.setControl(index, this.buildQuestion(res));
            this.cdr.detectChanges();
          })
        } else {
          this.lessonsService.updateMatchingQuiz(this.data!.id, questionId, correctData).subscribe(res => {
            this.quizzes.at(index).patchValue(this.buildQuestion(res).value);
            this.cdr.detectChanges();
          })
        }
        break;
      }
      case TestType.DragAndDrop: {
        if (!questionId) {
          this.lessonsService.createDragAndDropQuiz(this.data!.id, correctData).subscribe(res => {
            this.quizzes.setControl(index, this.buildQuestion(res));
            this.cdr.detectChanges();
          })
        } else {
          this.lessonsService.updateDragAndDropQuiz(this.data!.id, questionId, correctData).subscribe(res => {
            this.quizzes.at(index).patchValue(this.buildQuestion(res).value);
            this.cdr.detectChanges();
          })
        }
        break;
      }
      case TestType.Fill: {
        if (!questionId) {
          this.lessonsService.createFillQuiz(this.data!.id, correctData).subscribe(res => {
            this.quizzes.setControl(index, this.buildQuestion(res));
            this.cdr.detectChanges();
          })
        } else {
          this.lessonsService.updateFillQuiz(this.data!.id, questionId, correctData).subscribe(res => {
            this.quizzes.at(index).patchValue(this.buildQuestion(res).value);
            this.cdr.detectChanges();
          })
        }
        break;
      }
    }
  }

  public removeQuestion(index: number) {
    const id: string | undefined = this.quizzes.at(index).value['id'];
    if (id) {
      this.lessonsService.deleteQuiz(this.data!.id, this.quizzes.at(index).value['id']).subscribe(res => {
        this.quizzes.removeAt(index);
        this.cdr.detectChanges();
      });
    } else {
      this.quizzes.removeAt(index);
    }
  }

  public openQuestion(index: number): void {
    this.quizzes.controls[index].patchValue({expanded: true});
    this.cdr.detectChanges();
  };

  public setValidityOfQuestion(isValid: boolean, index: number): void {
    this.quizzes.at(index).patchValue({isValid: isValid});
  }

  public handleFormControlErrors(form: FormGroup, error: any, controlName: string): void {
    getFormControlErrors(form, error, controlName, this.translate);
    this.cdr.detectChanges();
  }

  private checkQuestionAmountSatisfaction(): void {
    const amountOfAType = this.quizzes.value.filter((item: ForAnswerTestQuestions) => item.difficultyLevel === DiffucltLevel.A).length;
    const amountOfBType = this.quizzes.value.filter((item: ForAnswerTestQuestions) => item.difficultyLevel === DiffucltLevel.B).length;
    const amountOfCType = this.quizzes.value.filter((item: ForAnswerTestQuestions) => item.difficultyLevel === DiffucltLevel.C).length;

    if (this.baseForm.get('atypeCount')?.value > amountOfAType) {
      this.baseFormErrors.atypeCount = "NUMBER_OF_QUESTIONS_OF_TYPE"
    } else {
      this.baseFormErrors.atypeCount = ""
    }

    if (this.baseForm.get('btypeCount')?.value > amountOfBType) {
      this.baseFormErrors.btypeCount = "NUMBER_OF_QUESTIONS_OF_TYPE"
    } else {
      this.baseFormErrors.btypeCount = ""
    }

    if (this.baseForm.get('ctypeCount')?.value > amountOfCType) {
      this.baseFormErrors.ctypeCount = "NUMBER_OF_QUESTIONS_OF_TYPE"
    } else {
      this.baseFormErrors.ctypeCount = ""
    }

    const timeFormatRegex1: RegExp = /^([0-1][0-9]|2[0-3]):[0-5][0-9]$/;
    const timeFormatRegex2: RegExp = /^([0-1][0-9]|2[0-3])[0-5][0-9]$/;
    const timeFormatRegex3: RegExp = /^([0-1][0-9]|2[0-3]):[0-5][0-9](:[0-5][0-9])?$/;

    if (this.baseForm.get('date')?.value) {
      this.baseFormErrors.date = ""
    } else {
      this.baseFormErrors.date = "SHOW_DEADLINE"
    }
    if (timeFormatRegex1.test(this.baseForm.get('time')?.value) || timeFormatRegex2.test(this.baseForm.get('time')?.value) || timeFormatRegex3.test(this.baseForm.get('time')?.value)) {
      this.baseFormErrors.time = ""
    } else {
      this.baseFormErrors.time = "REQUIRED_FIELD"
    }
  }

  public studentsSelected($event: any[]): void {
    this.baseForm.patchValue({usersIds: $event});
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VideoPlayerComponent } from './video-player.component';
import { VimeoPlayerComponent } from './vimeo-player/vimeo-player.component';
import { IframePlayerComponent } from './iframe-player/iframe-player.component';
import { YoutubePlayerComponent } from './youtube-player/youtube-player.component';
import { IframeVimeoPlayerComponent } from './iframe-vimeo-player/iframe-vimeo-player.component';
import {YouTubePlayerModule} from '@angular/youtube-player';
import { TestVideoPlayerComponent } from './test-video-player/test-video-player.component';

@NgModule({
  declarations: [
    VideoPlayerComponent,
    VimeoPlayerComponent,
    IframePlayerComponent,
    YoutubePlayerComponent,
    IframeVimeoPlayerComponent,
    TestVideoPlayerComponent
  ],
  imports: [
    CommonModule,
    YouTubePlayerModule
  ],
  exports: [
    VideoPlayerComponent
  ]
})
export class VideoPlayerModule { }

<div class="app-result">
    <div class="info">
        <h1 class="title">{{title}}</h1>
        <span class="add">{{('scores' | translate) + score.toFixed(2)}}&nbsp;
        <span class="bold">{{infoText}}</span></span>
    </div>
    <div class="answer">
        <ng-content></ng-content>
        <div *ngIf="explanation || referenceUrl" class="iconic-line line"></div>
        <div *ngIf="explanation || referenceUrl" class="block-wrapper">
          <h1 class="answer-title">{{'explanation' | translate}}</h1>
          <div class="explanation-wrapper">
            <app-editor-viewer class="explanation-text" [content]="explanation"></app-editor-viewer>
            <img *ngIf="referenceUrl" class="explanation-video" (click)="openVideoPlayer()" src="assets/img/fake-video.png" alt=""/>
          </div>
        </div>
    </div>
</div>

<app-video-player-dialog [isOpen]="isOpen" (close)="closeVideoPlayer()" [videoUrl]="referenceUrl"></app-video-player-dialog>
